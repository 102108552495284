import types from '../types/cart';

const initialState = {
    cart: {},
    loading: false,
    error: null,
};

export const cartReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_CART_SUCCESS:
            return {
                cart: action.payload,
                loading: true,
                error: null,
            };
        default: 
            return state;
    }
}