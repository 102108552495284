const types = {
    GET_QUESTION_REQUEST: 'GET_QUESTION_REQUEST',
    GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
    GET_QUESTION_FAILURE: 'GET_QUESTION_FAILURE',
    
    LOAD_EXAM_REQUEST: 'LOAD_EXAM_REQUEST',
    LOAD_EXAM_SUCCESS: 'LOAD_EXAM_SUCCESS',
    LOAD_EXAM_FAILURE: 'LOAD_EXAM_FAILURE',

    CLEAR_EXAM: 'CLEAR_EXAM',
    
    MARCAR_PREGUNTA_REQUEST: 'MARCAR_PREGUNTA_REQUEST',
    MARCAR_PREGUNTA_SUCCESS: 'MARCAR_PREGUNTA_SUCCESS',
    MARCAR_PREGUNTA_FAILURE: 'MARCAR_PREGUNTA_FAILURE',

    MARCAR_PREGUNTA_TEST_FAILURE: 'MARCAR_PREGUNTA_TEST_FAILURE',
    MARCAR_PREGUNTA_TEST_REQUEST: 'MARCAR_PREGUNTA_TEST_REQUEST',
    MARCAR_PREGUNTA_TEST_SUCCESS: 'MARCAR_PREGUNTA_TEST_SUCCESS',

    SAVE_NOTAS_REQUEST: 'SAVE_NOTAS_REQUEST',
    SAVE_NOTAS_SUCCESS: 'SAVE_NOTAS_SUCCESS',
    SAVE_NOTAS_FAILURE: 'SAVE_NOTAS_FAILURE',
    LOAD_EXAM_TEST: 'LOAD_EXAM_TEST',
}
export default types;
