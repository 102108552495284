import types from '../types/auth';
import dbRef from '../../config/dbRef';
import { googleAuthprovider, auth, signInWithPopup, db} from '../../config/firebase';
import { doc, setDoc, getDoc, collection, query, where, addDoc, getDocs  } from "firebase/firestore";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from "firebase/auth";

export const login = (uid, displayName) =>({
    type:types.loguin,
    payload:{uid, displayName},
});

export const isLoggedIn = (isAuthenticated = false) =>({
    type:types.IS_LOGGED_IN,
    payload:{isAuthenticated:isAuthenticated},
});

export const logout = () => ({
    type:types.logOut,
});

const loginRequest = payload => ({
    type: types.LOGIN_REQUEST,
    ...payload,
});

const loginSuccess = payload => ({
    type: types.LOGIN_SUCCESS,
    payload: payload,
});

const loginFailure = payload => ({
    type: types.LOGIN_FAILURE,
    payload: payload,
});

const registerRequest = payload => ({
    type: types.REGISTER_REQUEST,
    ...payload,
});

const registerSuccess = payload => ({
    type: types.REGISTER_SUCCESS,
    payload: payload,
});

const registerFailure = payload => ({
    type: types.REGISTER_FAILURE,
    payload: payload,
});

const clearError = payload => ({
    type: types.CLEAR_ERROR,
    payload: payload,
});

const clearSuccess = payload => ({
    type: types.CLEAR_SUCCESS,
    payload: payload,
});

export const startClearSuccess = () => dispatch => {
    dispatch(clearSuccess());
};
export const startClearError = () => {
    return (dispatch) => {
        dispatch(clearError());
    };
};

export const startGoogleAuth = () => {
    return async (dispatch) => {
        try {
            dispatch(loginRequest());
            const result = await signInWithPopup(auth, googleAuthprovider);
            const docRef = doc(db, dbRef.user, result.user.uid);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()){
                console.log("el usuario existe ");
                dispatch(login(result.user.uid,result.user.displayName));
            }else{
                console.log("el usuario no  existe debe completar el registro ");
            }
        } catch (error){
            console.error(error);
        }
    }
}

export const login_ = (data) => {
    return async (dispatch) => {
        try {
            const {user, password} = data;
            dispatch(loginRequest());
            await signInWithEmailAndPassword(auth, user, password);
            dispatch(loginSuccess(user));

        } catch (error) {
            //console.log(error);
            dispatch(loginFailure(error));
        }
    }
}

export const registerEmailPassSignUp = (userData) => {
    //crear cuenta usuario contraseña
    return async (dispatch) => {
        try{
            dispatch(registerRequest());
            const { email, password, name, lastName,  city, phone, birthday, code } = userData;
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
            console.log("[actions_RegisterEmailPass]cuenta creada", userId);

            console.log(email, password,'estas aqui');
            // Add a new document in collection "cities"
            const result = await setDoc(doc(db, dbRef.user, userId), {
                name: name,
                lastName: lastName,
                birthday: birthday,
                phone: phone,
                email: email,
                password: password,
                country: 'Bolivia',
                city: city,
                codeAfiliate: code?code:"0000 ",
                isAfiliate: false,
            });
            if(code){
                try {
                    const querySnapshot = await getDocs(
                        query(collection(db, dbRef.user),where("code", "==", code))
                    );
                    const userAfiliate = querySnapshot.docs[0].id;
                    const cuenta = doc(db, dbRef.user, userAfiliate);
                    let newAfiliate = {
                        afiliate: userId,
                        name: name,
                        lastName: lastName,
                        state : "revision",
                        plan : "free",
                        monto : 0,
                        date : new Date(),
                    }
                    await addDoc(collection(cuenta, 'afiliates'), newAfiliate);

                }catch(error){
                    const user = auth.currentUser;
                    user.delete().then(() => {
                        console.log("cuenta eliminada");
                        dispatch(registerFailure(error));
                    }).catch((error) => {
                        console.log("error al eliminar cuenta");
                    });
                }
                
            }
            console.log("[actions_RegisterEmailPass]documento creado", result);
            //throw new Error('Error de prueba');
            dispatch(registerSuccess(userData));
            
        } catch(error){
            console.log("[actions_RegisterEmailPass]cuenta creada", error);
            //const errorCode = error.code;
            //const errorMessage = error.message;
            //console.log(errorCode, errorMessage);
            dispatch(registerFailure(error));
        }
    }
}

export const startLogOut = () =>{
    return async (dispatch) => {
        try {
            const logo = await signOut(auth)
            console.log('Log-out sucess', logo);
            dispatch(logout())
        } catch (err) {
            console.log('Error in Log-out', err);
        }
    }
}
