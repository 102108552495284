import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'

export default function Modal({visible, title, onClose, children}) {
  let [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(visible)
  }, [visible]);


  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10"
          onClose={()=>onClose()}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-fondo" style={{opacity:0.5}} />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-5 my-5 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl border-2 border-gray-300 rounded-2xl">
                {title?<Dialog.Title
                          as="h3"
                          className="text-lg text-center font-medium leading-6 text-primary py-2 border-b border-solid border-blueGray-200"
                          >
                          {title}
                        </Dialog.Title>:null
                }
                {children}

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
