import React,{useState,useEffect,useRef } from 'react';
import {Link} from 'react-router-dom';
import NavBarMenu from './navBarMenu';
import { ReactComponent as Logo }  from '../assets/images/logo.svg';
import {useSelector,useDispatch} from 'react-redux';

import {startLogOut} from '../redux/actions/auth';
import {clearUser} from '../redux/actions/user';
import './style.css';
//icono de usuario
import { FaUser } from 'react-icons/fa';
//import { FaFacebookF, FaInstagram,FaTwitter,FaEnvelope} from 'react-icons/fa';

export default function Navbar() {

  //const reduxAuth = useSelector(state => state.auth);
  const reduxUser = useSelector(state => state.user);
	const [offset, setOffset] = useState(0);
  const [user, setUser] = useState({});
  //const [loading, setLoading] = useState(true);
  const navbar = useRef(null);

  const dispatch = useDispatch();

    useEffect(() => {
    window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }, [offset]);
    
    useEffect(() => {
      setUser(reduxUser);
    }, [reduxUser])
    


    const [toggleMenu, setToggleMenu] = useState(false);

    const handleToggle = () =>{
      setToggleMenu(!toggleMenu)
      //{offset>25?" fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14"}
      /**
       * <div className={ offset>50?"fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-red-500 flex flex-col justify-between bg-fondo text-14 -mt-14 md:-mt-26 lg:-mt-14 sm:-mt-26"
          :" fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14"}>
          :"fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14 top-0"
       */
    }


  return (
    <>

	<header id='bar' ref={navbar}  className="navigation">

	
	<NavBarMenu  />
	<div className={ offset>50?" fixed w-full px-40 sm:px-5 md:px-10 lg:px-10 py-4 text-white flex  justify-between bg-fondo text-14 top-0 z-50"
          :" fixed w-full px-40 sm:px-5 md:px-10 lg:px-10 py-4 text-white flex  justify-between bg-fondo text-14 top-9 sm:top-0 lg:top-9"}>
        <h1 className="flex flex-row items-center"><Logo  className="w-12 mr-3" /><div className="text-2xl"><span className="text-primary">A</span><span>priori</span></div></h1>
        <div className={toggleMenu ? "md:flex  md:pt-0 pt-10 w-full md:w-auto" : "hidden md:flex"} id="menu">
        <ul className='relative bg-fondo flex flex-row w-full left-0 sm:top-0 sm:top-16 sm:absolute sm:flex-col md:static md:flex-row pb-4'>
          <Link className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3" to="/">INICIO</Link>
          <a className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3" href="/team">NOSOTROS</a>
          <Link className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3 " to="/pricing">PLANES</Link>
          <Link className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3" to="/service">SERVICIOS</Link>
          <Link className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3" to="/contact">CONTÁCTANOS</Link>
          <Link className="text-center md:inline-block cursor-pointer hover:text-primary md:border-none py-2 px-3" to="/convocatoria">CONVOCATORIAS</Link>
          {
            user.user?
              <div className="dropdown inline-block relative ">
                <div className="flex flex-row justify-center items-center cursor-pointer hover:text-primary py-2 rounded-full px-3 border-2 border-primary w-5/6 mx-auto">
                  <FaUser className='mx-1'/>
                  <span className="mr-1">{user.user.name}</span>
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
                </div>
                <ul className="dropdown-menu absolute hidden text-gray-700 py-2 -ml-12 rounded">
                  <li className="">
                    <div className='bg-gray-200 py-2 px-4 block border-b-2 border-gray-300'>
                      <p><b>{user.user.name}</b></p>
                      <p>{user.user.lastName}</p>
                      <p className='text-xs'>{user.user.email}</p>
                    </div>                    
                  </li>
                  <Link className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer" to="/profile">Ir a Perfil</Link>
                  <div className='bg-gray-200 py-2 px-4 block border-b-2 border-gray-300 hover:bg-gray-400 cursor-pointer' onClick={
                    ()=>{
                      dispatch(startLogOut());
                      dispatch(clearUser());
                    }
                  }>Cerrar Sesión</div>
                </ul>
              </div>
            :
            <Link className="text-center md:inline-block cursor-pointer hover:text-primary py-2 rounded-full px-3  border-2 border-primary" to="/login">{'Iniciar Sesion'}</Link>
          }
          
        </ul>
        </div>
        <div className= "cursor-pointer md:hidden border-2 border-primary max-h-10">
            
          <input onClick={handleToggle}  className="menu-btn hidden" type="checkbox" id="menu-btn"/>
          <label className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none" htmlFor="menu-btn">
            <span className="navicon bg-white-darkest flex items-center relative"></span>
          </label>
      </div>
      </div>
	</header>
    </>
  )
}
