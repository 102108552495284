import React from 'react';
import './styles/styles.css';

export function OnLoad(props){

    return(
      <div className="balls">
        <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
        <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
        <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
        <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
        <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
      </div>
    
    );
};
export function Box(props){

  return(
    <div className="box">
      <div className="plane" style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
    </div>
  
  
  );
};

export function Bar(props){

  return(
    <div className="bar">
      <span style={{backgroundColor:`${props.color?props.color:'red'}`}}></span>
      <span style={{backgroundColor:`${props.color?props.color:'red'}`}}></span>
      <span style={{backgroundColor:`${props.color?props.color:'red'}`}}></span>
      <span style={{backgroundColor:`${props.color?props.color:'red'}`}}></span>
    </div>
  );
};

export function Spin(props){

  return(
    <div className="spin"  style={{borderTopColor:`${props.color?props.color:'red'}`,width:props.size?props.size:5,height:props.size?props.size:5}}></div>
  );
};

export function Spinner(props){

  return(
    <div className="spinner"  style={{borderTopColor:`${props.color?props.color:'red'}`,borderBottomColor:`${props.colorBotton?props.colorBotton:'red'}`}}></div>
  );
};

export function SpinnerLoader(props){

  return(
    <div className="loader">
      <div className="outer" style={{borderTopColor:`${props.color?props.color:'red'}`,borderRightColor:`${props.color?props.color:'red'}`}}></div>
      <div className="middle" style={{borderTopColor:`${props.color?props.color:'red'}`,borderRightColor:`${props.color?props.color:'red'}`}}></div>
      <div className="inner" style={{borderTopColor:`${props.color?props.color:'red'}`,borderRightColor:`${props.color?props.color:'red'}`}}></div>
    </div>
  );
};

export function SquareFill(props){

  return(
    <div className="square" ></div>
  );
};

export function Dots(props){

  return(
    <div className="dots">
      <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
      <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
      <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
      <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
      <div style={{backgroundColor:`${props.color?props.color:'red'}`}}></div>
    </div>

  );
};