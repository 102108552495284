import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { collection, getDocs } from "firebase/firestore";
import { doc, getDoc, addDoc } from "firebase/firestore";
import Footer from '../components/footer';
import {auth, db} from '../../config/firebase'
import NabarPhone from "./components/navbar-phone";
import Photo from "./components/Photo";
import ExamenCard from "./components/ExamenCard";
import {OnLoad, Bar as BarLoad, Dots} from '../components/onload';
import { Link } from 'react-router-dom';
import EventsCard from "./components/EventsCard";
import { FaWhatsapp, FaEnvelope, FaFacebookF, FaInstagramSquare} from "react-icons/fa";

import { Line, Bar } from "react-chartjs-2";
import {Chart,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,BarElement} from 'chart.js'

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Respuestas del ultimo examen',
    },
  },
};
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function UserProfile() {
  const reduxUser = useSelector(state => state.user);
  const data = useSelector(state => state.dataPage);

  const [windowActive, setWindowActive] = useState("exams");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [exams, setExams] = useState([]);
  const [eventos , setEventos] = useState([]);
  const [indexContent, setIndexContent] = useState([]);
  const [pruebas, setPruebas] = useState();
  const [mensajeContacto, setMensajeContacto] = useState('');
  const [datos , setDatos] = useState([]);

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'block';
  }

  useEffect(() => {
    if (data.dataPage.length > 0) {
      setDatos(data.dataPage[0].examenCpmpleto);
    }
  },[data]);


  const changeContent = (param) => {
    let newArray = [];
    indexContent.forEach((item, index) => {
      if (index === param){
        newArray.push('flex');
      }else{
        newArray.push('none');
      }
    })
    setIndexContent(newArray);
  }

  useEffect(() => {
    setUser(reduxUser.user);
    console.log(user);
  }, [reduxUser.user])

  useEffect(() => { 
    if(reduxUser.user){
      setLoading(false);
    }else{
      setLoading(true);
    }
    console.log('se ejecuto useEffect');
  }, [reduxUser])
  
  useEffect(() => {
    console.log("userProfile useEffect setExams");
    if(reduxUser.cuenta){
      setExams(reduxUser.cuenta);
    }
  }, [reduxUser.cuenta]);
  useEffect(()=>{
    getDocs(collection(db,'Cursos'))
    .then((response)=>{
        let event =[]
        response.forEach((doc)=>{
          event.push(
            {
              id: doc.id,
              data:doc.data()
            }
            );
        });
        return event;
    }).then( doc =>{console.log(doc); setEventos(doc)})
    .catch(err => console.log(err));
    //getEvents();
  },[])
  useEffect(() => {
    if(reduxUser){
      const pruebas = [];
      const cuenta = reduxUser.cuenta;
      console.log(cuenta);
      if (cuenta){
        let index = 0;
        let type = [];
        cuenta.forEach(exam => {
          pruebas.push(exam);
          if (index === 0){
            type.push('flex');
          }else{
            type.push('none');
          }
          index++;
        });
        setIndexContent(type);
        setPruebas(pruebas);  
      }
      
    }
    
  }, [reduxUser])
  return (
    <>
      <NabarPhone active={windowActive} onActive={setWindowActive} />
      <div className="w-full text-white flex flex-col justify-center items-center border-b-16 border-accent" >
        <h1 className="text-3xl my-5  sm:text-xl md:text-3xl mx-0 sm:mx-4 md:mx-0 text-center ">Bienvenido</h1>
      </div>
      <div className='flex flex-row items-start justify-center w-full h-full px-28 sm:px-2 md:px-16'>
        <Photo userName={user?.name} userLastName={user?.lastName} userPhoto={user?.photo} userEmail={user?.email} userMensajes={user?.mensajes} isAfiliate={user?.isAfiliate} />
        <div className='w-9/12 h-80 flex flex-col justify-start items-start -mt-1 sm:mt-1 md:-mt-1 w-full'>
          <div className='bg-accent flex flex-row w-full text-white w-full justify-between px-8 rounded-b-lg sm:hidden md:flex'>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'exams'?' bg-white text-primary':'text-white'}`} 
            onClick={() => setWindowActive('exams')}
            >Examenes</p>
            {/* <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'events'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('events')}
            >Eventos</p> */}
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'history'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('history')}
            >Resultados</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'sources'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('sources')}
            >Recursos</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'contact'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('contact')}
            >Soporte</p>
          </div>

          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'exams'?'flex flex-row':'hidden'}`}>
          {
            loading?
            <div className='w-full flex flex-col justify-center'>
              <p className="text-2xl text-center text-primary">
              <Dots color={'gray'}/></p>
              <h1  className='w-full text-center'>Cargando...</h1>
            </div>
              : exams.map((examen, index) => {
                  return(<ExamenCard key={index} plan={examen.plan} idCuenta={examen.id} data={examen}/>)
                }
            )
          }
          {
            exams.length === 0 && !loading &&
            <div className='w-full flex flex-col justify-center self-start'>
              <p 
              className="text-xl text-center text-secundary"
              >En este momento usted no tiene exameneas</p>
              <Link 
                className="m-4 text-center text-white base sm:base lg:base cursor-pointer hover:fondo py-2 rounded-full px-4  bg-primary flex flex-row items-center hover:bg-blue-600 self-center" to={{
                    pathname: `/Details`,
                    data: datos
                }}>Detalles de convocatoria</Link>
            </div>
          }
          <div className='w-full flex flex-col justify-center self-start'>
              <Link 
                className="m-4 text-center text-white base sm:base lg:base cursor-pointer hover:fondo py-2 rounded-full px-4  bg-primary flex flex-row items-center hover:bg-blue-600 self-center" to={{
                    pathname: `/Details`,
                    data: datos
                }}>Ver Examen Vigente</Link>
            </div>
          </div>

          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'events'?'flex flex-row':'hidden'}`}>
            {
              eventos.map((evento, index) =><EventsCard key={index} data={evento}/>)
            }
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'history'?'flex flex-row':'hidden'}`}>
            <div className="flex flex-row justify-center items-center w-full flex-wrap">
              {
                //estadisticas
                pruebas?
                pruebas.map((prueba, index) => {
                  return(
                      <div key={index} className={`w-1/4 text-center border-2 border-primary m-2 rounded p-2 cursor-pointer ${indexContent[index] !=='flex'?'text-primary bg-transparent':'text-white bg-primary'}`}
                      onClick={()=>{changeContent(index)}}
                      >
                        <p className={`border-b-2 ${indexContent[index] ==='flex'?'border-white':'border-primary'}`}>{prueba?prueba.nombre:'no data'}</p>
                        <p>{prueba?
                        prueba.tipo==='PFinal'?
                        'Prueba Final':
                        'Prueba practica':
                        'no data'}</p>
                      </div>
                  )
                }) :
                
                <div className='w-full flex flex-col justify-center'>
                  <p className="text-2xl text-center text-primary">
                    no hay pruebas
                  </p>
                  <h1  className='w-full text-center'>No hay pruebas</h1>
                </div>

              }
            </div>
            {
              pruebas?
              pruebas.map((prueba, index) => {
                return(
                 
                    <div
                    className='w-full flex flex-col justify-center items-center'
                      style={{display:`${indexContent[index]}`}}
                      key={index}
                    >
                      <p className='w-full border-primary border-t-2 text-black mb-2 mt-5 text-center'>
                        Estadisticas de respuestas correctas e incorrectas del ultimo intento realizado.
                      </p>
                      {pruebas[index].notas?
                      <Bar options={options} data={{
                        labels : pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.materia}):[],
                        datasets: [
                          {
                            label: 'Correctos',
                            data: pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.correctas}):[],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                          },
                          {
                            label: 'Incorrectos',
                            data:  pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.incorrectas}):[],
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                          },
                        ],
                      }} />:<p className='text-primary m-2'>No tiene notas en este examen</p>
                      }
                      <p className='w-full border-primary border-t-2 text-black mb-2 mt-5 text-center'>
                        Estadisticas de notas de todos los intentos realizado.
                      </p>
                      {pruebas[index].notas?
                        <Line data={{
                          labels: pruebas[index]?pruebas[index].notas.map((materia, index)=>{return 'intento '+(index+1)}):[],
                          datasets: [
                            {
                              label: "Notas Generales",
                              data: pruebas[index]?pruebas[index].notas.map((materia, index)=>{return materia.calificacion}):[],
                              fill: true,
                              backgroundColor: "#e8452909",
                              borderColor: "#e84529"
                            },
                          ]}} />
                        :<p className='text-primary'>No tiene notas en este examen</p>
                      }
                    </div>
                )
              }) :
              <p>No data</p>
            }  
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'sources'?'flex flex-row':'hidden'}`}>
            <div className='flex flex-col items-start justify-center mt-4 mx-4 '>
            <p className='text-center font-bold text-primary text-xl'>Tus recursos disponibles: </p>
              <ul>
                {user?
                  user.recursos?
                  user.recursos.map((recurso,index)=>{
                    return(
                      <li type='disc' className='ml-8' >
                        <a href={recurso.url} target='_blank' rel="noopener noreferrer" className='mx-1 text-start text-black'>{recurso.nombre}</a>
                      </li>
                    )
                      })
                    :
                    <li>No cuenta con Recursos en este momento</li>
                  :
                  null
                }
              </ul>
              
            </div>
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'contact'?'flex flex-row':'hidden'}`}>
          <h1>Hola {user?user.name:null}, en que podemos ayudarte:</h1>
                <div className="flex flex-row justify-center items-start mt-5 w-full">
                  <div className="flex flex-col justify-center items-center  w-full mr-8">
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Leonel de Sistemas</p>
                    </div>
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Abigail de administracion</p>
                    </div>
                    {/* <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Valeria de Finanzas</p>
                    </div> */}
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Rimber de sistemas</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-end w-full sefl-start ml-4">
                    <div className='flex self-end justify-end items-center bg-green-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Whatsapp</p>
                      <a href='https://wa.me/+59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                    <div className='flex self-end justify-end items-center bg-red-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Email</p>
                      <a className="text-primary" href="mailto:apriori.bolivian@gmail.com" >
                       <FaEnvelope className='bg-red-500 text-white p-2 ' size={40}/>
                      </a>
                      
                    </div>
                    <div className='flex self-end justify-end items-center bg-blue-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Facebook</p>
                      <a href="https://m.me/aprioribol" target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaFacebookF className='bg-blue-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                    <div className='flex self-end justify-end items-center bg-pink-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Instagram</p>
                      <a href="https://in.me/aprioribol" target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaInstagramSquare className='bg-pink-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                  </div>
                </div>

                <div className='flex flex-col items-center mt-8'>
                <p className='my-1 text-center'>Tuviste algun incoveniente con la plataforma? </p>
                <textarea  onChange={(e)=>{setMensajeContacto(e.target.value)}}  className='w-2/3 border-2 border-red-400 p-2 rounded my-3' placeholder='Escribe aqui tu mensaje'></textarea>
                  <div className='flex justify-center' >
                  <a href={`https://wa.me/+59175413873?text=${mensajeContacto}`} target='_blank' rel="noopener noreferrer" className='my-2 bg-primary p-2 rounded text-white px-3'>Enviar a Soporte
                      </a>
                  </div>
                  
                </div>
               
                <div div="flex flex-col w-full justify-center bg-red-200"> 
                  
                </div>
          </div>
        </div>
      </div>
      <Footer contact='not'/>
    </>
  );
}