import React from "react";
export const Contactanos = (props) => {
  const {type, texto, styles,mensaje} = props;

    return (
        <>
          {
            type==='border'?
            <button className="text-center base sm:base lg:base  md:inline-block cursor-pointer hover:text-gray-500 py-2 rounded-full px-3  border-2 border-primary mr-3 mt-0 sm:mt-3 lg:mt-0"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                <a
                //const url = `https://api.whatsapp.com/send?phone=5916188586&text=${mensaje}`;
                  href={`https://api.whatsapp.com/send?phone=59161885865&text=${mensaje?mensaje:''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {texto}
                </a>
              </button>
              :
              type === 'fill'?
              <button  className={
                `border-primary border-2 px-5 py-2 rounded-3xl my-3 -bottom-px text-white bg-primary mt-12`
              }>
                <a
                  href={`https://api.whatsapp.com/send?phone=59161885865&text=${mensaje?mensaje:''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {texto}
                </a>
              </button>:
              type === 'text'?
              <button  className={`font-bold  text-base fontWeight-thin`} style={styles}>
                <a
                  href={`https://api.whatsapp.com/send?phone=59161885865&text=${mensaje?mensaje:''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {texto}
                </a>
              </button>:
           null

          }
        </>
    );
}

