import React from "react";
import { Background } from "react-parallax";
import Banner from "../components/banner";
//crear componente funcinal
const HeaderPage = (props) => {
    return (
        <>
            <div className="w-full text-white pt-52 pb-36 sm:pt-24 sm:pb-16 md:pt-52 md:pb-36" style={{backgroundImage:`url(${props.image})`,backgroundSize:'cover' }}>
              <div className="flex flex-col justify-center items-center py-5 md:py-5 sm:px-3" style={{backgroundColor:'#00000099'}}>
                <h3>{props.titulo}</h3>
                <h1 className="text-5xl my-5 sm:text-2xl md:text-5xl sm:my-2 md:my-5">{props.mensaje}</h1>
                <h3>Inicio / {props.nombre}</h3>
              </div>  
            </div>
        </>
    );
}

export default HeaderPage;