/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from '../reducers/auth';
import { userReducer } from '../reducers/user';
import { dataPageReducer } from '../reducers/dataPage';
import { examReducer } from '../reducers/exam';
import { cartReducer } from '../reducers/cart';
import { testReducer } from '../reducers/test';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const reducers = combineReducers({
    auth:authReducer,
    user:userReducer,
    exam:examReducer,
    cart:cartReducer,
    dataPage:dataPageReducer,
    test:testReducer,
});

export const store = createStore(reducers, enhancer);
    
