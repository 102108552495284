import React,{useEffect, useState, useRef} from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import {auth } from '../../config/firebase';
import Modal from '../components/Modal';
import {db} from '../../config/firebase';
import dbRef from '../../config/dbRef';

import Panel from './components/panel';

import { collection, query, where, getDocs,updateDoc,doc  } from "firebase/firestore";

export default function AdminAfiliate() {
  const reduxUser = useSelector(state => state.user);
  
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const [code, setCode] = useState(false);
  const [codeValue, setCodeValue] = useState('');

  const codeState = useRef(null);
  const buttonRef = useRef(null);
  //const [user, setUser] = useState(null);
  useEffect(() => {
    console.log(reduxUser.user);
    setUser(reduxUser.user);
  }, [reduxUser])

  const handleRegister = async() => {
    try{
      if(code){
        buttonRef.current.innerText = 'Registrando....';
        buttonRef.current.disabled = true;
        const docRef = doc(db, dbRef.user, auth.currentUser.uid);
        await updateDoc(docRef, {
          code:codeValue,
          isAfiliate:true,
        });
        buttonRef.current.innerText = 'Registrar';
        buttonRef.current.disabled = false;
        setModal(false);
        alert('Felicidades, ahora eres un afiliado de Apriori');
      }
    }catch(err){
      console.log(err);
    }
    
  }

  const verifyCode = async(code) => {
    if(code.length === 4){
      //setCode(true);
      codeState.current.innerText   = 'comprobando código....';
      const querySnapshot = await getDocs(
        query(collection(db, dbRef.user),where("code", "==", code))
      );    
      if(querySnapshot.size>0){
        codeState.current.classList.remove('text-green-500');
        codeState.current.classList.add('text-red-500');
        codeState.current.innerText   = 'Código en uso, intente con otro';
        setCode(false);
      }else{
        codeState.current.classList.remove('text-red-500');
        codeState.current.classList.add('text-green-500');
        codeState.current.innerText   = `Código Valido`;
        setCode(true);
        setCodeValue(code);
      }
    }else{
      setCode(false);
      codeState.current.innerText  = 'El codigo debe ser de 4 caracteres';

    }
  }

  


  return (
    <>
      <Modal 
        visible={modal}
        title="Registro de Afiliado"
        onClose={()=>setModal(false)}
      >
        <div className="flex flex-col justify-center items-center mt-4">
          <p className="text-center">Para poder ser afiliado, debe introducir un Codigo de 4 caracteres, preferiblemente numeros.
          </p>
          <p className='text-center text-sm text-gray-500'>
          Este codigo sera el que debera compartir con sus colegas para que puedan suscribirse a Apriori y usted pueda recibir una retribucion por cada suscripcion.
          </p>
          <div className='w-full flex flex-col justify-center items-center mt-2'>
              <label className='text-primary text-base'>Codigo de Afiliado: </label>
              <input 
                type="text" 
                className='border-2 border-primary p-2 rounded-lg w-2/3 mt-4 text-center' 
                maxLength={4}
                onChangeCapture={(e)=>{verifyCode(e.target.value)}}
              />
              <p className='text-center text-sm text-gray-500' ref={codeState}>*Este codigo debe ser de 4 caracteres</p>
              <div className='flex flex-row justify-center items-center'>
                <button className='border-primary border-2 text-primary p-2 rounded-full px-4 mt-4 mx-4' onClick={()=>setModal(false)}>Cancelar</button>
                <button className='bg-primary text-white p-2 rounded-full px-4 mt-4' ref={buttonRef} onClick={()=>handleRegister()} >Registrar</button>
              </div>
          </div>
        </div>
      </Modal>
      {
        !user?.isAfiliate ?
        <div className='mt-16 p-4 text-center w-full flex flex-col justify-center items-center'>
          <h1 className='text-2xl text-primary my-4'>Hola {user?.name}</h1>
          <p className='w-2/3'>
            El plan de afiliados es una excelente oportunidad que Apriori te brinda para que puedas recibir una retribución por cada vez que recomiendes nuestro servicio a otras personas o colegas. Si alguno de los colegas a los que les hablaste de nosotros se suscribe a Apriori, recibirás un porcentaje de su suscripción. ¡Aprovecha esta oportunidad y comienza a ganar dinero recomendando Apriori!
          </p>
          <p className='w-2/3 mt-4 text-primary text-xl'>
            ¿Quiere comenzar Ahora?
          </p>
          <div className='w-2/3 mt-4'>
            <Link to='/profile' className='border-2 border-primary text-primary p-2 px-4 rounded-full'>No, volver a perfil</Link>
            <button className='bg-primary text-white p-2 rounded-full px-4 ml-4'  onClick={()=>setModal(!modal)}>Si, quiero ser Afiliado</button>
          </div>
        </div>
        :
        <Panel user={user} idUser={auth.currentUser.uid}/>
      }
      


    </>
  )
}
