import {db, auth} from '../../config/firebase'
import { collection, getDocs } from "firebase/firestore";
import { doc, getDoc, updateDoc} from "firebase/firestore";
import types from '../types/user';
import dbRef from '../../config/dbRef';

const getUserRequest = payload => ({
    type: types.GET_USER_REQUEST,
    payload:payload,
});

const getUserSuccess = payload => ({
    type:types.GET_USER_SUCCESS,
    payload:payload,
});

const getUserFailure = payload => ({
    type:types.GET_USER_FAILURE,
    payload:payload,
});

export const clearUser = payload => ({
    type:types.CLEAR_USER,
    payload:payload,
});
const updateIntntos = payload => ({
    type:types.UPDATE_INTENTOS,
    payload:payload,
});

export const getUser = (uid) => {
    return async (dispatch) => {
        try{
            dispatch(getUserRequest());
            const docRef = doc(db, dbRef.user, uid);
            const docSnap = await getDoc(docRef);

            const cuenta = doc(db, dbRef.user, uid);
            const cuentaSnap = await getDocs(collection(cuenta, "cuenta"));



            let cuentaData = [];
            cuentaSnap.forEach(doc => {
                cuentaData.push({id:doc.id,...doc.data()});
            });
            console.log('data obtenido de usuario cuenta');
            console.log(cuentaData);


            if (docSnap.exists()) {
                let user={
                    birthday:docSnap.data().birthday,
                    city:docSnap.data().city,
                    country : docSnap.data().country,
                    email : docSnap.data().email,
                    lastName : docSnap.data().lastName,
                    name : docSnap.data().name,
                    phone : docSnap.data().phone,
                    photo : docSnap.data().photoUrl,
                    recursos : docSnap.data().recursos,
                    mensajes : docSnap.data().mensajes,
                    code : docSnap.data().code,
                    codeAfiliate : docSnap.data().codeAfiliate,
                    isAfiliate : docSnap.data().isAfiliate,
                }
                dispatch(getUserSuccess({user:user,cuenta:cuentaData}));
            } else {
                dispatch(getUserFailure('No existe el usuario'));
                console.log("[Actions][user][getUser]:Error No existe el usuario");
            }
            
        } catch(error){
            dispatch(getUserFailure(error));
            console.log("[Actions][user][getUser]:", error);
        }
    };
};
export const updateIntentos = (idCuenta, idExamen) => {
    return async (dispatch) => {
        try {
            const userId = auth.currentUser.uid;
            const cuenta = doc(db, dbRef.user, userId,'cuenta',idCuenta);
            //update cuenta intentos
            const cuentaSnap = await getDoc(cuenta);
            const examenes = cuentaSnap.data().examenes;
    
            examenes.forEach((examen)=>{
                if(examen.id === idExamen){
                    examen.isResolve = true;
                }
            });

            console.log(cuentaSnap.data().intentos+ ": esta es l cantidad de intentos disponibles");
            await updateDoc(cuenta, {intentos:cuentaSnap.data().intentos-1, examenes:examenes});

            dispatch(getUser(userId));

            console.log("se redujo los intentos");
        } catch(e){
            console.log(e);
        }
    }
}
export const fetchUsers = (payload) => {
    return dispatch => {
        dispatch(getUserRequest(payload));
        getDocs(collection(db,'user'))
        .then((response)=>{
            let user =[]
            response.forEach((doc)=>{
                console.log(doc.data());
                console.log('datos recogido aqui')
                user.push(doc.data());
            });
            return user;
        }).then( user =>console.log("log"))
        /*

        const user = await getDocs(collection(db, "user"));
        let data = [];
        user.forEach((doc) => {
            data.push(doc.data())
        });
        dispatch(successGetUsers(data))*/
    };
}

export const _fetchUsers = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, '_user'));
        let users = [];

        querySnapshot.forEach((doc) => {
            users.push({ id: doc.id, ...doc.data() });
        });

        return { users, error: null };
    } catch (error) {
        console.error("Error al obtener usuarios:", error);
        return { users: [], error: error.message };
    }
};