import { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';

export default function EventsCard(props){
    const {fecha, contenido, expositor, modalidad, nombre, url} = props.data.data;
    const [isOpen, setIsOpen] = useState(false);
    console.log(props.data)
    return (
      <>
       <Modal visible={isOpen} title="Apriori Eventos." onClose={()=>setIsOpen(false)}>
         <div className='text-center'>
          <h1 className='text-primary my-4'>Bienvenido a Apriori - Eventos, una nueva forma de aprender </h1>
          <p>Le damos la bienvenida al evento <b>{nombre}</b>, impartido por el {expositor}.</p>
          <p>Para una mejor experiencia le recomendamos tener buena conexion a internet, agüita o cafe y estar comodos</p>
          <div className='my-8'>
            {/* {<Link to={url} target='_blank' className="bg-primary text-white py-2 px-4 my-16 rounded-md" >Comenzar</Link>} */}
            <Link className="bg-primary text-white py-2 px-4 my-16 rounded-md" to={{
            pathname: `/Live`,
            data: props.data,
          }}>Comenzar</Link>
          </div>
          
         </div>
         
         
       </Modal>
        <div className=" flex flex-col m-3 justify-between items-center text-black border-accent border-2 w-full max-w-45  md:max-w-45 sm:max-w-full rounded-md">
        <div className=" flex flex-row ">
          <div className="flex flex-col justify-start w-2/4 "> 
          <h1 className="bg-accent text-white text-center py-2 p-2">{nombre}</h1>
          <div className="px-3">
            
            <p className="text-start text-primary">Fecha de evento: <span className="text-black">{fecha}</span> </p>
            <p className="text-start text-primary">Contenido: <span className="text-black"></span> </p>
              <ol className="pl-5">
                {
                  contenido.map((item, index) => {
                    return <li type="square" key={index}>{item}</li>
                  }
                  )
                }
              </ol>
            </div>
          </div>
          <img className="border-white w-2/4 rounded-tr-md" src={url} alt=""/>
        </div>
        <div className="bg-gray-200 w-full justify-center flex flex-col px-3 py-2 rounded-b-md">
          {
            expositor.length === 1 ?
            <p className="text-start text-primary">Expositor</p>
            :
            <p className="text-start text-primary">Expositores:</p>
          }
          <ol className="pl-5 text-black">
            {
              expositor.map((item, index) => {
                return <li key={index}>{item}</li>
              }
              )
            }
          </ol>
            
          <p className="text-start text-primary">Medio: <span className="text-black pl-1">{modalidad}</span> </p>
  
          <button className="bg-primary text-white py-2 px-4 mt-3 rounded-md" onClick={() => setIsOpen(!isOpen)}>
            {'Ingresar'}
          </button>
        </div>
  
        </div>
      </>
    );
  }
  