import React from 'react'

export const BenefitItem = ({ benefit }) => {
  return (
    <li className="flex items-center justify-between text-sm">
      <span>{benefit.descripcion}</span>
      {benefit.disponible === 1 ? (
        <p>Si</p>
      ) : (
        <p className='px-3'>No</p>
      )}
    </li>
  )
}
