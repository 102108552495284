import types from '../types/test';

const addTestFailure = payload => ({
    type: types.ADD_TEST_FAILURE,
    payload:payload,
});

const addTestSuccess = payload => ({
    type:types.ADD_TEST_SUCCESS,
    payload:payload,
});

const remplaceTestFailure = payload => ({
    type: types.REMPLACE_TEST_FAILURE,
    payload:payload,
});

const remplaceTestSuccess = payload => ({
    type:types.REMPLACE_TEST_SUCCESS,
    payload:payload,
});


export const addTest = (payload) => {
    return (dispatch) => {
        try{
            dispatch(addTestSuccess(payload))
            // alert("lleogotest");
        } catch(error){
            dispatch(addTestFailure())
            console.log("ErrorTest:", error);
        }
    };
}

export const remplaceTest = (payload) => {
  return (dispatch) => {
      try{
          dispatch(remplaceTestSuccess(payload));
      } catch(error){
          dispatch(remplaceTestFailure())
          console.log("ErrorTest:", error);
      }
  };
}