import React,{useState} from "react";
import Header from "../components/headerPage";
import { FaFacebookSquare ,FaTwitter ,FaInstagramSquare} from "react-icons/fa";
import { GiSmartphone } from "react-icons/gi";
import { BiDirections } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import  Comments  from "../components/comments";
import Footer from "../components/footer";
import contact from "../../assets/images/contact.jpg";


const Contact = () => {
  const [ciudad,setCiudad]= useState("abc");
    return (
        <>
        <Header titulo={"Contacta con nosotros"} nombre={"Contacto"} mensaje={"Contáctanos ahora"} image={contact}/>
        <div className="h-auto w-full py-20 px-32 sm:px-4 md:px-20 sm:py-4 md:py-4 flex flex-row sm:flex-col md:flex-row justify-between">
            <div  className="w-1/2 sm:w-full md:w-1/2 p-10 flex flex-col justify-start text-left sm:text-center md:text-left">
                <p className="text-lg font-bold text-primary">Envíanos un mensaje:</p>
                <p className="text-3xl">Formulario de contacto</p>
                <input id="contact-name" type="text" className="w-full p-3 mt-3 my-1 bg-gray-200" placeholder="Nombre"/>
                <input
                  id="contact-email"
                type="text" className="w-full p-3 my-1 bg-gray-200" placeholder="Correo electronico"/>
                <div
                className="flex flex-row justify-between items-center mt-3">
                  <input 
                  id="contact-phone"
                  type="text" className="w-1/2 p-3 my-1 bg-gray-200 mr-3" placeholder="Número telefonico"/>   
                  <select
                  id="contact-city"
                  value={ciudad}
                  className="w-1/2 p-3 my-1 bg-gray-200"
                  onChange={e => setCiudad(e.target.value)}
                  name='ciudad' >
                    <option value="0">Ciudad de residencia</option>
                    <option value="chuquisaca">Chuquisaca</option>
                    <option value="laPaz">La Paz</option>
                    <option value="cochabamba">Cochabamba</option>
                    <option value="oruro">Oruro</option>
                    <option value="potosi">Potosi</option>
                    <option value="tarija">Tarija</option>
                    <option value="pando">Pando</option>
                    <option value="beni">Beni</option>
                    <option value="santaCruz">Santa Cruz</option>
                  </select>
                </div>
                <textarea
                id="contact-message"
                className="w-full p-3 my-1 bg-gray-200" placeholder="Mensaje"></textarea>
                <button 
                onClick={() => {
                  const name = document.getElementById('contact-name').value;
                  const email = document.getElementById('contact-email').value;
                  const phone = document.getElementById('contact-phone').value;
                  const city = document.getElementById('contact-city').value;
                  const message = document.getElementById('contact-message').value;
                  window.open(`https://api.whatsapp.com/send?phone=59161885865&text=Nombre: ${name}%0AEmail: ${email}%0ATelefono: ${phone}%0ACiudad: ${city}%0AMensaje: ${message}`);

                }}
                className="w-1/3 sm:w-full md:w-1/3 p-3 mt-6 sm:mt-2 md:mt-6 bg-primary text-white rounded">Enviar</button>

</div>
            <div className="w-1/2 sm:w-full md:w-1/2 p-10 sm:p-4 md:p-10 flex flex-col justify-start sm:justify-center md:justify-start mx-1">
            <p className="text-lg text-primary font-bold sm  text-left sm:text-center md:text-left">¿Quíenes somos?
            </p>
            <p className="text-4xl  text-left sm:text-center md:text-left sm:text-2xl md:text-4xl">
            Apriori es la primera plataforma que te prepara para el examen de competencias.
            </p>
            <ol className="text-gray-500">
                <li className="flex flex-row mx-0 sm:mx-auto md:mx-0 my-3 ml-5 sm:ml-0 md:ml-5"><a className="flex flex-row base" href="mailto:apriori.bolivian@gmail.com" ><AiOutlineMail size={25} className="mr-3" color="#e84529"/>Email:apriori.bolivian@gmail.com</a></li>
                <li className="flex flex-row mx-0 sm:mx-auto md:mx-0 my-3 ml-5 sm:ml-0 md:ml-5">
                  <a
                    href="https://wa.me/56975555555"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-row base" 
                  >
                    <GiSmartphone  size={25} className="mr-3" color="#e84529"/>
                    Telefono:+591 61885865
                  </a>
                </li>
            </ol>
            <div className="flex flex-row justify-start sm:justify-center md:justify-start text-5xl mt-3">
              <a
                href="https://www.facebook.com/aprioribol"
                target="_blank"
                rel="noopener noreferrer"
              ><FaFacebookSquare  className="mx-3"  color="#1A2527"/></a>
              <a href="https://www.instagram.com/apriori_bo"
                target="_blank"
                rel="noopener noreferrer"
              ><FaInstagramSquare className="mx-3" color="#1A2527"/></a>
            </div>
                
            </div>
            
        </div>
        <Footer/>
        
        </>
    );
}

export default Contact;