/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React,{useState, useEffect} from "react";
import Header from "../components/headerPage";
import Footer from "../components/footer";
import image from "../../assets/images/convocatoria.jpg";
import {db} from '../../config/firebase';
import dbRef from '../../config/dbRef';
import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import { OnLoad } from "../components/onload";


const Details = (props) => {
  const [data,setData]= useState([]);
  const [juridico,setJuridico]= useState(false);
  const [medico,setMedico]= useState(false);
  const [academico,setAcademico]= useState(false);
  const [policial,setPolicial]= useState(true);

  const [dataJur,setDataJur]= useState([]);
  const [dataMed,setDataMed]= useState([]);
  const [dataAcad,setDataAcad]= useState([]);
  const [dataPol,setDataPol]= useState([]);
  const [indiceJur,setIndiceJur]= useState(0);

  useEffect(() => {
    getDocs(collection(db,dbRef.exam))
    .then((response)=>{
        response.forEach((doc)=>{
          switch(doc.data().area){
            case 'juridico':
              dataJur.push({id:doc.id,data:doc.data()});break;
            case 'medico':
              dataMed.push({id:doc.id,data:doc.data()});break;
            case 'academico':
              dataAcad.push({id:doc.id,data:doc.data()});break;
            default:
              dataPol.push({id:doc.id,data:doc.data()});break;
          }
        });
    }).then( doc =>{console.log(dataJur); setData(doc)})
    console.log(dataPol);
  } ,[])

  const handleClick = (param) =>{
    switch(param){
      case 1:{
        setJuridico(true);
        setMedico(false);
        setAcademico(false);
        setPolicial(false);
      };break;
      case 2:{
        setJuridico(false);
        setMedico(true);
        setAcademico(false);
        setPolicial(false);
      };break;
      case 3:{
        setJuridico(false);
        setMedico(false);
        setAcademico(true);
        setPolicial(false);
      };break;
      default:{
        setJuridico(false);
        setMedico(false);
        setAcademico(false);
        setPolicial(true);
      };
    
  }}

  const Card = (props) =>{
    return(
      <div className="bg-basic border-2 border-b-green-500 flex flex-col items-stretch justify-between rounded-lg">
        <div className="bg-accent py-2 w-full rounded-t-lg border-2 border-b-green-500">
          <h3 className="text-center text-white font-bold  self-center px-12 underline decoration-solid">{props.data.data.nombre}</h3>
          <p className="text-center text-white ">N° Convocatoria 004/22</p>
          <p>{props.id}</p>
        </div>
        <div className="flex flex-row justify-between p-4  sm:p-2 md:p-4">
          <div className="w-full">
            <p className="text-start text-primary text-lg text-center">Vigencia:</p>
            <p className="text-start text-black text-center font-semibold">Del: <span className="text-gray-600 font-normal" >{props.data.data.fecha}</span> - Al: <span className="text-gray-600  font-normal" >{props.data.data.fecha}</span></p> 
            {/* <p className="text-primary text-center mt-4 text-lg">Distritos:</p>

            <ul className="flex flex-row justify-center w-full flex-wrap text-sm">
              <li type='disc' className="mx-4">Sucre</li>
              <li type='disc' className="mx-4">Potosi</li>
              <li type='disc' className="mx-4">Tarija</li>
              <li type='disc' className="mx-4">Cochabamba</li>
              <li type='disc' className="mx-4">Chuquisaca</li>
              <li type='disc' className="mx-4">Beni</li>
              <li type='disc' className="mx-4">Pando</li>
            </ul> */}
          </div>
          
        </div>
        <Link className="text-primary font-normal mx-auto my-2 py-2 px-4 rounded my-3 hover:text-white hover:bg-primary border-2 border-primary" to={{
          pathname: `/Details`,
          data: props.data,
        }}>Ver Detalles</Link>

      </div>
    )
  }

    return (
        <>
        <Header titulo={"Convocatorias"} nombre={"Convocatoria"} mensaje={"Tu futuro aqui"} image={image} posicion={'center'} />

        <div className="h-auto w-full py-3 px-32 sm:px-4 md:px-32 my-5 mb-12">
          <h1 className="text-4xl sm:text-2xl md:text-4xl font-bold text-primary text-center w-full">Convocatorias Previas</h1>
          
          {/* <div className="flex flex-row justify-center w-full border-accent mt-6" style={{paddingBottom:'0'}}>
            <p onClick={()=>{handleClick(1)}} style={{cursor:'pointer', backgroundColor:`${juridico?'#750016':'transparent'}`,color:`${juridico?'white':'#750016'}`}} className="text-xl text-accent border-2 border-accent border-b-0 border-r-0 p-2 px-6 rounded-tl-2xl">Vigentes</p>
             <p onClick={()=>{handleClick(2)}} style={{cursor:'pointer', backgroundColor:`${medico?'#750016':'transparent'}`,color:`${medico?'white':'#750016'}`}} className="text-xl text-accent border-2 border-accent border-b-0 border-r-0 p-2 px-6">Pasadas</p>
           <p onClick={()=>{handleClick(3)}} style={{cursor:'pointer', backgroundColor:`${academico?'#750016':'transparent'}`,color:`${academico?'white':'#750016'}`}} className="text-xl text-accent border-2 border-accent border-b-0 border-r-0 p-2 px-6">Académico</p> 
            <p onClick={()=>{handleClick(4)}} style={{cursor:'pointer', backgroundColor:`${policial?'#750016':'transparent'}`,color:`${policial?'white':'#750016'}`}} className="text-xl text-accent border-2 border-accent border-b-0 p-2 px-6 rounded-tr-2xl">Pasadas</p>
          </div>*/}
          <div className="flex flex-col justify-center  w-full border-t-2 border-accent">
          <p className="mt-2 text-center w-full">
            Te presentamos las convocatorias que hemos atendido en el área judicial, puedes preguntar por las que no se encuentran en esta lista.
          </p>
             <div style={{display:`${juridico?'grid':'none'}`}} className=" grid grid-cols-2  sm:grid-cols-1 md:grid-cols-2 gap-4 m-8 ">
              {
                dataJur.length>0?
                dataJur.map((item,index)=>{
                  console.log(item);
                  let fechaItem = item.data.fecha.split('/');
                  console.log(fechaItem);
                  let fecha = new Date(fechaItem[1]+'/'+fechaItem[0]+'/'+fechaItem[2]);
                  let ahora = new Date();
                  if(fecha>ahora){
                    return(
                      <Card key={index} data={item}/>
                    )
                  }
                  else{
                    return null;
                  }
                })
                :
                <div className="text-center col-span-2">
                  <p className="text-center">No hay Convocatorias en esta area.</p>
                  <p className="text-center text-blue-700 mb-5 text-sm">Conoces alguna que no este aqui? </p>
                   <Link className="bg-primary text-white font-normal mx-auto py-2 px-4 rounded mt-5" to={{
                    pathname: `/contact`,
                    data: {
                      nombre: 'Convocatoria'
                    }
                  }}>Contactanos</Link>
                  <p className="text-center text-gray-700 mt-5 text-sm ">Avisanos de una convocatoria que no conozcamos y ganate una suscripcion gratis </p>
                
                </div>
              }
            </div>
            {/*<div style={{display:`${medico?'grid':'none'}`}} className="container grid grid-cols-2 gap-4 m-8">
              {
                 dataJur.length>0?
                 dataJur.map((item,index)=>{
                   console.log(item);
                   let fechaItem = item.data.fecha.split('/');
                   console.log(fechaItem);
                   let fecha = new Date(fechaItem[1]+'/'+fechaItem[0]+'/'+fechaItem[2]);
                   let ahora = new Date();
                   if(fecha<ahora){
                     return(
                       <Card key={index} data={item}/>
                     )
                   }
                   else{
                     return null;
                   }
                 })
                :
                <div className="text-center col-span-2">
                  <p className="text-center">No hay Convocatorias en esta area.</p>
                  <p className="text-center text-blue-700 mb-5 text-sm">Conoces alguna que no este aqui? </p>
                   <Link className="bg-primary text-white font-normal mx-auto py-2 px-4 rounded mt-5" to={{
                    pathname: `/contact`,
                    data: {
                      nombre: 'Convocatoria'
                    }
                  }}>Contactanos</Link>
                  <p className="text-center text-gray-700 mt-5 text-sm ">Avisanos de una convocatoria que no conozcamos y ganate una suscripcion gratis </p>
                
                </div>
              }
            </div>
            <div style={{display:`${academico?'grid':'none'}`}} className="container grid grid-cols-2 gap-4 m-8">
              {
                dataAcad.length>0?
                dataAcad.map((item,index)=>{
                  return(
                    <Card key={index} data={item}/>
                  )
                })
                :
                <div className="text-center col-span-2">
                  <p className="text-center">No hay Convocatorias en esta area.</p>
                  <p className="text-center text-blue-700 mb-5 text-sm">Conoces alguna que no este aqui? </p>
                   <Link className="bg-primary text-white font-normal mx-auto py-2 px-4 rounded mt-5" to={{
                    pathname: `/contact`,
                    data: {
                      nombre: 'Convocatoria'
                    }
                  }}>Contactanos</Link>
                  <p className="text-center text-gray-700 mt-5 text-sm ">Avisanos de una convocatoria que no conozcamos y ganate una suscripcion gratis </p>
                
                </div>
              }
            </div> */}
            <div style={{display:`${policial?'flex':'none'}`}} className="  m-8">
              {
                 dataJur.length>0?
                 <div className="text-center">
                  <div className=" grid grid-cols-2  sm:grid-cols-1 md:grid-cols-2 gap-4 m-8">
                    {dataJur[indiceJur] && <Card data={dataJur[indiceJur]}/> }
                    {dataJur[indiceJur+1] && <Card data={dataJur[indiceJur+1]}/> }
                    {dataJur[indiceJur+2] && <Card data={dataJur[indiceJur+2]}/> }
                    {dataJur[indiceJur+3] && <Card data={dataJur[indiceJur+3]}/> }
                  </div>
                  <div id="paginacion" className="">
                    <div className="flex flex-row justify-center">
                      <button onClick={()=>{
                      if (indiceJur>0)
                        setIndiceJur(indiceJur-4)
                      }} className={`text-xl text-white ${indiceJur>0?"bg-accent":"bg-gray-400"} p-2 px-6 rounded-l-2xl`}> ◄ </button>
                      <p
                        className="text-xl text-accent border-2 border-accent bg-transparent p-2 px-6"
                      >{parseInt(indiceJur/4)+1}</p>
                      <button onClick={()=>{
                        if(indiceJur+4<dataJur.length){
                        setIndiceJur(indiceJur+4)
                        }
                      }} className={`text-xl text-white ${indiceJur+4<dataJur.length?"bg-accent":"bg-gray-400"} p-2 px-6 rounded-r-2xl`}> ► </button>
                    </div>
                  </div>
                  <p className="mt-2">{
                  parseInt(indiceJur/4)+1
                  } paginas de {
                    dataJur.length%4===0?
                    parseInt(dataJur.length/4)
                    :
                    parseInt(dataJur.length/4)+1
                  }</p>
                  </div>
                 
                :
                <div className="text-center w-full flex flex-col items-center">
                  <p className="text-center font-bold text-primary text-2xl my-4">Cargando Datos...</p>
                  <OnLoad color="gray"/>
                  {/* <p className="text-center">No hay Convocatorias en esta area.</p>
                  <p className="text-center text-blue-700 mb-5 text-sm">Conoces alguna que no este aqui? </p>
                   <Link className="bg-primary text-white font-normal mx-auto py-2 px-4 rounded mt-5" to={{
                    pathname: `/contact`,
                    data: {
                      nombre: 'Convocatoria'
                    }
                  }}>Contactanos</Link>
                  <p className="text-center text-gray-700 mt-5 text-sm ">Avisanos de una convocatoria que no conozcamos y ganate una suscripcion gratis </p> */}
                
                </div>
              }
            </div>
          </div>
        </div>
        <Footer/>
        
        </>
    );
}

export default Details;