import { db } from '../../src/config/firebase';
import dbRef from '../config/dbRef';
import {shuffleArray} from './arrays';
//import { useDispatch, useSelector } from 'react-redux';
import { doc, addDoc, collection} from "firebase/firestore";

export const setSuggestion =  (data) =>
new Promise(async (resolve, reject) => {
    try{
        const {name, lastname, email, phone, description} = data;
        const docRef = await addDoc(collection(db, dbRef.suggestios), {
            name: name,
            lastname: lastname,
            email: email,
            phone: phone,
            description: description
        });
        resolve(docRef.id);
    } catch (error) {
        reject(error);
    }
});