import React,{useEffect, useState} from "react";
import Footer from "../components/footer";
import { useSelector, useDispatch } from 'react-redux';
import Modal from "../components/Modal";
//importar firebase storage
//import { storage } from "../../config/firebase";
//import {getDownloadURL, ref as referencia, uploadBytesResumable} from "@firebase/storage";
import { auth } from "../../config/firebase";
import imgQr from './../../assets/images/qr.png'
import { db } from "../../config/firebase";
import dbRef from "../../config/dbRef";
//import { collection} from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";// addDoc
import { Link } from "react-router-dom";
//import {BsWhatsapp} from "react-icons/bs";
import {Dots} from "../components/onload"

import {FiClock} from "react-icons/fi";
import {FiClipboard} from "react-icons/fi";
import {AiOutlineFileText} from "react-icons/ai";
import {CgCheck} from "react-icons/cg";
import {BiX} from "react-icons/bi";
import {BsFillTelephoneFill} from "react-icons/bs";
import {getDataPage} from "../../redux/actions/dataPage"
import newPlan from "../../assets/images/newPlan.jpg"

import {IoMdRadioButtonOff, IoMdRadioButtonOn, IoMdCheckmark} from "react-icons/io";

const RadioButton = (props) => {
  const [active , setActive] = useState(props.active);
  useEffect(() => {
    setActive(props.active);
  }, [props.active]);
  return (
    <div className="flex flex-row justify-center items-center cursor-pointer" onClick={
      ()=>{
        setActive(!active);
        props.onClick();
      }
    }>

      {
        active ? <IoMdRadioButtonOn color={props.color}/> : <IoMdRadioButtonOff color={props.color}/>
      }
      
      <p className="text-sm">{props.label}</p>
    </div>
    
  );
}


const Plans = (props) => {
  
  const [data,setData]= useState([]);
  const [id, setId] = useState('');
  const [visible, setVisible] = useState(false);
  const [tigo, setTigo] = useState(true);
  const [deposito, setDeposito] = useState(false);
  const [transferencia, setTransferencia] = useState(false);
  const [qr, setQr] = useState(false);
  const [child, setChild] = useState('');

  const [plan, setPlan] = useState('0');
  const [banco, setBanco] = useState('bnb');
  const [titulo, setTitulo] = useState('Titulo');
  const [telefono, setTelefono] = useState('Telefono');
  const [monto, setMonto] = useState(0);
  const [imagen, setImagen] = useState('');
  const [progreso , setProgreso] = useState(0);
  const [url, setUrl] = useState('');
  const [load, setLoad] = useState(0);//0 false -1 true -2 loading
  const [text, setText] = useState('ninguna imagen');

  const [textAdvertencia, setTextAdvertencia] = useState('Inntroducir un numero de telefono valido');

  const [metodo, setMetodo] = useState('vacio');

  // const storage 
  const info = useSelector(state => state.dataPage);

  const [dataPage , setDataPage] = useState(info);
  //verificar si el store de redux esta vacio
  useEffect(() => {
    if(info.dataPage[0]){
      // alert ('si hay datos');
      setDataPage(info);
    }else{
      dispatch(getDataPage());
      setDataPage(info);
    }
  }, []);

  useEffect(() => {
    setDataPage(info.dataPage[0]);
  }, [info]);

  const dispatch = useDispatch();
  console.log(auth.currentUser);

  const changeRadioButton = (index) => {
    switch (index) {
      case 1:
        setTigo(true);
        setDeposito(false);
        setTransferencia(false);
        setQr(false);
        break;
      case 2:
        setTigo(false);
        setDeposito(true);
        setTransferencia(false);
        setQr(false);
        break;
      case 3:
        setTigo(false);
        setDeposito(false);
        setTransferencia(true);
        setQr(false);
        break;
      case 4:
        setTigo(false);
        setDeposito(false);
        setTransferencia(false);
        setQr(true);
        break;
      default:
        break;
    }
  }

  const subirPlan = () => {
    if(plan  !=='0'){
      setLoad(1);
      setChild('deposito');
      setTitulo('Información')
      updateDocumento(data.id,'ninguno').then(()=>{
        setLoad(2);
        setVisible(true);
      })
    }else{
      setText("Seleccione un plan");
    }
  }

  const sendWhatsapp = () => {
    if (telefono.length > 7 && telefono.length < 9) {
      let mensaje= `${
        banco === 'bnb'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :* 4500837492 *Carnet de Identidad :* 10377499 *Banco :* Banco Nacional de Bolivia (BNB) *Monto :* ${monto}`:
        banco === 'bisa'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Abigail Mamani *Numero de cuenta :*  495340-401-5 *Carnet de Identidad :* 13427301 pt *Banco :* Banco Bisa *Monto :* ${monto}`:
        banco === 'union'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :*  10000042944682 - Caja de ahorro M/N *Carnet de Identidad : *10377499 *Banco :* Banco Unión *Monto :* ${monto}`:
        `*CUENTA DE DEPOSITO: Nombre del Titular :* Rocely Valeria Cruz *Numero de cuenta :*  703-2-1-03486-9 *Carnet de Identidad :* 10330317 *Banco :* Banco Prodem *Monto :* ${monto}`


      }`;
      const url = `https://api.whatsapp.com/send?phone=591${telefono}&text=${mensaje}`;
      window.open(url, '_blank');
    }else{
      alert("Introducir un numero de telefono valido");
    }
  }

  const updateDocumento = async (paramId) => {
    //const messageRef = doc(db, "rooms", "roomA", "messages", "message1");
    const authUser = auth.currentUser.uid;
    const cuenta = doc(db, dbRef.user, authUser,'cuenta',paramId);
    const newExam = {
      metodo: metodo,
      banco: banco,
      rebizado: false,
      plan: plan,
    }
    await updateDoc(cuenta, newExam);
    // await updateDoc(cuenta, paramId, newExam);
    setText("Comprobante enviado");

  };

  useEffect(() => {
    
    if(!props.location.data){
      props.history.push('/profile');
    }else{
      setData(props.location.data);
      setId(props.location.data.id);
      setPlan(props.location.data.plan);
      console.log(data);
      if(props.location.data.plan !==0 || props.location.data.plan !== 'pendiente'  ){
        setLoad(0);
      }
      else{
        setLoad(2);
      }
    }
  } ,[props.history,load])

  useEffect(() => {
    if (monto < 10) {
      setTextAdvertencia('Debe seleccionar un plan')
    }else{
      if (telefono.length <7) {
        setTextAdvertencia('Debe introducir un numero de telefono valido');
        
      }
      
    }
    if (telefono.length <7) {
      setTextAdvertencia('Debe introducir un numero de telefono valido')
    }

    if(monto > 10 && telefono.length >7){
      setTextAdvertencia('Perfecto');
    }
  } ,[monto,telefono])



    return (
      <>
        <Modal visible={visible} title={titulo} onClose={()=>{setVisible(false);setProgreso(0)}}>
          {
            child === 'wpp' ? 
            <>
              <p className="text-sm">Numero de celular:</p>
              <div className="flex flex-row justify-center items-center">
                <p className="text-base">+591 </p>
                <input onChange={ 
                  (e) => {
                    setTelefono(e.target.value);
                  }
                } className="border-b-2 border-b-gray-400 w-full p-2" type="text" placeholder="75413879"/>
              </div>
              <p className="text-base m-2 text-center">{textAdvertencia}{textAdvertencia==='Perfecto'? true :false  }</p>
              <div className="flex flex-row justify-end items-center self-end">
                <button onClick={()=>{setVisible(false)}} className="hover:bg-red-700 hover:text-white text-primary mt-4 font-bold py-1 px-4 rounded border-2 border-primary mr-2">Cerrar</button>
                <button disabled={false  } onClick={()=>sendWhatsapp() } className={`${textAdvertencia!=='Perfecto'?'bg-gray-500 cursor-not-allowed':'bg-green-500 cursor-pointer'} mt-4 text-white p-2 rounded text-sm self-end`}>Enviar a Whatsapp</button>
              </div>
            </>
            : child === 'deposito' ?
            <>
            <div className="w-full my-4 flex flex-col items-center">
              <p>Muchas Gracias por seleccionar el plan {plan}. </p>
              <p className="text-center mt-2">Para reportar el pago, por favor regrese al su perfil y click en el boton "Reportar Pago" de su examen.</p>
              <div className="flex flex-row justify-center items-center mt-6">
                <Link
                  className="border-2 border-primary text-primary text-sm py-2 px-4 rounded mr-4"
                  to="/profile"
                >Volver a Perfil</Link>
                <button className="bg-green-500 text-white p-2 rounded text-sm self-end flex flex-row items-center px-2 border-2 border-green-500" onClick={()=>{
                  const url = `https://api.whatsapp.com/send?phone=59161885865&text=Quiero reportar el pago de mi plan ${plan}, para el examen de ${data.nombre} .`;
                  window.open(url, '_blank');
                  }}>
                  {/* <BsWhatsapp className="mr-2"/> */}
                  Contactar con Apriori
                </button>
              </div>
            </div>
            <p className="text-primary text-center cursor-pointer" onClick={()=>setVisible(false)}>Cerrar</p>
            </>:'no cargo'
          }
        </Modal>
        <div className="pt-24 flex flex-col justify-center items-center bg-secundary w-full text-white pb-6 text-4xl sm:text-2xl md:text-4xl" style={{backgroundColor:'#303030'}}>
          <div><h1>Planes de pago{'\n'}</h1></div>
          <div className="px-4 text-center"><p className="text-white text-xl sm:text-base md:text-xl mx-auto tex-center">{data.nombre}</p></div>
        </div>
        <div className="flex flex-col-reverse justify-center items-center p-0 mx-0 sm:mx-2 md:mx-0">
          <div className="border-2 border-primary mt-24 rounded-md">
          <div className="w-full p-8 flex flex-col justify-start items-center bg-fondo rounded-t-md">
            <h1 className="text-primary text-3xl font-normal font-bold">Detalles de Plan:</h1>
            <h3 className="text-white text-2xl">{plan==='0'?'No seleccionado':plan}</h3>
          </div>
          <div className="w-full p-8 flex flex-col justify-start items-center bg-gray-100 rounded-b-md">
            <h3 className="text-primary text-xl mt-2 font-bold">Fecha del examen :</h3>
            <p className="text-xl ">{data.fechaExamen}</p>
            <h3 className="text-primary text-xl mt-5 font-bold">Plan seleccionado </h3>
            <p className="text-xl">{plan!=='0' ?plan:'ninguno' }</p>
            <h3 className="text-primary text-xl mt-5 font-bold">Intentos totales :</h3>
            <p className="text-xl">{
              plan === 'fast' ? <><p>3 en Pruebas Prácticas </p><p>1 en Pruebas de finales</p></>:
              plan === 'basico' ? <><p>3 en Pruebas Prácticas </p><p>1 en Pruebas de finales</p></>:
              plan === 'profesional' ? <><p>5 en Pruebas Prácticas </p><p>2 en Pruebas de finales</p></>:
              plan === 'premium' ? <><p>8 en Pruebas Prácticas </p><p>2 en Pruebas de finales</p></>:
              null
            }</p>
            <h3 className="text-primary text-xl mt-5 font-bold">Monto :</h3>
            {
              plan === 'fast' ? <p className="text-xl">60 Bs.</p>:
              plan === 'basico' ? <p className="text-xl">120 Bs.</p>:
              plan === 'profesional' ? <p className="text-xl">210 Bs.</p>:
              plan === 'premium' ? <p className="text-xl">290 Bs.</p>:
              null
            }
            <h3 className="text-primary text-xl mt-5 font-bold">Metodo de pago :</h3>
            <p className="text-sm w-2/3 text-center mb-3">Seleccione el metodo de pago que prefiera, para ver mas información. </p>
            <div className="flex flex-col justify-center items-center">
              {/**panel de seleccion de 4 opciones */}
              <div className="flex flex-col justify-center items-start self-start ">
                <RadioButton color={'green'} active={tigo} label = 'Tigo Money' onClick={()=> changeRadioButton(1)} />
                <RadioButton color={'green'} active={deposito} label = 'Deposito Bancario'  onClick={()=> changeRadioButton(2)}/>
                <RadioButton color={'green'} active={transferencia} label = 'Transferencia' onClick={()=> changeRadioButton(3)}/>
                <RadioButton color={'green'} active={qr} label = 'Codigo QR' onClick={()=> changeRadioButton(4)}/>
              </div>
              
              <div className="flex flex-col justify-center items-start p-4 border-accent border-2 rounded mt-4 ease-in duration-300 acordion">
              <p className="text-primary font-bold">Información :</p>
                {
                  tigo &&
                  <div className="flex flex-col justify-center items-start">
                    <p className="text-sm text-gray-700"><span className="text-primary">Numero de Telefono : </span> 75795725</p>
                    <p className="text-sm text-gray-700"><span className="text-primary">nombre del titular : </span> Rimber Romario Uyuquipa Mamani</p>
                  </div>
                }
                {
                  deposito &&
                  <div className="flex flex-col justify-center items-start">
                    <p className="text-sm text-gray-700"><span className="text-primary">Nombre del Titular : </span>AVENDAÑO VILLARROEL LEONEL</p>
                    <p className="text-sm text-gray-700"><span className="text-primary">Numero de cuenta : </span> 10000042944682 - Caja de ahorro M/N</p>
                    <p className="text-sm text-gray-700"><span className="text-primary">Carnet de Identidad : </span> 10377499 CH.</p>
                    <p className="text-sm text-gray-700"><span className="text-primary">Banco : </span>Banco Union</p>
                    <button className="bg-green-500 mt-4 text-white p-2 rounded text-sm self-end" onClick={()=>{setChild('wpp'); setVisible(true); setTitulo('Enviar Datos:')} } >Enviar estos datos a mi Whatsapp</button>
                  </div>
                }
                {
                  transferencia &&
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row border-b-2 border-accent">
                      <div onClick={()=>setBanco('bnb')} className={`p-2 px-4 text-sm rounded-t-lg cursor-pointer ${banco === 'bnb' ?'bg-accent text-white':'bg-transparent text-accent'}`}>BnB</div>
                      <div onClick={()=>setBanco('bisa')} className={`p-2 px-4 text-sm rounded-t-lg cursor-pointer ${banco === 'bisa'?'bg-accent text-white':'bg-transparent text-accent'}`}>Bisa</div>
                      <div onClick={()=>setBanco('union')} className={`p-2 px-4 text-sm rounded-t-lg cursor-pointer ${banco === 'union'?'bg-accent text-white':'bg-transparent text-accent'}`}>Union</div>
                      <div onClick={()=>setBanco('prodem')} className={`p-2 px-4 text-sm rounded-t-lg cursor-pointer ${banco === 'prodem'?'bg-accent text-white':'bg-transparent text-accent'}`}>Prodem</div>
                    </div>
                    <div className="flex flex-col my-4">
                      {
                        banco === 'bnb' &&
                        <div className="flex flex-col justify-center items-start">
                          <p className="text-sm text-gray-700"><span className="text-primary">Nombre del Titular : </span>AVENDAÑO VILLARROEL LEONEL</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Numero de cuenta : </span> 4500837492</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Carnet de Identidad : </span> 10377499</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Banco : </span>Banco Nacional de Bolivia  (BNB)</p>
                          <button className="bg-green-500 mt-4 text-white p-2 rounded text-sm self-end" onClick={()=>{setChild('wpp'); setVisible(true)} } >Enviar estos datos a mi Whatsapp</button>
                        </div>
                      }
                      {
                        banco === 'bisa' &&
                        <div className="flex flex-col justify-center items-start">
                          <p className="text-sm text-gray-700"><span className="text-primary">Nombre del Titular : </span>ABIGAIL MAMANI</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Numero de cuenta : </span> 495340-401-5</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Carnet de Identidad : </span> 13427301 pt</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Banco : </span>Banco bisa</p>
                          <button className="bg-green-500 mt-4 text-white p-2 rounded text-sm self-end" onClick={()=>{setChild('wpp'); setVisible(true)} } >Enviar estos datos a mi Whatsapp</button>
                        </div>
                      }
                      {
                        banco === 'union' &&
                        <div className="flex flex-col justify-center items-start">
                          <p className="text-sm text-gray-700"><span className="text-primary">Nombre del Titular : </span>AVENDAÑO VILLARROEL LEONEL</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Numero de cuenta : </span> 10000042944682 - Caja de ahorro M/N</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Carnet de Identidad : </span> 10377499</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Banco : </span>Banco Union </p>
                          <button className="bg-green-500 mt-4 text-white p-2 rounded text-sm self-end" onClick={()=>{setChild('wpp'); setVisible(true)} } >Enviar estos datos a mi Whatsapp</button>
                        </div>
                      }
                      {
                        banco === 'prodem' &&
                        <div className="flex flex-col justify-center items-start">
                          <p className="text-sm text-gray-700"><span className="text-primary">Nombre del Titular : </span>ROCELY VALERIA CRUZ</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Numero de cuenta : </span>  703-2-1-03486-9 </p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Carnet de Identidad : </span> 10330317</p>
                          <p className="text-sm text-gray-700"><span className="text-primary">Banco : </span>Banco Prodem</p>
                          <button className="bg-green-500 mt-4 text-white p-2 rounded text-sm self-end" onClick={()=>{setChild('wpp'); setVisible(true)} } >Enviar estos datos a mi Whatsapp</button>
                        </div>
                      }
                    </div>
                  </div>
                }
                {
                  qr &&
                  <div className="flex flex-col justify-center items-center" style={{padding:0}}>
                    <img src={imgQr} alt="qr" className="w-3/4"/>
                    <p className="text-sm text-gray-700 text-center">Escane la imagen de arriba y suba el comprobante de tranferecia con el monto del plan seleccionado.</p>
                  </div>
                }
              </div>
              {
                
                load === 0 ?
                  <button className="bg-primary p-2 px-8 rounded text-white mt-4 text-base" onClick={()=>{subirPlan()}}>
                    Continuar
                  </button>
                : load === 1 ?
                <button className="bg-gray-400 p-2 px-8 rounded text-white mt-4 text-base p-4">
                  <Dots color='white'/>
                </button>:
                <>
                  <button className="bg-gray-400 p-2 px-8 rounded text-white mt-4 text-base p-4">
                    Plan Registrado
                  </button>
                  <div>Para cambiar de plan contáctese <a className="text-green-500" href={`https://api.whatsapp.com/send?phone=59161885865&text=Buenas%20Apriori.%20Deseo%20cambiar%20mi%20plan%20actual.`} >aqui.</a> </div>
                  
                </>
                
              }
              
            </div>  
          </div>
          </div>
          <div className="w-full p-8 px-32 sm:px-4 md:px-32 flex flex-col justify-start items-center text-xl self-start">
              <h1 className="text-primary  text-3xl font-bold text-center">Planes de suscripcion:</h1>
              <div className="w-full p-6 py-8 flex flex-col"
        >
          <h4 className="text-center text-fondo font-bold text-xl sm:text-xl md:text-xl my-2">Ahora tenemos un plan, pruebalo ahora:</h4>
          <div className="bg-white w-1/3 md:w-1/3 sm:w-5/6 p-4 my-4 rounded-b-lg rounded-t-md self-center border-4 border-red-500">
            <div className="flex flex-col items-center bg-fondo py-4 rounded">
              <p className="text-4xl md:text-4xl sm:text-2xl text-center text-white font-bold"> Plan Fast
              </p> 
            </div>
            
            <h1 className="text-5xl md:text-5xl sm:text-4xl font-bold my-5 text-gray-700 text-center">Bs.<span className="text-primary"> 60</span></h1>

            
          </div>
          <details className="w-1/4 md:w-1/4 sm:w-3/4 self-center">
          <summary className="text-white text-center py-4 -mt-10 rounded-md font-bold text-xl md:text-xl sm:text-lg bg-red-500 self-center">Ver Informacion</summary> 
          
            <div className="m-1 bg-fondo rounded absolute border-4 border-primary -ml-20 p-8 max-w-45 z-100 md:-ml-20 sm:-ml-8 md:max-w-45 sm:max-w-screen-lg mr-4 md:mr-0 sm:mr-4">
            <p className="text-center mt-4 md:mt-4 sm:mt-0 text-sm text-white">
                Este plan solo esta disponible el dia de la adquisición, en las cuales podras realizar las pruebas que desees, al finalizar el tiempo se cerrara la sesión y no podras realizar mas pruebas.
              
              </p>
              
            <div className="flex flex-col justify-center items-start mx-4 mt-4">
              
              
            <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-sm flex items-center mr-2"><FiClock className="mr-2"/>Suscripción: </p>
            <p className="font-regular text-white text-center text-lg sm:text-sm md:text-lg ml-3">Hasta las 23:59 del dia de suscripción </p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-sm md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas Prácticas: 3</p>
            <p className="font-regular text-left text-lg sm:text-sm md:text-lg  text-white ml-3">Más de 25 en cada examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-sm flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas finales: 1</p>
            <p className="font-regular text-left text-lg sm:text-sm md:text-lg ml-3 text-white">Más de 40 en el examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-sm md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary"/>Total de Preguntas: </p>
            <p className="font-regular text-left text-lg sm:text-sm md:text-lg ml-3  text-white">Más de 190 preguntas</p>
          </div>
          <p className="font-bold text-primary text-center text-xl md:text-xl sm:text-lg flex items-center mr-2 mt-4">Beneficios:</p>
          <ol>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500"/> Examen cronometrado. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500"/> Calificacion inmediata. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500"/>Revisión.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500"/>Gráficos comparativos.</p>
            </li>
            {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
            }
          </ol>
          
          {
                      plan === 'fast'?
                      <a href="#infoPlans"  onClick={()=>{setPlan('fast');setMonto(60)}} className='bg-green-500 p-2 rounded text-sm m-4 text-white boton mx-8 flex flex-row justify-center items-center'>
                        <p>Seleccionado</p>
                      <IoMdCheckmark className="text-white ml-2" size={24} />
                      </a>:
                      <a href="#infoPlans"   onClick={()=>{setPlan('fast');setMonto(60)}}  className='bg-primary p-2 rounded text-sm m-4 text-white boton mx-8'>Seleccionar Plan</a>
                    }
            </div>
        </details>

          
        </div>
              <div className="flex flex-row sm:flex-col md:flex-row justify-center items-start w-full mt-20 sm:mt-8 md:mt-20 ">

              



                <div className={`rounded w-full flex flex-col justify-center  mt-8  ${plan === 'basico'?'bg-green-400' :'bg-gradient-to-t from-gray-300 to-gray-600'}`} style={{padding:3}}>
                  <div className="bg-basic w-full rounded flex flex-col justify-center">
                    <p className={`text-center text-4xl px-2 ${plan === 'basico'?'bg-green-400' :' bg-red-500'} w-full p-4 py-6 sm:py-2 md:py-6 -mt-16 sm:mt-0 md:-mt-16 rounded-b-lg rounded-t-md font-bold text-white drop-shadow-md`}>Plan <p>Básico</p>
                    <p className="text-3xl text-gray-800 ">Bs. <span>120</span></p>
                    </p>
                    <div className="flex flex-col justify-center items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-base flex items-center mr-2"><FiClock className="mr-2"/>Suscripción: </p>
            <p className="font-regular text-center text-lg sm:text-base md:text-lg ml-3">Hasta el día del examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas Prácticas: 3</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg  ml-3">Más de 50 preguntas en cada examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-base flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas finales: 1</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 40 preguntas en cada prueba</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary"/>Total de Preguntas: </p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 190 preguntas</p>
          </div>
          <p className="font-bold text-primary text-center text-xl flex items-center mr-2 mt-4 mx-4">Beneficios:</p>
          <ol  className="mx-4">
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Examen cronometrado. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Calificacion inmediata. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Revisión.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Gráficos comparativos.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2">
              <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!props.cinco?'text-gray-400':'text-black'}`}>{props.cinco?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Puede modificar la fecha de los exámenes finales.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2">
              <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!props.cinco?'text-gray-400':'text-black'}`}>{props.cinco?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Puede modificar las materias de los exámenes.</p>
            </li>
            {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
            }
          </ol>
                    {
                      plan === 'basico'?
                      <a href="#infoPlans"  onClick={()=>{setPlan('basico');setMonto(90)}} className='bg-green-500 p-2 rounded text-sm m-4 text-white boton mx-8 flex flex-row justify-center items-center'>
                        <p>Seleccionado</p>
                      <IoMdCheckmark className="text-white ml-2" size={24} />
                      </a>:
                      <a href="#infoPlans"   onClick={()=>{setPlan('basico');setMonto(90)}}  className='bg-primary p-2 rounded text-sm m-4 text-white boton mx-8'>Seleccionar Plan</a>
                    }
                  </div>
                  
                </div>
                <div className="bg-basic rounded mx-4 sm:mx-0 md:mx-4 w-full mt-0 sm:mt-8 md:mt-0 flex flex-col justify-center">
                <div className={`rounded w-full flex flex-col justify-center ${plan === 'profesional'?'bg-green-400' :'bg-gradient-to-t from-accent to-primary'}`} style={{padding:3}}>
                  <div className="bg-basic w-full rounded flex flex-col justify-center">
                  <p className={`text-center text-4xl px-2 ${plan === 'profesional'?'bg-green-400' :' bg-red-500'} w-full p-4 py-6 sm:py-2 md:py-6 -mt-16 sm:mt-0 md:-mt-16 rounded-b-lg rounded-t-md font-bold text-white drop-shadow-md`}>Plan <p>Profesional</p>
                    <p className="text-3xl text-gray-800 ">Bs. <span>210</span></p>
                    </p>
                    <div className="flex flex-col justify-center items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-base flex items-center mr-2"><FiClock className="mr-2"/>Suscripción: </p>
            <p className="font-regular text-center text-lg sm:text-base md:text-lg ml-3">Hasta el día del examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas Prácticas: 5</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg  ml-3">Más de 50 preguntas en cada examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-base flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas finales: 2</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 40 preguntas en cada prueba</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary"/>Total de Preguntas: </p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 330 preguntas</p>
          </div>
          <p className="font-bold text-primary text-center text-xl flex items-center mr-2 mt-4 mx-4">Beneficios:</p>
          <ol  className="mx-4">
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Examen cronometrado. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Calificacion inmediata. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Revisión.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Gráficos comparativos.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2">
              <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Puede modificar la fecha de los exámenes finales.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2">
              <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!props.cinco?'text-gray-400':'text-black'}`}>{props.cinco?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Puede modificar las materias de los exámenes.</p>
            </li>
            {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
            }
          </ol>
                    {
                      plan === 'profesional'?
                      <a href="#infoPlans" onClick={()=>{setPlan('profesional');setMonto(175)}} className='bg-green-500 p-2 rounded text-sm m-4 text-white boton mx-8 flex flex-row justify-center items-center'>
                        <p>Seleccionado</p>
                      <IoMdCheckmark className="text-white ml-2" size={24} />
                      </a>
                      :
                      <a href="#infoPlans" onClick={()=>{setPlan('profesional');setMonto(175)}}  className='bg-primary p-2 rounded text-sm m-4 text-white boton mx-8'>Seleccionar Plan</a>
                    }
                  </div>
                  
                </div>
                </div>
                <div className="bg-basic rounded w-full flex flex-col justify-center mt-8">
                
                <div className={`rounded w-full flex flex-col justify-center  ${plan === 'premium'?'bg-dorado' :'bg-gradient-to-t from-gray-300 to-gray-600'}`} style={{padding:3}}>
                
                  <div className="bg-basic w-full rounded flex flex-col justify-center ">
                  
                  <p className={`text-center text-4xl px-2 text-dorado flex flex-col items-center ${plan === 'premium'?'bg-green-400' :' bg-red-500'} w-full p-4 py-6 sm:py-2 md:py-6 -mt-16 sm:mt-0 md:-mt-16 rounded-b-lg rounded-t-md font-bold text-white drop-shadow-md`}>
                  <p className="font-bold bg-white text-primary text-center text-xl m-2 p-1  -mt-12 sm:-mt-8  md:-mt-12 z-10 w-4/6 rounded border-2 border-red-500">Recomendado</p>
                    Plan <p>Premium</p>
                   
                    <p className="text-3xl text-gray-800 ">Bs. <span>290</span></p>
                  </p>
                    <div className="flex flex-col justify-center items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-base flex items-center mr-2"><FiClock className="mr-2"/>Suscripción: </p>
            <p className="font-regular text-center text-lg sm:text-base md:text-lg ml-3">Hasta el día del examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas Prácticas: 8</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg  ml-3">Más de 50 preguntas en cada examen</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-base flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Pruebas finales: 2</p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 40 preguntas en cada prueba</p>
          </div>
          <div className="flex flex-col justify-start items-start mx-4 mt-4">
            <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary"/>Total de Preguntas: </p>
            <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 480 preguntas</p>
          </div>
          <p className="font-bold text-primary text-center text-xl flex items-center mr-2 mt-4 mx-4">Beneficios:</p>
          <ol  className="mx-4">
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Examen cronometrado. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Calificacion inmediata. </p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Revisión.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Gráficos comparativos.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Puede modificar la fecha de los exámenes finales.</p>
            </li>
            <li className="font-regular text-left text-lg flex items-center mr-2 bg-red-100 text-red-900 rounded">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>}
                Puede modificar las materias de los exámenes.
              </p>
            </li>

            {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
            }
          </ol>
                    <div  id="infoPlans"/>
                    {
                      plan === 'premium'?
                      <a href="#infoPlans"  onClick={()=>{setPlan('premium');setMonto(200)}} className='bg-green-500 p-2 rounded text-sm m-4 text-white boton mx-8 flex flex-row justify-center items-center'>
                        <p>Seleccionado</p>
                      <IoMdCheckmark className="text-white ml-2" size={24} />
                      </a>:
                      <a href="#infoPlans"  onClick={()=>{setPlan('premium');;setMonto(200)}}  className='bg-primary p-2 rounded text-sm m-4 text-white boton mx-8'>Seleccionar Plan</a>
                    }
                  </div>
                  
                </div>
                </div>
              </div>

          </div>
        </div>
        {/* <div className="w-ful flex flex-col items-center">
          <p className="text-2xl text-gray-700">si ya cuenta con un comprobante de pago...</p>
          <button className="bg-primary p-3 px-8 rounded text-white mt-4 text-xl" onClick={()=>{setVisible(true);setChild('deposito');setTitulo('Registro de comprobante')}}>Adquirir Plan</button>
        </div> */}
        
        
        <Footer/>
      </>
    );
}

export default Plans;