// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup} from "firebase/auth";
import { getStorage } from "firebase/storage";

//import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

//import { getAnalytics } from "firebase/analytics";

//import {GoogleAuthProvider} from 'firebase/auth';

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  // authDomain:process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  // messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  // appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  // REACT_APP_FIREBASE_APIKEY=AIzaSyCTGg_5w5Z91KXBtGowvbFJ6DbAoDBlgj4
  // REACT_APP_FIREBASE_AUTHDOMAIN=a-priori-5c593.firebaseapp.com
  // REACT_APP_FIREBASE_DATABASEURL=https://a-priori-5c593.firebaseio.com
  // REACT_APP_FIREBASE_PROJECTID=a-priori-5c593
  // REACT_APP_FIREBASE_STORAGEBUCKET=a-priori-5c593.appspot.com
  // REACT_APP_FIREBASE_MESSAGINGSENDERID=279356194733
  // REACT_APP_FIREBASE_APPID=1:279356194733:web:c5b4dc9f0c0fead9deeb24
  // REACT_APP_FIREBASE_MEASUREMENTID=G-7VX8GZGVWC.  
  apiKey: "AIzaSyCTGg_5w5Z91KXBtGowvbFJ6DbAoDBlgj4",
  authDomain:'a-priori-5c593.firebaseapp.com',
  projectId: 'a-priori-5c593',
  storageBucket: 'a-priori-5c593.appspot.com',
  messagingSenderId:'279356194733',
  appId: '1:279356194733:web:c5b4dc9f0c0fead9deeb24',
  measurementId:'G-7VX8GZGVWC',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleAuthprovider = new GoogleAuthProvider();
const storage = getStorage(app);
//const analytics = getAnalytics(app);

export {  
  db,
  googleAuthprovider,
  auth,
  signInWithPopup,
  storage,
};
