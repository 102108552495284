import React,{useEffect, useState} from "react";
import Footer from "../components/footer";
import { useSelector} from 'react-redux';
import Modal from "../components/Modal";
//importar firebase storage
import { storage } from "../../config/firebase";
import {getDownloadURL, ref as referencia, uploadBytesResumable} from "@firebase/storage";
import { auth } from "../../config/firebase";
//import imgQr from './../../assets/images/qr.jpg'
import { db } from "../../config/firebase";
import dbRef from "../../config/dbRef";
//import { collection} from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";// addDoc
import { Link } from "react-router-dom";
import {BsWhatsapp, BsFillImageFill} from "react-icons/bs";
import {Dots} from "../components/onload"
import {IoMdRadioButtonOff, IoMdRadioButtonOn, IoMdCheckmark} from "react-icons/io";

const RadioButton = (props) => {
  const [active , setActive] = useState(props.active);
  useEffect(() => {
    setActive(props.active);
  }, [props.active]);
  return (
    <div className="flex flex-row justify-center items-center cursor-pointer" onClick={
      ()=>{
        setActive(!active);
        props.onClick();
      }
    }>

      {
        active ? <IoMdRadioButtonOn color={props.color}/> : <IoMdRadioButtonOff color={props.color}/>
      }
      
      <p className="text-sm">{props.label}</p>
    </div>
    
  );
}


const Payment = (props) => {
  
  const [data,setData]= useState([]);
  const [id, setId] = useState('');
  const [visible, setVisible] = useState(false);
  const [tigo, setTigo] = useState(true);
  const [deposito, setDeposito] = useState(false);
  const [transferencia, setTransferencia] = useState(false);
  const [qr, setQr] = useState(false);
  const [child, setChild] = useState('');

  const [plan, setPlan] = useState('0');
  const [banco, setBanco] = useState('bnb');
  const [titulo, setTitulo] = useState('Titulo');
  const [telefono, setTelefono] = useState('Telefono');
  const [monto, setMonto] = useState(0);
  const [imagen, setImagen] = useState('');
  const [progreso , setProgreso] = useState(0);
  const [url, setUrl] = useState('');
  const [load, setLoad] = useState(1);//0 false -1 true -2 loading
  const [text, setText] = useState('ninguna imagen');

  const [textAdvertencia, setTextAdvertencia] = useState('Inntroducir un numero de telefono valido');

  const [metodo, setMetodo] = useState('vacio');

  // const storage 
  
  const user = useSelector(state => state.user);
  const changeRadioButton = (index) => {
    switch (index) {
      case 1:
        setTigo(true);
        setDeposito(false);
        setTransferencia(false);
        setQr(false);
        break;
      case 2:
        setTigo(false);
        setDeposito(true);
        setTransferencia(false);
        setQr(false);
        break;
      case 3:
        setTigo(false);
        setDeposito(false);
        setTransferencia(true);
        setQr(false);
        break;
      case 4:
        setTigo(false);
        setDeposito(false);
        setTransferencia(false);
        setQr(true);
        break;
      default:
        break;
    }
  }

  const subirImagen = (img) => {
      if(metodo !== 'vacio'){
      if(imagen !== ''){
      setLoad(2);
      //fecha actual
      const date = new Date();
      const storeRef = referencia(storage,`comprobante/+${date}+${img.name}`);
      const UploadTask = uploadBytesResumable(storeRef, img);
      UploadTask.on('state_changed', (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgreso(progress);
        },
        (error) => {
            console.log(error);
        },
        () => {
          getDownloadURL(UploadTask.snapshot.ref).then((downloadURL) => {
              setText("Imagen subida correctamente");
              return downloadURL;
          }).then((url)=>{
            updateDocumento(data.id,url);
            // console.log('llego')
          }).then(()=>{
            setVisible(true);
            setTitulo('Comprobante subido correctamente');
            setLoad(3);
              setText("El comprobante se ha subido correctamente");
          });
        }
      );}else{
        setText("Seleccione una imagen");
      }
    }else{
      setText("Seleccione un metodo de pago");
    }
  }

  const sendWhatsapp = () => {
    if (telefono.length > 7 && telefono.length < 9) {
      let mensaje= `${
        banco === 'bnb'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :* 4500837492 *Carnet de Identidad :* 10377499 *Banco :* Banco Nacional de Bolivia (BNB) *Monto :* ${monto}`:
        banco === 'bisa'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Abigail Mamani *Numero de cuenta :*  495340-401-5 *Carnet de Identidad :* 13427301 pt *Banco :* Banco Bisa *Monto :* ${monto}`:
        banco === 'union'? `*CUENTA DE DEPOSITO: Nombre del Titular :* Leonel Avendaño Villarroel *Numero de cuenta :*  10000042944682 - Caja de ahorro M/N *Carnet de Identidad : *10377499 *Banco :* Banco Unión *Monto :* ${monto}`:
        `*CUENTA DE DEPOSITO: Nombre del Titular :* Rocely Valeria Cruz *Numero de cuenta :*  703-2-1-03486-9 *Carnet de Identidad :* 10330317 *Banco :* Banco Prodem *Monto :* ${monto}`


      }`;
      const url = `https://api.whatsapp.com/send?phone=591${telefono}&text=${mensaje}`;
      window.open(url, '_blank');
    }else{
      alert("Introducir un numero de telefono valido");
    }
  }

  const updateDocumento = async (paramId,paramUrl) => {
    //const messageRef = doc(db, "rooms", "roomA", "messages", "message1");
    const authUser = auth.currentUser.uid;
    const cuenta = doc(db, dbRef.user, authUser,'cuenta',paramId);
    const newExam = {
      urlRecibo: paramUrl,
      metodo: metodo,
      banco: banco,
      rebizado: false,
      plan: plan,
    }
    await updateDoc(cuenta, newExam);
    // await updateDoc(cuenta, paramId, newExam);
    setText("Comprobante enviado");

  };

  useEffect(() => {
    
    if(!props.location.data){
      props.history.push('/profile');
    }else{
      setData(props.location.data);
      setId(props.location.data.id);
      console.log(data);
    }
  } ,[props.history,load])

    return (
      <>
        <Modal visible={visible} title={titulo} onClose={()=>{setVisible(false);setProgreso(0)}}>
          {
            <>
              <h2 className="text-center text-primary text-3xl">Apriori</h2>
              <p className="text-center text-gray-900 my-4">
              El comprobante se ha subido correctamente, nuestro equipo se encargará de validarlo y será notificado.  
              </p>
              <div className="flex flex-row justify-center">
                <p className="text-primary text-center cursor-pointer border-2 border-primary rounded text-center p-2 mx-4" onClick={()=>setVisible(false)}>Cerrar</p>
                <button className="bg-green-500 text-white p-2 rounded text-sm self-end flex flex-row items-center px-2 border-2 border-green-500" onClick={()=>{
                  const url = `https://api.whatsapp.com/send?phone=59161885865&text=Quiero reportar el pago de mi plan ${data.plan}, para el examen de ${data.nombre} .`;
                  window.open(url, '_blank');
                  }}>
                  <BsWhatsapp className="mr-2"/>
                  Contactar con Apriori
                </button>
              </div>
            </>
          }
        </Modal>
        <div className="pt-24 flex flex-col justify-center items-center bg-secundary w-full text-white pb-6 text-4xl" style={{backgroundColor:'#303030'}}>
          <div><h1>Reportes de Pago</h1></div>
          <div><p className="text-white text-xl">{data.nombre}</p></div>
        </div>
        <div className="flex flex-row justify-center items-start p-0 my-16">
          {/**drag and drop of image*/} 
          <div className="flex flex-col  justify-center items-center">
          <p>Seleccionar comprobante de pago.</p>
          <div className="flex flex-row justify-center items-center border-4 border-gray-500 rounded-md mt-4 max-w-lg">
            
          <input 
            id="fileImage"
            type="file"
            accept="image/*"
            onChange={(e)=>{setImagen(e.target.files[0]);setText("Imagen seleccionada")}}
            style={{display:'none',width:0,height:0}}
          />
          {
            
            <label className="cursor-pointer" htmlFor="fileImage">
              {
                imagen ?
                  <img src={URL.createObjectURL(imagen)} alt="imagen"/>
                :
                  <BsFillImageFill style={{fontSize:250 }} className="m-4"/>
              }
            </label>
          }
          
          </div>
          <div className="w-full bg-gray-200 h-2 mb-6 mt-4">
            <div className="bg-primary h-2"  style={{width:`${progreso}%`}}></div>
          </div>
          <p className="text-sm text-center">{text}</p>
          {
            imagen ?
              <label className="cursor-pointer bg-primary text-white p-2 px-3 rounded mt-4" htmlFor="fileImage">
                Cambiar Imagen
              </label>
            :
            null
          }
           
          </div>
          <div className="rounded-md z-10 pl-16">
          <div className="w-full p-8 flex flex-col justify-start items-start rounded-b-md">
          
            <h3 className="text-primary text-xl font-bold">Metodo de pago :</h3>
            <p className="text-sm text-left mb-3">Seleccione el método por el que realizó el pago.</p>
            <div className="flex flex-col justify-center items-center">
              {/**panel de seleccion de 4 opciones */}
              <div className="w-full flex flex-col justify-start items-start mt-2 px-6">
                <RadioButton color={'green'} active={metodo==='tigoMoney'?true:false} label = 'Tigo Money' onClick={()=> setMetodo('tigoMoney')} />
                <RadioButton color={'green'} active={metodo==='deposito'?true:false} label = 'Deposito Bancario'  onClick={()=> setMetodo('deposito')}/>
                <RadioButton color={'green'} active={metodo==='transferencia'?true:false} label = 'Transferencia' onClick={()=> setMetodo('transferencia')}/>
                <RadioButton color={'green'} active={metodo==='qr'?true:false} label = 'Codigo QR' onClick={()=> setMetodo('qr')}/>

              </div>
              {
                load === 1 ?
                <button
                  className="bg-primary p-2 px-8 rounded text-white mt-4 text-base"
                  onClick={()=>{subirImagen(imagen)}}
                >
                  Continuar
                </button>:
                load === 2 ?
                <button className="bg-gray-400 p-2 px-8 rounded text-white mt-4 text-base p-4">
                  <Dots color='white'/>
                </button>
                :
                load === 3 ?
                <button className="bg-gray-400 p-2 px-8 rounded text-white mt-4 text-base p-4">
                  Comprobante Registrado
                </button>
                :
                null
              }
              <Link className="bg-primary p-2 px-8 rounded text-white mt-4 text-base" to={"/profile" }>Volver a Perfil</Link >
            </div>  
          </div>
          </div>
        </div>
        <Footer/>
      </>
    );
}

export default Payment;