import React,{useState,useEffect, useRef} from 'react';
import { FaAngleRight ,FaAngleLeft} from 'react-icons/fa';
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import ReactGA from 'react-ga';
import Card from './cardSimulacrum';
import { FaBalanceScale} from 'react-icons/fa';
import {db} from '../../config/firebase';
import dbRef from '../../config/dbRef';
import { collection, getDocs } from "firebase/firestore";
import image from '../../assets/images/logoBlanco.png';
import {Contactanos} from './../components/uiComponents';

export default function Simulacrum(props){

    const[nac,setNac] = useState(true) 
    const[dep,setDep] = useState(false)
    const [items, setItems] = useState([])
    const [selected, setSelected]  = useState([])
    const [position, setPosition] = useState(0)
    const [data,setData] = useState({});
    var elem = document.getElementById('element');
    const [intervalo , setIntervalo] = useState(false);

    const clientes = useRef(null);
    const simulacros = useRef(null);
    const ciudades = useRef(null);
    const colaboradores = useRef(null);

    var divElemento =  useRef(null);


    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick = (id) => ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id)
    
        setSelected((currentSelected) =>
          itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id)
        );
      }
    //hacer una funcion que suba los valores de las referencias cada 100 milisegundos
    /**
     * {data.clientesActuales}
     * {data.simulacrosTotales}
     * {data.ciudades}
     * {data.colaboradores}

     */
    // useEffect(()=>{
    //   const valor = setInterval(()=>{
    //     console.log('intervalo')
    //   },1000);
    //   return () => {
    //     clearInterval(valor);
    //   };
        
    // },[])


    const subirValores = () => {
      // console.log('aquifirst')
      // const intervalos = setInterval(() => {
      //   console.log('aqui')
      // }, 1000);
    //   // clientes.current.innerHTML <= data.clientesActuales || simulacros.current.innerHTML <= data.simulacrosTotales || ciudades.current.innerHTML <= data.ciudades || colaboradores.current.innerHTML <= data.colaboradores
    // if (true) {
    //   const intervaldata = setInterval(() => {
        
    //     console.log('first')
    //     // if( parseInt(clientes.current.innerHTML) <= data.clientesActuales ){
    //     //   clientes.current.innerHTML = parseInt(clientes.current.innerHTML)+1;
    //     // }else{
    //     //   clientes.current.innerHTML = parseInt(data.clientesActuales)+1;
    //     // }
    //     // if( parseInt(simulacros.current.innerHTML) <= data.simulacrosTotales ){
    //     //   simulacros.current.innerHTML = parseInt(simulacros.current.innerHTML)+1;
    //     // }else{
    //     //   simulacros.current.innerHTML = parseInt(data.simulacrosTotales)+1;
    //     // }
    //     // if( parseInt(ciudades.current.innerHTML) <= data.ciudades -1 ){
    //     //   ciudades.current.innerHTML = parseInt(ciudades.current.innerHTML)+1;
    //     // }else{
    //     //   ciudades.current.innerHTML = parseInt(data.ciudades);
    //     // } 
    //     // if( parseInt(colaboradores.current.innerHTML) <= data.colaboradores ){
    //     //   colaboradores.current.innerHTML = parseInt(colaboradores.current.innerHTML)+1;
    //     // }else{
    //     //   colaboradores.current.innerHTML = parseInt(data.colaboradores)+1;
    //     // }

        
    //   }, 50);
    // }

  }
// activo  boool




  // window.addEventListener('scroll',  () => {
  //      if(divElemento !==null)
  //      {
  //        //divElemento = document.getElementById('contentenedor')
  //        console.log(divElemento);
  //        if (divElemento.current.getBoundingClientRect().top < window.innerHeight-150  ) {
  //           setIntervalo(true);
  //         }
  //      } q 
  //     // 
  // });


  const btnAnalitycs = (btn) => {
    ReactGA.event({
      category: 'button',
      action: 'Click button:'+ btn +' en home -> pruebas disponibles',
    });
  }

  const scrollHandler = _ => {
    if(clientes.current!==null)
      if (divElemento.current.getBoundingClientRect().top < window.innerHeight-150  ) {
        clientes.current.style.opacity = 1;
        simulacros.current.style.opacity = 1;
        ciudades.current.style.opacity = 1;
        colaboradores.current.style.opacity = 1;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);
  


    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)
      
        return (
          <span disabled={isFirstItemVisible} onClick={() => scrollPrev()} className="bg-transparent flex items-center rounded-l-2xl">
            <FaAngleLeft size={50} className="top-1/2  text-primary"/>
          </span>
        );
      }
      
    function RightArrow() {
      const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)
    
      return (
        <span disabled={isLastItemVisible} onClick={() => scrollNext()} className="bg-transparent  flex items-center rounded-r-2xl">
          <FaAngleRight size={50}  className="top-1/2 text-primary"/>
        </span>
      );
    }


    useEffect(()=>{
        getDocs(collection(db,dbRef.exam))
        .then((response)=>{
            let examenes =[]
            response.forEach((doc)=>{
              examenes.push(
                {
                  id:doc.id,
                  data:doc.data(),
                }
              );
            });
            return examenes;
        }).then( doc =>{setItems(doc);console.log(items)})
    },[])

    useEffect(()=>{
      setData({
        ciudades:props.datos.ciudades,
        clientesActuales:props.datos.clientesActuales,
        simulacrosTotales:props.datos.simulacrosTotales,
        colaboradores:props.datos.colaboradores,
      });
    },[props.datos]);
    
    return(
    <>
        <div  className="bg-no-repeat bg-cover bg-white flex flex-row pt-0 pb-2 sm:flex-col md:flex-row sm:pt-10 md:pt-12" >
          <div className="h-96 w-2/5 pl-5 block sm:hidden md:block" style={{backgroundImage:`url(${image})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}></div>
          <div className="h-96 w-3/5 bg-white px-20 flex flex-col justify-center sm:h-full md:h-96 sm:w-full md:w-3/5 sm:px-5 md:px-10 text-left sm:text-center md:text-left">
                 <div>
                   <span className='text-primary font-bold text-base sm:text-2xl md:text-base'>¿Quiénes somos? </span>
                   <div className='font-bold text-3xl sm:text-2xl md:text-3xl'>Somos un equipo multidisciplinario de profesionales que están comprometidos con tus resultados</div>
                 </div>
                 <div className="flex flex-row pt-3 sm:flex-col md:flex-row items-start sm:items-center md:text-start">
                   <div>< FaBalanceScale  className="text-primary text-5xl"/></div>
                   <div  className="px-7">
                    <div><span className='mb-5'>Apriori pura preparación, puro poder</span></div>
                    {/* <div className='mt-5'>Slogan de la empresa Slogan de la empresa Slogan de la empresa Slogan de la empresa Slogan de la empresa Slogan de la empresa .</div> */}
                    <Contactanos type={'fill'} texto={'Contactanos'} />
                   </div>
                 </div>
          </div>
        </div>
        <div ref={divElemento} className='bg-gray-200 flex flex-row flex-wrap justify-around py-8'>
          <div className='flex flex-col items-center '>
            <div ref={clientes}  className='transition ease-in-out delay-150 font-bold text-5xl scale-0  duration-700 opacity-0'>{props.clients}</div>
            <span>Clientes Actuales</span>
          </div>
          <div className='flex flex-col items-center'>
            <div ref={simulacros} className='transition ease-in-out delay-150 font-bold text-5xl scale-0  duration-700 opacity-0'>{items.length}</div>
            <span>Exámenes</span>
          </div>
          <div className='flex flex-col items-center  sm:hidden md:flex'>
            <div ref={ciudades} className='transition ease-in-out delay-150 font-bold text-5xl scale-0  duration-700 opacity-0'>7</div>
            <span>Ciudades</span>
          </div>
          <div className='flex flex-col items-center sm:hidden md:flex'>
            <div ref={colaboradores} className='transition ease-in-out delay-150 font-bold text-5xl scale-0  duration-700 opacity-0'>{props.totalPreguntas}+</div>
            <span>Preguntas</span>
          </div>
        </div>
        <div className="bg-white pb-10 pt-10 px-20 sm:pt-10 sm:px-5 lg:pt-10 lg:px-20 -z-10">
            <h2 className="text-3xl sm:text-2xl lg:text-3xl text-primary text-start sm:text-center md:text-start">PRUEBAS DISPONIBLES</h2>
            <p className="mt-3 text-xl  sm:text-base md:text-xl font-regular text-start sm:text-center md:text-start">Nuestro trabajo está desarrollado por profesionales en el área, a la par de la última tecnología para ofrecerle a usted la mejor preparación que todo nuestro equipo pueda brindarle.</p>
            <div className="flex flex-col items-center mt-5 flex-wrap">
                <div>
                    {/* <button 
                    onClick={()=>{
                      setNac(true); 
                      setDep(false);
                      btnAnalitycs('Activos');
                    }} 
                    className={`border-primary border-2 px-5 py-2 rounded-t-3xl mr-3 -bottom-px ${nac?' text-white bg-primary':'text-primary'}`
                 }>Activos</button> */}
                    {/* <button
                      onClick={()=>{
                        setNac(false);
                        setDep(true);
                        btnAnalitycs('Previas');
                        }}
                      className={`border-primary border-2 px-5 py-2 rounded-t-3xl -bottom-px ${dep?' text-white bg-primary':'text-primary'}`}>
                        Previas
                    </button> */}
                </div> 
                <div className="relative w-full h-auto px-8 pt-0 sm:px-2 md:px-8" >
                  {//<div  className={nac?"absolute border-primary border-2 w-full h-full rounded-2xl -top-px":"absolute border-primary border-2 rounded-2xl w-full h-full hidden -top-px"}>
                  }
                    {/* <div  className={`overflow-auto border-primary max-w-full border-2 w-full h-full rounded-2xl p-2 -top-px ${nac?'flex':'hidden'}`}> */}
                    <div  className={`border-primary max-w-full w-full h-full rounded-2xl p-2 -top-px flex`}>
                    {/* // <ScrollMenu
                    //     LeftArrow={LeftArrow}
                    //     RightArrow={RightArrow}
                    //     >
                    //     {items.map(({ id }) => (
                    //         <Card
                    //         itemId={id} // NOTE: itemId is required for track items
                    //         title={id}
                    //         key={id}
                    //         onClick={handleClick(id)}
                    //         selected={isItemSelected(id)}
                    //         />)
                    //     )}

                    //     </ScrollMenu> */}
                    <div className="flex justify-start flex-wrap sm:flex-wrap md:flex-nowrap">
                    {
                      items.map((examen,index) => (
                        examen.data.visible?
                          <Card key={index} data = {examen} id={examen.id}/>
                          :null
                          )
                      )
                    }
                    </div>
                        
                    </div>
                    {/* <div  className={`overflow-auto border-primary max-w-full justify-self-start border-2 w-full h-full rounded-2xl p-2 -top-px ${dep?'flex':'hidden'}`}>
                      <div className="flex justify-start">
                      {
                        items.map((examen,index) => (
                          examen.data.visible?
                            null
                            :<Card key={index} data = {examen} id={examen.id}/>
                            )
                        )
                      }
                      </div>
                    </div> */}
                </div>

              
              {/* <button  className={
                    `border-primary border-2 px-5 py-2 rounded-3xl my-3 -bottom-px text-white bg-primary`
                }>Ver todos</button> */}
            </div>
            
            
            
        </div>
    </>
    );
};