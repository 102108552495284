import React ,{useState,useEffect,useRef} from 'react';
import { RiSendPlane2Fill} from "react-icons/ri";
import Pregunta from './pregunta';
import {useDispatch ,useSelector} from 'react-redux';
import { addTest } from '../../../redux/actions/test';

const Marker=({texto, select})=>{
  
  const [state,setState] = useState(false);
  const [text,setText] = useState('');
  useEffect(()=>{
    setState(select);
    setText(texto);
  },[select,texto])
  return(
    <div className= {`bg-fondo m-1 mx-2 px-4 p-2 border-2 text-red-600  -md border-withe  hover:border-primary hover:text-primary ${state?
      'bg-primary border-primary text-fondo':
      ''
    } `}>
      {text}
    </div>
  );
}

export default function Materia  (props) {
  const {revision} = props;
  const dispatch = useDispatch();
  //const data = props.data.preguntas;
  const [revision_,setRevision_] = useState(revision);
  const [data,setData] = useState(props.data.preguntas);
  const [notas ,setNotas] = useState(props.notas);
  const [dataNotas,setDataNotas] = useState({nombre:"",notas:[]});
  const [memoria ,setMemoria] = useState(props.espacio);

  const test = useSelector(state => state.test.test);
  useEffect(()=>{
    setRevision_(revision);
  },[revision])

  useEffect(()=>{
    setData(props.data.preguntas);

  },[props.data])

  useEffect(()=>{
    dataNotas.nombre=props.data.nombre;
    console.log(props.data.nombre);
    setMemoria(dataNotas);
  },[])
  return(
    <>
      <div 
        className='w-3/4 sm:w-full md:w-3/4 flex flex-col justify-start items-center overflow-scroll h-screen pt-20 bg-gray-200 px-6 sm:px-2 md:px-6 pb-24 mt-0 sm:mt-32 md:mt-0'
        style={{overflowX:'hidden',display:`${props.display}`,}}
      >   
        <div className='flex flex-row justify-center items-center absolute items-self-end justify-self-end mt-full bg-gray-400 p-1 rounded drop-shadow-lg content-q1uestion'>
          {
            data.map((item,index)=>{
              return(
                  <Marker texto={index+1} select={item.marcado?true:false}/>
              )
            })
          }
          </div>
          {
            data.map((item,index)=>{
              let data ={
                id:item.id+item.materia,
                opcionCorrecta : item.opcionCorrecta,
                opcionSeleccionada :'',
                correcto : false,
              }
              //si el id exite no lo agrega
              if (test.find((element) => element.id === data.id) === undefined) {
                dispatch(addTest(data));
              }

              return(
                <Pregunta 
                  marcado={item.marcado?item.marcado:null} 
                  idPregunta={item.id}
                  materia={item.materia}
                  key={index}
                  index={index+1}
                  pregunta={item.pregunta}
                  respuestas={item.opciones}
                  correct={item.opcionCorrecta}
                  revision={revision_}
                  explicacionCorrecta={item.explicacionCorrecta}
                  campo = {dataNotas.notas[index]}
                  onPress = {props.onPress()}
                />
                  
              )
            })
          }
        </div>
    </>
    );
};