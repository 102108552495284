import React,{useState, useEffect} from 'react';
import {db} from '../../config/firebase';
import { collection, getDocs } from "firebase/firestore";
import { FaBookmark } from "react-icons/fa";

const Card =(props)=>{
  return(
    <div className="w-1/3 sm:w-full md:w-1/3 mx-3  rounded-lg border-primary border-2 bg-black flex flex-col justify-between pb-3">
      <img className="rounded-t-lg" src={props.url} alt=""/>
      <div className="p-5">
        <h5 className="text-primary font-bold">{props.titulo}</h5>
        <p className="text-white font-regular">{props.descripcion}</p>
        
      </div>
      {
        props.active?
        <button className="bg-primary text-white font-bold mx-auto my-2 py-2 px-4 rounded-lg">Ver mas</button> 
        :
        <button className="bg-primary text-white font-bold mx-auto my-2 py-2 px-4 rounded-lg">Proximamente</button> 
      }
      
    </div>
  )
}

const CardService = (props) =>{
  return(
      <div className="flex flex-row sm:flex-col md:flex-row items-start justify-center h-auto p-5 bg-white rounded-lg">
          <FaBookmark className="text-5xl text-primary mx-0 sm:mx-auto md:mx-0 sm:text-3xl md:text-5xl"/>
          <div className=" pl-5 sm:pl-0 md:pl-5 text-left sm:text-center md:text-left">
              <p className="text-xl font-bold text-fondo">{props.titulo}</p>
              <p className="font-regular">{props.descripcion}</p> 
          </div>            
      </div>
   );
}

export default function NextQuestion(props){

  const [events, setEvents] = useState([]);

  useEffect(()=>{
    getDocs(collection(db,'eventos'))
    .then((response)=>{
        let event =[]
        response.forEach((doc)=>{
          event.push(doc.data());
        });
        return event;
    }).then( doc =>{console.log(doc); setEvents(doc)})
  },[])

    return(
    <>

        <div  className="bg-black bg-no-repeat bg-cover" >
        <div className="flex flex-col items-center justify-around w-full p-8">
            <p className="font-bold text-primary text-xl text ">
            ¿Cómo funciona Apriori?
            </p>
            <p className="w-1/2 text-center text-3xl sm:text-xl md:text-3xl font-bold mt-5 tracking-wider sm:w-full md:w-1/2 text-white">Rapido y Sencillo</p>
        </div>
          {/*<div className="h-full z-10 flex flex-col w-full items-center"> 
            <div className="w-full bg-black  p-5">
            <h1 className="text-white text-4xl text-center leading-relaxed font-normal">Ultimos eventos.</h1>
            <p className="text-white text-center leading-relaxed -mt-3">Puedes ver toda la informacion ahora</p>
            </div>
            
            {/* <h1 className="text-primary">subtitulo deseado</h1> */}
            {/* <div className="w-full flex flex-row mt-5 justify-center"> */}
            {/*
              events.map((event, index)=>{
                return(
                  <Card 
                  key={index}
                  titulo={event.titulo}
                  descripcion={event.descripcion}
                  url={event.url}
                  />
                )
              })
              */
            }
          {/* </div>
          </div> */}
          <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-6 auto-rows-min h-auto w-full p-8 pb-16 sm:px-4 md:px-24 ">
            <CardService titulo="1. Encuentra la convocatoria a la que postulas." 
            descripcion="Podras ver en nuestro sitio todas las convovatorias vigentes, elige la convocatoria a la que postulas y empieza tu preparación.
            " />
            <CardService titulo="2. Prepárate en cualquier momento." 
            descripcion="Al ser usuario de Apriori, la plataforma esta disponible 24/7, solo debes elegir el momento adecuado para empezar a prepararte.
            " />
            <CardService titulo="3. Disfruta de un contenido estructurado." 
            descripcion="Nuestras pruebas siguen los parametros establecidos por su respectiva convocatoria, tales como: tiempo cronometrado y preguntas del eje temático.
            " />
            <CardService titulo="4. Comienza cuando quieras." 
            descripcion="Genera confianza con Apriori, optimiza tu tiempo y crea familiaridad con el examen que rendirás, además te mostramos los resultados y avance en tus pruebas.
            " />
        </div>

        </div>
    </>
    );
};