const types = {
    
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    COMPLETE_REGISTER_REQUEST: 'COMPLETE_REGISTER_REQUEST',
    COMPLETE_REGISTER_SUCCESS: 'COMPLETE_REGISTER_SUCCESS',
    COMPLETE_REGISTER_FAILURE: 'COMPLETE_REGISTER_FAILURE',
    
    LOGIN_WITH_FACEBOOK_REQUEST: 'LOGIN_WITH_FACEBOOK_REQUEST',
    LOGIN_WITH_FACEBOOK_SUCCESS: 'LOGIN_WITH_FACEBOOK_SUCCESS',
    LOGIN_WITH_FACEBOOK_FAILURE: 'LOGIN_WITH_FACEBOOK_FAILURE',

    LOGIN_WITH_GOOGLE_REQUEST: 'LOGIN_WITH_GOOGLE_REQUEST',
    LOGIN_WITH_GOOGLE_SUCCESS: 'LOGIN_WITH_GOOGLE_SUCCESS',
    LOGIN_WITH_GOOGLE_FAILURE: 'LOGIN_WITH_GOOGLE_FAILURE',

    CLEAR_ERROR: 'CLEAR_ERROR',
    CLEAR_SUCCESS: 'CLEAR_SUCCESS',

    IS_LOGGED_IN: 'IS_LOGGED_IN',
    
    loguin:'LOGUIN',
    logOut:'LOGOUT',
    signUp:'SIGNUP',
}
export default types;
