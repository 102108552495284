import React ,{useState,useEffect,useRef} from 'react';
import { RiSendPlane2Fill} from "react-icons/ri";

export default function Pestania  (props) {

  console.log("desde pestania"+props);

  const [state,setState] = useState(true);

  const changeState = () => {
    alert(props.id);
  }

  return(
  <>
    <div className={`${
      props.active ? 
      'divide-y items-self-end text-center p-3 border-t-2 border-b-2 w-full border-transparent bg-gray-200 text-primary':
      'divide-y items-self-end text-center p-3 border-t-2 border-b-2 w-full border-primary text-white'
    } cursor-pointer`} style={{
      width: '100%',
    }}
    onClick={()=>{props.onPress()}}
    >
      {props.nombre}
    </div>
  </>
  );
};