import React, {useState, useEffect}  from "react";
import {db} from '../../../config/firebase';
import dbRef from '../../../config/dbRef';
import { collection, getDocs,doc  } from "firebase/firestore";


export default function Panel(props) {
    const {user, idUser} = props;
    const [activeTab, setActiveTab] = useState(0);
    const [afiliates, setAfiliates] = useState([]);
    const [datos , setDatos] = useState({
        cantidad:0,
        cantidadRegistrados:0,
        cantidadPagados:0,
        cantidadCancelados:0,
        saldo:0,
        dineroRetirado:0,
    });
    const comision = 0.05;

    const handleClick = (index) => {
        setActiveTab(index);
      };
    useEffect(() => {
        let cantidad = afiliates.length;
        let cantidadRegistrados = 0;
        let cantidadPagados = 0;
        let cantidadCancelados = 0;
        let saldo = 0;
        let dineroRetirado = 0;

        afiliates.forEach(afiliate => {
            if (afiliate.state === "revision"){
                cantidadRegistrados = cantidadRegistrados+1;
            }
            if (afiliate.state === "pago"){
                cantidadPagados = cantidadPagados+1;
                saldo = saldo+(afiliate.monto*comision);
            }
            if (afiliate.state === "pay"){
                cantidadCancelados = cantidadCancelados+1;
                dineroRetirado = dineroRetirado+(afiliate.monto*comision);
            }
            
        });
        setDatos({
            cantidad,
            cantidadRegistrados,
            cantidadPagados,
            cantidadCancelados,
            saldo,
            dineroRetirado,
        });
    }, [afiliates]);


    
    useEffect(() => {
        const getAfiliates = async() => {
            const cuenta = doc(db, dbRef.user, idUser);
            const cuentaSnap = await getDocs(collection(cuenta, "afiliates"));

            let cuentaData = [];
            cuentaSnap.forEach(doc => {
                cuentaData.push({id:doc.id,...doc.data()});
            });
            console.log(cuentaData);
            setAfiliates(cuentaData);
        }
        getAfiliates();
    }, [idUser]);


    return (
        <div className='mt-16 p-4 text-center w-full flex flex-col justify-start items-center bg-gray-100 min-h-90'>
            <div className='w-full flex flex-row justify-between items-between p-4 w-90 bg-white shadow-md rounded'>
                <div className="text-left">
                    <h2 className='text-2xl text-primary'>Panel de control de afiliados</h2>
                    <p className='text-gray-500'>Bienvenido {user?.name} </p>
                    <p className='text-gray-500'>cantidad: {datos.cantidad}</p>

                </div>
                <div>
                    <h2 className='text-2xl text-primary'>Saldo : <span className="text-gray-500 font-normal">Bs. {datos.saldo.toFixed(2)}</span></h2>
                    <p className='text-gray-500'>Dinero retirado: Bs. {datos.dineroRetirado}</p>
                </div>
            </div>
            <div className='w-full flex flex-col justify-between items-between p-4 w-90 bg-white shadow-md rounded mt-4'>
                <div className="flex border-b border-gray-200">
                    <button
                        className={`${activeTab === 0 ? "bg-gray-100" : ""} py-4 px-6 text-gray-800 font-semibold border-b-2`}
                        onClick={() => handleClick(0)}
                    >
                        Registrados
                        <span className="text-xs text-white font-normal mx-1 p-1 px-2 bg-gray-400 rounded-full">{datos.cantidadRegistrados}</span>
                    </button>
                    <button
                        className={`${activeTab === 1 ? "bg-gray-100" : ""} py-4 px-6 text-gray-800 font-semibold border-b-2`}
                        onClick={() => handleClick(1)}
                    >
                        Pagados 
                        <span className="text-xs text-white font-normal mx-1 p-1 px-2 bg-gray-400 rounded-full">{datos.cantidadPagados}</span>
                    </button>
                    <button
                        className={`${activeTab === 2 ? "bg-gray-100" : ""} py-4 px-6 text-gray-800 font-semibold border-b-2`}
                        onClick={() => handleClick(2)}
                    >
                        Cancelados
                        <span className="text-xs text-white font-normal mx-1 p-1 px-2 bg-gray-400 rounded-full">{datos.cantidadCancelados}</span>
                    </button>
                </div>

                <div className="px-6">
                    {activeTab === 0 && 
                        <div className='w-full flex flex-col justify-start items-center'>
                            <p className="text-gray-400 text-sm self-start my-2">*Los usuarios de acontinuacion se suscribieron con su codigo de afiliado, pero aun no hicieron la cancelacion de la suscripción.</p>
                             <div className="w-full overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                <thead>
                                    <tr
                                    className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50"
                                    >
                                    <th className="px-4 py-3">Nombre completo</th>
                                    <th className="px-4 py-3">Monto</th>
                                    <th className="px-4 py-3">Fecha</th>
                                    <th className="px-4 py-3">Plan</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y">
                                    {afiliates.map((item, index) => (
                                        item.state === "revision" &&
                                            <tr key={index} className="text-gray-700 text-left">
                                                <td className="px-4 py-3">{item.name+" "+item.lastName}</td>
                                                <td className="px-4 py-3">{item.monto}</td>
                                                <td className="px-4 py-3">{item.date.toDate().toLocaleDateString()}</td>
                                                <td className="px-4 py-3">{item.plan}</td>
                                            </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {activeTab === 1 &&
                        <div className='w-full flex flex-col justify-start items-center'>
                            <p className="text-gray-400 text-sm self-start my-2">*Los usuarios de acontinuacion pagaron su suscripción, pero aun no se ha retirado la comisión.
                            </p>
                            <div className="w-full overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                <thead>
                                    <tr
                                    className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-green-100"
                                    >
                                    <th className="px-4 py-3">Nombre completo</th>
                                    <th className="px-4 py-3">Monto</th>
                                    <th className="px-4 py-3">Fecha</th>
                                    <th className="px-4 py-3">Plan</th>
                                    <th className="px-4 py-3">Comisión (5%) </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y">
                                    {afiliates.map((item, index) => (
                                        item.state === "pago" &&
                                            <tr key={index} className="text-gray-700 text-left">
                                                <td className="px-4 py-3">{item.name+" "+item.lastName}</td>
                                                <td className="px-4 py-3">{item.monto} Bs.</td>
                                                <td className="px-4 py-3">{item.date.toDate().toLocaleDateString()}</td>
                                                <td className="px-4 py-3">{item.plan}</td>
                                                <td className="px-4 py-3">{(item.monto*comision).toFixed(1)} Bs.</td>
                                            </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div className='w-full flex flex-col justify-start items-center'>
                            <p className="text-gray-400 text-sm self-start my-2">*Usted ya retiro la comisión de los usuarios de acontinuacion.
                            </p>
                            <div className="w-full overflow-x-auto">
                                <table className="w-full whitespace-no-wrap">
                                <thead>
                                    <tr
                                    className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-red-100"
                                    >
                                    <th className="px-4 py-3">Nombre completo</th>
                                    <th className="px-4 py-3">Monto</th>
                                    <th className="px-4 py-3">Fecha</th>
                                    <th className="px-4 py-3">Plan</th>
                                    <th className="px-4 py-3">Comisión (5%)</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y">
                                    {afiliates.map((item, index) => (
                                        item.state === "pay" &&
                                            <tr key={index} className="text-gray-700 text-left">
                                                <td className="px-4 py-3">{item.name+" "+item.lastName}</td>
                                                <td className="px-4 py-3">{item.monto} Bs.</td>
                                                <td className="px-4 py-3">{item.date.toDate().toLocaleDateString()}</td>
                                                <td className="px-4 py-3">{item.plan}</td>
                                                <td className="px-4 py-3">{(item.monto*comision).toFixed(1)} Bs.</td>
                                            </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
    
        </div>
    );
}