import React ,{useState,useEffect,useRef} from 'react';
import Pregunta from './components/pregunta';
import Materia from './components/materia';
import Pestania from './components/pestania';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {auth} from '../../config/firebase';
import {evaluarExamen} from './../../utils/exam';
import {loadExam,loarExamTest, saveNotas, getQuestion, clearExam} from './../../redux/actions/exam';
import {updateIntentos} from './../../redux/actions/user';
import { ref } from 'firebase/storage';
import Modal from '../components/Modal';
import {Redirect} from 'react-router-dom';
import {getExamTest, privateToken} from './getExam';


const Pestanias = (props) => {

  
  return(
    <button className="bg-fondo m-1 mx-2 p-2 border-2 text-white  -md border-withe  hover:border-primary hover:text-primary active:bg-blue-600 focus:bg-primary focus:border-primary focus:text-fondo">
  {props.name}
</button>
  )
}

export default function Examen(props){
  const minutos = useRef(null);
  const segundos = useRef(null);
  const {idMaterias} = useParams();
  const reduxExam = useSelector(state => state.exam);
  const test = useSelector(state => state.test.test);
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState('');
  const [varContent,setVarContent] = useState([]);
  const [examData,setExamData] = useState();
  const [revision,setRevision] = useState(false);
  const [visible,setVisible] = useState(false);
  const [notas,setNotas] = useState({});
  const [isLoad,setIsLoad] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [notasRevision ,setNotasRevision] = useState ([]);

  const [screenWidth,setScreenWidth] = useState(window.innerWidth);
  const [materiaActive,setMateriaActive] = useState('');

  const [pestaniasActivas,setPestaniasActivas] = useState(false);

  const rev = ()=>{
    setRevision(true);
  }


//ventana de confirmacion para cerrar ventana
  useEffect(()=>{
    window.onbeforeunload = function(e) {
      e.preventDefault();
      return '¿Esta seguro que desea salir?';
    };
  },[])

  useEffect(()=>{
    window.back = ()=>{
      alert('No se puede regresar');
    };
  },[])

  const finalizarRevision = ()=>{
    let confirm = window.confirm(revision?"¿Esta seguro de finalizar la revisión?":"¿Esta seguro de finalizar el Examen?");
    if(confirm){
      // dispatch(getQuestion(auth.currentUser.uid));
      let correcta = 0;
      let incorrecta = 0;
      test.map((item) => {
        if(item.correcto)
          correcta++;
        else
          incorrecta++;
      });
      //ponderacion a 100 puntos
      //redondear a 2 decimales
      // let nota = Math.round((correcta*100/test.length)*100)/100;
      setNotas({
        nota : Math.round((correcta*100/test.length)*100)/100,
        correctas : correcta,
        incorrectas : incorrecta,
      });
      setVisible(true);
      rev();
      if(revision){
        setRedirect(true);
      }
    }
    
  }

  useEffect(()=>{
    if(examData){
      const minut = evaluarExamen(examData)
      console.log(minutos.current.innerText);
      console.log(segundos.current);
      minutos.current.innerText = `${minut.correctas + minut.incorrectas}`;
      segundos.current.innerText = '0';
    }
  },[minutos,segundos,examData])


  //hacer un cuenta regresiva
  useEffect(() => {
    let interval = setInterval(() => {
      if(minutos?.current?.innerText === '0' && segundos.current.innerText === '0'){
        clearInterval(interval);
        setVisible(true);
        rev();
      }
      else{
        
        if(segundos?.current?.innerText === '0'){
          segundos.current.innerText = '59';
          if(parseInt(minutos.current.innerText) - 1>10){
            minutos.current.innerText = '0'+parseInt(minutos.current.innerText) - 1;
          }else{
            minutos.current.innerText = parseInt(minutos.current.innerText) - 1;
          }
        }
        else{
          if(segundos?.current?.innerText){
            segundos.current.innerText = parseInt(segundos.current.innerText) - 1;
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  },[minutos,segundos]);

  const cargarNotas = (preguntaCorrecta, opcionSeleccionda, pocision )=>{
    if(opcionSeleccionda === preguntaCorrecta)
      pocision = 1;
    else
      pocision = 0;
  }

  useEffect(()=>{
    if(examData){
      for (let index = 0; index < examData.length; index++) {
        varContent[index] = 'none';
        
      }
      varContent[0] = 'flex';
    }
  },[examData]);

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'none';
  }

  let contador = -1;

  function changeContent(id , materia){
    var auxVector =[];
    for (let i = 0; i < varContent.length; i++) {
      auxVector[i] = 'none';
    }
    auxVector[id] = 'flex';
    setPestaniasActivas(!pestaniasActivas);
    setVarContent(auxVector);
    setMateriaActive(materia);

  }
  const loadExamTest = async() => {
    try{
      const data = await getExamTest(idMaterias);
      setIsLoad(true);
      setExamData(data);
      dispatch(loarExamTest(data));
      setMateriaActive(data[0].materia);
    }catch(error){
      setIsLoad(false);
      console.log(error);
    }
  }


  useEffect(()=>{
    if (reduxExam.examTest) {
      if(reduxExam.examTest[0].idCuenta==="_test_acc"){
        setExamData(reduxExam.examTest);
      } else{
        //const copia = reduxExam.exams.filter(e=>e.idCuenta === `_test_acc`);
        loadExamTest()
        //console.log("cantoda de de la copia: ",copia.length);
        //dispatch(loarExamTest(copia));
      }
    } else {
      loadExamTest()
      //const copia = reduxExam.exams.filter(e=>e.idCuenta === `_test_acc`);
      //console.log("cantoda de de la copia: ",copia.length);
      //dispatch(loarExamTest(copia));
    }

  },[reduxExam.examTest]);


    return(
     <>{isLoad?
    
    <>
    {redirect?<Redirect to="/Pricing" />:null}
      <Modal visible={visible} title={'Tiempo Agotado'} onClose={()=>{setVisible(false);}}>
        <div className="flex flex-col items-center justify-center">
        {notas?(
            <>
              <p className="text-center text-green-500 text-xl">Correctas:{notas.correctas}</p>
              <p className="text-center text-red-500 text-xl">Incorrectas:{notas.incorrectas}</p>
              <p className="text-center text-2xl">Nota Final</p>
              <p className={`text-center text-2xl ${notas.nota<=49?"text-red-500":notas.nota<=70?"text-yellow-500":"text-green-500"}`}>
                {notas.nota}/100
                </p>
            </>):
          null} 
          <p className="text-center text-xl my-2">
            Para ver los estadisticas de esta prueba Contáctanos ahora.
          </p>
          <div className="flex justify-center">
          <a href="https://api.whatsapp.com/send?phone=59161885865&text=Hola%20acabo%20de%20rendir%20un%20examen%20de%20prueba.%20Quiero%20mas%20información" 
          className="bg-fondo hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
            Contactanos
          </a>
          <button className="bg-primary rounded-full ml-2 text-white  py-2 px-4 hover:bg-fondo hover:border-fondo active:bg-blue-600 focus:bg-primary focus:border-primary focus:text-fondo" onClick={()=>{setVisible(false);}}> 
            Ir a revisión
          </button>
          </div>
        
          
        </div>
      </Modal>
      <div className='flex flex-col justify-center items-between bg-black w-full py-5 text-white absolute'>
        <div className='flex flex-row justify-around items-center'>
          
         {
           screenWidth < 768 ?
           <div className='flex flex-row justify-center justify-center mt-0'>
            <div className='flex flex-col justify-start items-start' >
              <p ref={minutos} className='text-white text-center text-3xl'>60 :</p>
              <p className='text-primary text-start'>minutos</p>
            </div>
            <p className='text-white self-start text-3xl'>:</p>
            <div className='flex flex-col justify-center items-center' >
              <p ref={segundos} className='text-white text-center text-3xl'>12</p>
              <p className='text-primary text-center'>segundos</p>
            </div>
          </div>:
          'Este es un examen unicamente de prueba, Suerte'
         }

          <button className='bg-primary p-2 rounded' onClick={()=>finalizarRevision()}>{revision?'Finalizar revisión': 'Finalizar Examen'}</button>
        </div>
        {
          screenWidth < 768 ?
          <div className='flex flex-col justify-around items-center'>
          <p
            onClick={()=>setPestaniasActivas(!pestaniasActivas)}
            className='cursor-pointer text-lg font-normal bg-gray-100 p-2 rounded px-6 mt-4 text-fondo mx-2 text-center'
          >{materiaActive} </p>
          <div className={`bg-blue-300 w-11/12 transition duration-100 ease-in-out transform mt-3 ${pestaniasActivas?'scale-100 h-auto':'scale-0 h-0'}`}>
            {
              examData?
                  examData.map((item,index)=>{
                    return(
                      <Pestania 
                        key={index}
                        nombre={item.materia}  
                        id={index} 
                        onPress={()=>{changeContent(index,item.materia)}}
                        active={varContent[index]==='flex'?true:false}
                      />
                    )
                  }):null
              
            }
          </div>
        </div>:null
        }
        
      </div>
      <div className='flex flex-row w-1/2 h-1/2 w-full'>
        <div className='bg-fondo w-1/4 flex flex-col justify-start items-center py-12 sm:hidden md:flex mt-16' >
          {
            screenWidth > 768 ?
            <div className='flex flex-row justify-center justify-center mt-12'>
              <div className='flex flex-col justify-start items-start' >
                <p ref={minutos} className='text-white text-center text-6xl'>60 :</p>
                <p className='text-primary text-start'>minutos</p>
              </div>
              <p className='text-white self-start text-6xl'>:</p>
              <div className='flex flex-col justify-center items-center' >
                <p ref={segundos} className='text-white text-center text-6xl'>12</p>
                <p className='text-primary text-center'>segundos</p>
              </div>
            </div>
            :null
          }
          {
            screenWidth > 768 ?
            <div className='mt-6 items-self-start text-center flex flex-col w-full'>
            {//inicio de pestania
            examData?
              examData.map((item,index)=>{
                return(
                  <Pestania 
                    key={index}
                    nombre={item.materia}  
                    id={index} 
                    onPress={()=>{changeContent(index,item.materia)}}
                    active={varContent[index]==='flex'?true:false}
                  />
                )
              }):null
              
            }
          </div>:null
          }
          
        </div>
            {
              examData?
              examData.map((item,index)=>{
                console.log(varContent[contador]);
                contador++;

                return(
                  <Materia 
                    key={index}
                    display={`${varContent[contador]}`} 
                    data={item}
                    notas= {notasRevision}
                    onPress={()=>{cargarNotas()}}
                    revision={revision}
                    espacio = {notasRevision[index]}
                  />
                  
                );
              }):null
            }
        {/**fin de  materias  */}
      </div>
    </>:<>
        <text>Cargando...</text>
    </>
}
    </>
    );
};