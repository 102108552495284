import React,{useState} from 'react';
import { FaFacebookF, FaInstagram,FaTwitter,FaEnvelope} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Card =(props)=>{
  return(
    <div className="w-1/3 mx-3 min-h-5/6 rounded-lg border-white border-2 bg-black flex flex-col justify-between pb-3">
      <img className="min-h-1/3" src={props.urlImg} alt="" />
      <div className="p-5">
        <h5 className="text-primary font-bold">props.title</h5>
        <p className="text-white font-regular">Descripciones del evento donde se muestra las caracteristicas y todo lo respecto al evento </p>
        
      </div>
      <button className="bg-primary text-white font-bold mx-auto my-2 py-2 px-4 rounded-lg">Ver mas</button> 
    </div>
  )
}

export default function Footer(props){

    return(
    <>

        <div  className="h-auto bg-gray-100 z-10 flex flex-col items-center mt-5 w-full sm:items-start" >
            {
                props.mensaje ?
                <div className="w-full bg-fondo p-10 sm:p-3 md:p-5 flex flex-row sm:flex-col md:flex-row items-center justify-around text-left sm:text-center md:text-left">
                    <div className=" w-3/4 sm:w-full md:w-1/2 p-3">
                        <h1 className="text-primary">Para cualquier Duda o Consulta</h1>
                        <p className="text-extrabold text-3xl text-white">Confíe su futuro a nuestro equipo de profesionales</p>
                    </div>
                    <div >
                        <button className="bg-primary text-white font-normal mx-auto my-2 py-2 px-4 rounded-full">Contáctanos</button>
                    </div>
                </div>:null
            }
          <div className="p-10 z-10 flex flex-row items-start sm:flex-col sm:p-2 w-full md:flex-row"> 
            <div className="w-full m-3 flex flex-col text-start sm:hidden md:flex">
                <h1 className="mb-5 text-regular text-xl text-start ">Empresa</h1>
                <ul>
                    <li>
                    <Link to="/terminos" className='hover:text-primary'>Terminos y condiciones</Link>
                    </li>
                    <li>
                        <Link to="/politicas" className='hover:text-primary'>Politicas de privacidad</Link>
                    </li>
                    <li>
                    <a href='https://wa.me/59175413873' target='_blank' rel="noopener noreferrer" className='hover:text-primary'>
                       Soporte
                      </a>
                    </li>
                    <li>
                    <Link to="/contact"  className='hover:text-primary'>Contacto</Link>
                    </li>
                </ul>
            </div>
            <div className="w-full m-3 flex flex-col text-start sm:hidden md:flex"> 
                <h1  className="mb-5 text-xl">Enlaces Rápidos</h1>
                <ul>
                    <li>
                        <Link to="/" className='hover:text-primary'>Inicio</Link>
                    </li>
                    <li>
                        <Link to="/team" className='hover:text-primary'>Nosotros</Link>
                    </li>
                    <li>
                        <Link to="/pricing" className='hover:text-primary'>Planes</Link>
                    </li>
                    <li>
                        <Link to="/service" className='hover:text-primary'>Servicio</Link>
                    </li>
                    <li>
                        <Link to="/contact" className='hover:text-primary'>Contactos</Link>
                    </li>
                </ul>
            </div>
            {
                props.contact ?
               null
              :<div className="w-full m-3 -ml-10 sm:-ml-0 sm:p-3 sm:text-center">
              <h1 className="mb-5 text-xl">Suscríbete Con Nosotros</h1>
              <p>Suscríbase para recibir las últimos convocatorias y recursos</p>
              <div className='sm:flex sm:flex-col'>
              <input 
              id='footer-message'
              type="text" 
              placeholder="Suscríbase ahora ..." 
              className="bg-white border border-primary rounded-lg p-2 mt-5"/>
              <button 
              className="bg-primary text-white font-bold mx-auto my-2 py-2 px-4 rounded mt-5"
              onClick={()=>{
                const message = document.getElementById('footer-message').value;
                if(message.length>0){
                  window.open(`https://api.whatsapp.com/send?phone=59161885865&text=${message}`);
                }else{
                  alert('Escribenos tu mensaje');
                }
              }}
              >Suscríbete</button>
              </div>  
            </div> 
            }
            
            <div className="w-full m-3 sm:text-center">
                <h1 className="mb-5 text-3xl"><span className="mb-5 text-primary">A</span>priori</h1>
                <a className="font-bold" href="mailto:apriori.bolivian@gmail.com" >apriori.bolivian@gmail.com</a>
                <p><a href='https://wa.me/+591 61885865' target='_blank' rel="noopener noreferrer" className='hover:text-primary'>
                +591 61885865
                      </a></p>
                
                    
            </div>

            
          </div>
          <div className="flex flex-row justify-around items-center w-full bg-gray-200 py-4 sm:flex-col sm:p-3 sm:text-center">
              <div>© Copyright Reserved to AprioriBolivia by R&L Dev</div>
              
              <div className="flex flex-row justify-between w-1/3 sm:w-full sm:justify-center">
                  <div className="flex flex-row items-center sm:mx-2">
                      <FaFacebookF  className="text-xl"/>
                      <p className='sm:hidden md:block block'>Facebook</p>
                  </div>
                  <div className="flex flex-row items-center sm:mx-2">
                      <FaInstagram className="text-xl"/>
                      <p className='sm:hidden md:block block'>Instangram</p>
                  </div>
                  <div className="flex flex-row items-center sm:mx-2">
                      <FaEnvelope  className="text-xl"/>
                      <p className='sm:hidden md:block block'>Correo</p>
                  </div>

              </div>
          </div>
        </div>
    </>
    );
};