/* eslint-disable no-unused-vars */
import React,{useState, useEffect} from "react";
import jwt from 'jsonwebtoken';
import {privateToken} from '../ExamTest/getExam';
import Footer from "../components/footer";
import {addCart} from '../../redux/actions/cart';
import {useDispatch, useSelector} from 'react-redux';
import dbRef from "../../config/dbRef";
import { getAuth } from "firebase/auth";
import {db} from '../../config/firebase'
import { collection} from "firebase/firestore";
import { doc, addDoc } from "firebase/firestore";
import { Bar } from "../components/onload";
import { getUser } from '../../redux/actions/user';
import {auth} from '../../config/firebase';
import { Contactanos } from "../components/uiComponents";
import { getExamTest } from "../ExamTest/getExam";
import {Link} from 'react-router-dom';
import ReactGa from 'react-ga';

const Details = (props) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [id,setId]= useState('');
  const [data ,setData]= useState();
  const [textBtn,setTextBtn]= useState("Adquirir Plan");
  console.log(props);

  const btnAnalytics = (btn) => {
    ReactGa.event({
      category: 'Button-details',
      action: 'Click button :'+btn,
    });
  }

  useEffect(() => {
    try {
    if(props.location.data){
      if(props.location.data.nombre){
        setData(props.location.data);
        if(props.location.data.id)
          setId(props.location.data.id);
        if(props.location.data.idExamen)
          setId(props.location.data.idExamen);
        return;
      }
      if(props.location.data.data.nombre){
        if(props.location.data.id)
          setId(props.location.data.id);
        if(props.location.data.data.idExamen)
          setId(props.location.data.idExamen);
        setData(props.location.data.data);
        return;
      }
    }else{
      props.history.push('/convocatoria');
    }
    } catch (error) {
      props.history.push('/convocatoria');
    }
    
  } ,[props.location.data])

  const anidirExamen = async (paramId) => {
    let dataMaterias=[];
    data.materias.map(materia => {
      dataMaterias.push(materia.nombre);
    })
    
    const auth = getAuth();
    const user = auth.currentUser;
    //const messageRef = doc(db, "rooms", "roomA", "messages", "message1");
    const cuenta = doc(db, dbRef.user + '/' + user.uid);
    const newExamFinal = {
      idExamen: paramId,
      fechaRegistro: new Date().toISOString(),
      plan:'Pendiente',
      tipo : 'PFinal',
      nombre : data.nombre,
      fechaExamen: data.fecha,
      materias :dataMaterias,
      estado: 'pendiente',
      cantidadPreguntas: `${data.cantidadPreguntas?data.cantidadPreguntas:0}`,
      intentos: `${data.intentos?data.intentos:0}`,
    }
    const newExamPrueba = {
      idExamen: paramId,
      fechaRegistro: new Date().toISOString(),
      plan:'Pendiente',
      tipo : 'PPrac',
      nombre : data.nombre,
      fechaExamen: data.fecha,
      materias :dataMaterias,
      estado: 'pendiente',
      cantidadPreguntas: `${data.cantidadPreguntas?data.cantidadPreguntas:0}`,
      intentos: `${data.intentos?data.intentos:0}`,
    }
    await addDoc(collection(cuenta, 'cuenta'), newExamFinal);
    await addDoc(collection(cuenta, 'cuenta'), newExamPrueba);
    alert('Examen agregado');
  };

  const verificarExamen = (paramId) => {
    let examenEncontrado = true;
    user.cuenta.map(examen => {
      if(examen.idExamen === paramId){
        examenEncontrado = false;
      }else{
        examenEncontrado = true;
      }
    });
    return examenEncontrado;
  }


    return (
        <>
        {
          data ?<>
          <div className="bg-gray-100 bg-contexto mt-20 py-8 px-10 sm:px-6 md:px-10 flex flex-col items-center justify-center">
            <h1 className="text-center text-5xl sm:text-2xl md:text-5xl font-bold text-white">{data.nombre&&data.nombre}</h1>
            <div className="text-center py-4 flex flex-col flex-wrap">
            <p className='text-xl text-white sm:text-base md:text-xl'>
              Te damos la posibilidad de probar nuestra plataforma de forma gratuita
              
            </p>
            <Link
              onClick={()=>btnAnalytics('Prueba Gratis')}
              className="bg-primary text-white grow-0 text-xl m-auto font-bold py-4 sm:py-2 md:py-4 px-4 rounded-full mt-5 transition ease-in-out delay-150 duration-300 border-white border-2 "
              to={`/examtest/${jwt.sign(JSON.stringify(data.materias), privateToken)}`}
            >
              Prueba gratis ahora
            </Link>
          </div>
          </div>
          
          <div className="h-auto w-full py-3 px-32 sm:px-4 md:px-32 flex flex-row sm:flex-col md:flex-row justify-between">
            <div  className="w-1/2 sm:w-full md-w-1/2  text-left sm:text-center md:text-left p-10 sm:p-2 md:p-10 flex flex-col justify-start items-start sm:justify-center md:justify-start">
              <p className="text-xl font-bold text-primary text-left sm:text-center md:text-left">Datos generales:</p>
              <p className="text-3xl">{data&&data.nombre}</p>
              <p className="text-sm text-justify mt-3">
                {data&&data.descripcion}
              </p>
              <p className="text-xl font-bold text-primary mt-3"> Recursos: </p>
              <ul className="ml-8">
                  {data&&
                    data.recursos?
                    data.recursos.map((item,index)=>{
                      return(
                        <li type = "square" key={index} className="text-sm">
                          <a href={item.url} target="_blank" rel="noopener noreferrer">{item.nombre}</a>
                        </li>
                      )
                    })
                    :
                    null
                  }
              </ul>
              <div className="w-full flex flex-row items-center justify-center">
                <Link
                  onClick={()=>btnAnalytics('Prueba Gratis')}
                  className="bg-primary text-white font-normal mx-auto py-4 px-4 mr-4 rounded mt-5 transition ease-in-out delay-150 duration-300"
                  to={`/examtest/${jwt.sign(JSON.stringify(data.materias), privateToken)}`}
                >
                  Prueba gratis
                </Link>
                
                {
                data.estado==='activo'?
                
                <button className={`bg-primary text-white font-normal mx-auto py-4 px-4 rounded mt-5 transition ease-in-out delay-150 duration-300`}
                onClick={()=>{
                  btnAnalytics('adquirir plan');
                  if(textBtn === 'Adquirir Plan'){
                    setTextBtn("Agregado");
                    dispatch(addCart({
                      id:id,
                    })).then(() => {
                        if(auth.currentUser){
                          if(verificarExamen(id)){
                            anidirExamen(id).then(()=>{
                              const userID = auth.currentUser.uid;
                              dispatch(getUser(userID));
                                }).then(()=>{
                                  props.history.push('/profile'); 
                                });
                          }else{
                            alert('Esta Prueba ya existe en su perfil');
                            props.history.push('/convocatoria');
                          }

                          
                          // alert('hay usuario');
                          // props.history.push('/profile'); 
                        }else{
                          // alert('no hay usuario')
                          props.history.push('/login');
                        }
                    });
                  } else {
                    console.log('procesando');
                  }
                }}
              >
                {/**transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 */}
                {textBtn === 'Adquirir Plan'?textBtn:<Bar color={'white'}/>}
                </button>
                :
                <Contactanos type='fill' texto='Contactanos'/>
              }
              
              {/* <Link className="bg-primary text-white font-normal mx-auto py-2 px-4 rounded mt-5" to={{
                      pathname: `/pricing`,
                      data: {
                        nombre: 'Convocatoria'
                      }
                    }}>Adquirir Plan</Link> */}
                
              </div>
            </div>
            <div className="w-1/2 p-10 flex flex-col justify-start mx-1">
              <p className="text-xl text-primary font-bold ">Documentación mínima habilitante:
              </p>
              <ul className="ml-8" >
                {data&&
                  data.documentacion?
                  data.documentacion.map((item,index)=>{
                    return(
                      <li type = "square" key={index} className="text-sm">
                        {item}
                      </li>
                    )
                  }
                  )
                  :
                  null
                }
              </ul>
            </div>
              
          </div></>:null 
        }
        <Footer/>
        
        </>
    );
}

export default Details;