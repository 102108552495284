import React from "react";
const title = (props) => {

    return (
        <>
            <div className="flex flex-col items-center justify-around w-full p-8">
            <p className="font-bold text-primary text-xl text ">{props.titulo}</p>
            <p className="w-1/2 text-center text-3xl sm:text-xl md:text-3xl font-bold mt-5 tracking-wider sm:w-full md:w-1/2">{props.mensaje}</p>
        </div>
        </>
    );
}

export default title;