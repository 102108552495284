import types from '../types/auth';

const initialState = {
    user: [],
    loading: false,
    loadingRegister: false,
    isAuthenticated: false,
    isAuthenticatedLoading: true,
    error: null,
    errorRegister: null,
    success: null,
};

export const authReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.loguin:
            return {
                uid:action.payload.uid,
                name: action.payload.displayName,
            };

        case types.IS_LOGGED_IN:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isAuthenticatedLoading: false,
            };

        case types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user:[action.payload],
                isAuthenticated: true,
            };

        case types.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: {error:action.payload},
            };
            
        case types.REGISTER_REQUEST:
            return {
                ...state,
                loadingRegister: true,
            };
        case types.REGISTER_SUCCESS:
            return {
                ...state,
                loadingRegister: false,
                user:[action.payload],
                isAuthenticated: true,
                error: null,
                success: true,
            };
        case types.REGISTER_FAILURE:
            return {
                ...state,
                loadingRegister: false,
                errorRegister: {error:action.payload},
            };

        case types.CLEAR_ERROR:
            return {
                ...state,
                error: null,
                loadingRegister: false,
                errorRegister: null,
            };

        case types.CLEAR_SUCCESS:
            return {
                ...state,
                loadingRegister: false,
                errorRegister: null,
                success: null,
            };

        case types.logOut:
            return {
                ...state,
                user:[],
                isAuthenticated: false,
            }
            
        default: 
            return state;
    }
}

//export {authReducer}
