import types from '../types/user';

const initialState = {
    user: null,
    cuenta: null,
    loading: false,
    error: null,
};

export const userReducer =  (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,

            };
        case types.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                cuenta:action.payload.cuenta,
                loading: false,
                error: null,
            };
        case types.GET_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CLEAR_USER:
            return {
                ...state,
                user: null,
            };
        default: 
            return state;
    }
}
