//import { id } from 'react-horizontal-scrolling-menu/dist/types/constants';
import { db } from '../../src/config/firebase';
import dbRef from '../config/dbRef';
import {shuffleArray} from './arrays';
//import { useDispatch, useSelector } from 'react-redux';
import { collection, getDocs, doc, getDoc, query,where} from "firebase/firestore";

export const loadExams = (idExamem, iduser) =>
new Promise(async (resolve, reject) => {
    try{
        console.log('idExamen:', idExamem);
        console.log('iduser:', iduser);
        const examenRef = doc(db,dbRef.user, iduser, 'examenes', idExamem);
        const docSnap = await getDoc(examenRef);
        
        resolve(docSnap.data().examen);
        //data.push(materias);

        // resolve(materias);
    } catch (error) {
        reject(error);
    }
});

export const getExamId =  (examId) =>
new Promise(async (resolve, reject) => {
    try{
        const docRef = doc(db, dbRef.exam, examId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log('data obtenido de exam');
        } else {
            reject('[utils][exam]No Existe el examen');
            console.log("[Actions][exam][getQuestion]:Error No existe el examen");
        }
        const exam = docSnap.data()
        resolve(exam);

    } catch (error) {
        reject(error);
    }

});

export const getMateriaId =  (IdMateria) => 
new Promise(async (resolve, reject) => {
    try {
        const materias = query(collection(db, IdMateria), where("id",">=",0));
        const querySnapshot = await getDocs(materias);
        let preguntas= [];
        querySnapshot.forEach(doc => {
            preguntas.push(doc.data());
        })
        //mezclamos el orden de las preguntas
        shuffleArray(preguntas);
        
        //mezclamos el orden de las opciones
        preguntas.map(pregunta =>shuffleArray(pregunta.opciones));

        resolve(preguntas);

    } catch(error) {
        reject(error);
    }
});

export const getMateriaId2 =  (IdMateria, cantidadP) => 
new Promise(async (resolve, reject) => {
    try {
        const materias = query(collection(db, IdMateria), where("id",">=",0));
        const querySnapshot = await getDocs(materias);
        let preguntas= [];
        querySnapshot.forEach(doc => {
            preguntas.push(doc.data());
        })
        //mezclamos el orden de las preguntas
        shuffleArray(preguntas);
        
        //mezclamos el orden de las opciones
        preguntas.map(pregunta =>shuffleArray(pregunta.opciones));

        const preguntas_ = preguntas.slice(0,cantidadP);
        resolve(preguntas_);

    } catch(error) {
        reject(error);
    }
});

export const evaluarExamen = (exam) => {
    let materias = [];
    let idCuenta = '';
    let totalCorretas = 0; 
    let totalIncorrectas = 0;
    exam.map((mat)=>{
        const materia = mat.materia;
        idCuenta = mat.idCuenta;
        let correctas = 0;
        let incorrectas = 0;
        mat.preguntas.map((p) => {
            if(p.marcado){
                if(p.marcado === p.opcionCorrecta){
                    correctas++;
                    totalCorretas++;
                }else{
                    incorrectas++;
                    totalIncorrectas++;
                }
            } else {
                incorrectas++;
                totalIncorrectas++;
            }
        });
        let materia_ = {            
            materia: materia,
            correctas: correctas,
            incorrectas: incorrectas,
        }
        materias.push(materia_);
    })
    console.log("total de ppreguntas :",totalCorretas, totalIncorrectas);
    const notaPorPregunta = 100/(totalCorretas+totalIncorrectas);
    const calificacion = notaPorPregunta*totalCorretas;
    const nota = {
        fecha: new Date(),
        correctas: totalCorretas,
        incorrectas: totalIncorrectas,
        calificacion: calificacion,
        idCuenta: idCuenta,
        notas:materias
    };

    return nota;
}

