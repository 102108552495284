import { db,auth } from '../../../config/firebase';
import dbRef from '../../../config/dbRef';
import { collection, getDocs, doc, setDoc, getDoc, query,where} from "firebase/firestore";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc } from "firebase/firestore";

export const getInscritoByEmail = (eventId, email) =>
new Promise(async (resolve, reject) => {
    try{
        const cursos = doc(db, dbRef.cursos, eventId);
        const inscritos = await getDocs(collection(cursos, "Inscritos"));
        let dataInscritos = [];
        inscritos.forEach(doc => {
            dataInscritos.push({id:doc.id,...doc.data()});
        });
        const inscrito = dataInscritos.find(inscrito => inscrito.email === email);
        if(inscrito){
            resolve('existe');
        } else {
            resolve('no inscrito');
        }
    } catch (error) {
        reject(error);
    }

});

export const userExist = (email) =>
new Promise(async (resolve, reject) => {
    try{
        const users = await  getDocs(collection(db, dbRef.user));
        let dataUsers = [];
        users.forEach(doc => {
            dataUsers.push({id:doc.id,...doc.data()});
        });
        //email "paolapebles@gmail.com
        const inscrito = dataUsers.find(u => u.email === email);
        if(inscrito){
            resolve(inscrito.id);
        } else {
            resolve('no Existe');
        }
    } catch (error) {
        reject(error);
    }

});
export const randomString = (length) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export const generateBarCode = (eventId) =>
new Promise(async (resolve, reject) => {
    try{
        let igual=0;
        while(igual===0){
            const barCode = randomString(10);
            const cursos = doc(db, dbRef.cursos, eventId);
            const inscritos = await getDocs(collection(cursos, "Inscritos"));
            let dataInscritos = [];
            inscritos.forEach(doc => {
                dataInscritos.push({id:doc.id,...doc.data()});
            });
            const inscrito = dataInscritos.find(inscrito => inscrito.codigoBarras === barCode);
            if(inscrito){
                igual=0;
            } else {
                igual=1;
                resolve(barCode);
            }
        }
    } catch (error) {
        reject(error);
    }

});

export const registerUser = (data) =>
new Promise (async (resolve, reject) => {
    try {
        const { birthday,city,email,lastName,name,password,phone } = data;
        const user = {
            birthday,
            city,
            email,
            lastName,
            name,
            password,
            phone,
            country: "Bolivia",
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userId = userCredential.user.uid;
        const result = await setDoc(doc(db, dbRef.user, userId), user);
        console.log("usuario creado", result);
        resolve(userId);
    } catch (error) {
        reject({
            error:error,
            message: '[registerToEvent,events]registerUser'
        });
    }
});

export const registerUserEvents = (data) =>
new Promise (async (resolve, reject) => {
    try {
        const {userId, eventId, codigoBarras, city, direccion, grado, modalidad, preferencia} = data;
        
        const user = doc(db, dbRef.user + '/' + userId);
        const event = {
            idEvent:eventId,
            codigoBarras:codigoBarras,
            city:city,
            direccion:direccion,
            grado:grado,
            modalidad:modalidad,
            preferenciaRefrigerio:preferencia,
            habilitado:'no'
        }
        await addDoc(collection(user, 'Events'), event);
        resolve('ok');
    } catch (error) {
        reject({
            error:error,
            message: '[registerToEvent,events]registerUserEvents'
        });
    }
});

export const registerCursosIncritos = (data) =>
new Promise (async (resolve, reject) => {
    try {
        const {userId, eventId, codigoBarras, grado, modalidad, preferencia, email} = data;
        const event = doc(db, dbRef.cursos + '/' + eventId);
        const inscrito = {
            userId:userId,
            codigoBarras:codigoBarras,
            grado:grado,
            modalidad:modalidad,
            preferenciaRefrigerio:preferencia,
            email:email,
            habilitado:"no"
        }
        await addDoc(collection(event, 'Inscritos'), inscrito);
        resolve('ok');
    } catch (error) {
        reject({
            error:error,
            message: '[registerToEvent,events]registerCursosIncritos'
        });
    }
});

export const registerData = (data) =>
new Promise (async (resolve, reject) => {
    try {
        let {birthday,city,codigoBarras,direccion,email,eventId,grado,isNewUser,lastName,modalidad,name,password,phone,preferencia,userId} = data;

        if(isNewUser){
            // registramos usuario i retornar su id en userId
            const user = await registerUser({birthday,city,email,lastName,name,password,phone})
            userId = user;
        }
        
        // registramos user Events con el id : userId
        const regEventInUser = await registerUserEvents({userId, eventId, codigoBarras, city, direccion, grado, modalidad, preferencia});
        
        if(regEventInUser === 'ok'){
            console.log('registro de evento el usuario exitoso');
        }
        // registramos inscritos  de curoso:eventId con el id : userId
        const regInscrito = await registerCursosIncritos({userId, eventId, codigoBarras, grado, modalidad, preferencia, email});
        if(regInscrito === 'ok'){
            console.log('registro de inscrito exitoso');
        }
        resolve('ok');
    } catch (error) {
        reject({
            error:error,
            message: '[registerToEvent,events]registerData'
        });
    }
});