import React,{useState} from 'react';
import activate from '../../assets/images/logos/activate.png';
import fepch from '../../assets/images/logos/fepch.png';
import pnud from '../../assets/images/logos/pnud.png';
import solides from '../../assets/images/logos/solides.png';
import usfx3 from '../../assets/images/logos/usfx3.png';
import warmilab from '../../assets/images/logos/warmilab.png';


export default function Company(props){

    return(
    
      <div  className="bg-fondo flex flex-col items-center w-full sm:items-start md:items-center p-8" >
        <h1 className="text-white text-4xl text-center leading-relaxed font-normal">Apoyado por :</h1>
        <div className="flex flex-row flex-wrap p-8 sm:p-2 md:p-8 justify-center items-center w-full sm:items-start md:items-center">
          <a
            href="https://activatebolivia.org/"
            target="_blank"
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4 bg-white" 
            rel = 'noopener noreferrer'
          >
            <img src={activate} alt="activate"/>
          </a>
          <a
            href="https://www.solydesaceleradora.org/"
            target="_blank"
            rel = 'noopener noreferrer'
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4 bg-white" 
          >
          <img src={'https://www.solydesaceleradora.org/wp-content/themes/aceleradora-solydes/assets/img/logo-header.jpg'} alt="solides"/>
          </a>
          <a
            target={'_blank'}
            href="https://www.usfx.bo/"
            rel = 'noopener noreferrer'
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4"
          >
            <img src={usfx3} alt="usfx3"/>
          </a>
          <a
            href='https://www.facebook.com/ActivateSucreBolivia'
            target={'_blank'}
            rel = 'noopener noreferrer'
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4" 
          >
            <img src={warmilab} alt="warmilab"/>
          </a>
          <a
            href='https://www.facebook.com/Federacion-de-Empresarios-Privados-de-Chuquisaca-FEPCH-101234058189721/'
            target={'_blank'}
            rel = 'noopener noreferrer'
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4" 
          >
            <img src={fepch} alt="fepch"/>
          </a>
          <a
            href='https://www.undp.org/es/'
            target={'_blank'}
            rel = 'noopener noreferrer' 
            className="max-w-1/4 sm:max-w-1/2 md:max-w-1/4 sm:m-1 md:m-4 m-4 h-1/4" 
            
          >
            <img src={pnud}alt="pnud" style={{
              maxHeight:'200px'
            }}/>  
          </a>
        </div>
      </div>
    
    );
};