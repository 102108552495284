import React, { useEffect, useState } from "react";
import {useForm} from 'react-hook-form';
import { useDispatch } from "react-redux";
import { clearExam } from "../../redux/actions/exam";
import {Redirect} from 'react-router-dom';
import {setSuggestion} from "../../utils/suggestions";

//nombre telefono correo descripcion de sugerencia
export default function Suggestions() {
  const dispatch = useDispatch();
  const { reset ,register, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);  

  const bar = document.getElementById('bar');
    if (bar) {
      bar.style.display = 'block';
    }

  const onSubmit = async (data, e) => {
      try {
          setIsLoading(true);
          const id = await setSuggestion(data);
          console.log(id);
          setIsLoading(false);
          reset();
          alert('Sugerencia enviada');
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
          
      } catch (error) {
          console.log(error);
          setIsLoading(false);
      }
  };

  useEffect(()=>{
    dispatch(clearExam());
  },[]);
  
  return (
      <>
        {redirect?<Redirect to="/" />:null}
        <div className="pt-24 px-4 flex items-center flex-col">
          <center><h1 className="text-primary text-3xl">Sugerencias</h1></center>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center w-1/2">
            <input
              name="name"
              placeholder="Nombre"
              type="text"
              className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
              {...register("name", {required:true})}        
            />
            {errors.name?.type === 'required' && "Debe ingresar su nombre"}
            <input
              name="lastname"
              placeholder="Apellido"
              type="text"
              className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
              {...register("lastname", {required:true})}        
            />
            {errors.lastname?.type === 'required' && "Debe ingresar su apellido"}
            <input
              name="email"
              placeholder="Correo electrónico"
              type="email"
              className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
              {...register("email")}        
            />
            <input
              name="phone"
              placeholder="Numero de telefono"
              type="number"
              className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
              {...register("phone")}        
            />
            <textarea 
              name="description" 
              placeholder="Descripcion de sugerencia"
              className="my-3 p-2 border-2 focus:border-0 focus:border-red-0 focus:border-red-300"
              rows="4" 
              cols="20"
              {...register("description", {required:true})}
            />
            {errors.description?.type === 'required' && "Debe ingresar la descripcion de su sugerencia"}
            {!isLoading?<button className="py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary">
                      Enviar sugerencia
                  </button>:
                  <button className="py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary" disabled>
                    <center>
                      <div className='flex'>
                        <div className="mr-1">cargando</div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce`}></div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce200`}></div>
                        <div className={`h-2.5 w-2.5 bg-current rounded-full mr-1 animate-bounce400`}></div>
                      </div>
                    </center>
                  </button>
                  }
          </form>
        </div>
      </>
    );
}
