import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db, storage } from '../../../../config/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const ModalUpdateGalery = ({ image, isOpen, closeModal, refresImages }) => {

    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (image) {
            setTitle(image.data.titulo);
            setFile(image.data.url);
        }
    }, [image]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const imageId = image.id;
    
        // Valida que se haya ingresado un título o seleccionado una imagen
        if (!title && !file) {
            alert("Por favor ingresa un título o selecciona una fotografía.");
            return;
        }
    
        try {
            // Inicializa un objeto para almacenar los campos que se actualizarán
            const updates = {};
    
            // Agrega el título a los campos a actualizar si fue modificado
            if (title && title !== image.titulo) {
                updates.titulo = title;
            }
    
            // Si se selecciona una nueva foto, súbela a Storage y agrega la URL a los campos a actualizar
            if (file && file.name) {
                const photoRef = ref(storage, `galeria/${file.name}-${Date.now()}`);
                await uploadBytes(photoRef, file);
                const photoURL = await getDownloadURL(photoRef);
                updates.url = photoURL;
            }
    
            // Verifica si hay algo que actualizar antes de llamar a Firestore
            if (Object.keys(updates).length > 0) {
                const docRef = doc(db, 'galeria', imageId);
                await updateDoc(docRef, updates);
                alert("Datos de la fotografía actualizados correctamente.");
            } else {
                alert("No se detectaron cambios para actualizar.");
            }
    
            closeModal();
    
            // Reinicia el formulario
            setTitle('');
            setFile(null);
            refresImages();
        } catch (error) {
            console.error("Error al actualizar la fotografía:", error);
            alert("Error al actualizar la fotografía.");
        }
    };
    

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-2/4 max-w-full h-9/10">
                <p className="text-xl font-bold mb-4">Editar Fotografía</p>

                <form onSubmit={handleSubmit} className="h-9/10 overflow-y-auto">
                    {/* Campo para el Título */}
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-gray-700 font-semibold mb-2">Título de la Fotografía</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={title}                          // Valor inicial del título
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            placeholder="Escribe un título"
                            required
                        />
                    </div>

                    {/* Campo para la Fotografía */}
                    <div className="mb-4">
                        <label htmlFor="photo" className="block text-gray-700 font-semibold mb-2">Selecciona una Fotografía</label>
                        <input
                            type="file"
                            id="photo"
                            name="photo"
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            accept="image/*"
                        />
                        {file && (
                            <img
                                src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                alt="Preview"
                                className="mt-4 w-full h-64 object-cover"
                            />
                        )}
                    </div>

                    {/* Botones de Cancelar y Guardar */}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="mr-2 bg-gray-400 text-white py-2 px-4 rounded"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}