import React ,{useState,useEffect,useRef} from 'react';
import Pregunta from './components/pregunta';
import Materia from './components/materia';
import Pestania from './components/pestania';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {auth} from '../../config/firebase';
import {evaluarExamen} from './../../utils/exam';
import {loadExam, saveNotas, getQuestion, clearExam, loadToExams} from './../../redux/actions/exam';
import {updateIntentos} from './../../redux/actions/user';
import { ref } from 'firebase/storage';
import Modal from '../components/Modal';
import {Redirect} from 'react-router-dom';
import {loadExams} from '../../utils/exam';
import { Link } from 'react-router-dom';

const Pestanias = (props) => {

  
  return(
    <button className="bg-fondo m-1 mx-2 p-2 border-2 text-white  -md border-withe  hover:border-primary hover:text-primary active:bg-blue-600 focus:bg-primary focus:border-primary focus:text-fondo">
  {props.name}
</button>
  )
}

export default function Examen(props){
  const dispatch = useDispatch();

  const minutos = useRef(null);
  const segundos = useRef(null);

  const {idCuenta} = useParams();

  const reduxExam = useSelector(state => state.exam);
  const user = useSelector(state => state.user);

  
  const [nombre, setNombre] = useState('');

  const [cuenta, setCuenta] = useState(user.cuenta);
  const [varContent,setVarContent] = useState([]);
  const [examData,setExamData] = useState();
  const [revision,setRevision] = useState(false);
  const [visible,setVisible] = useState(false);
  const [notas,setNotas] = useState();
  const [tipo ,setTipo] = useState('');

  const [redirect, setRedirect] = useState(false);

  const [actualizarIntentos, setActualizarIntentos] = useState(false);
  const [intentoActualizado, setIntentoActualizado] = useState(false);

  const [loading, setLoading] = useState(true);
  
  const cargarExamen = async () => {
      const dd = await loadExams(idCuenta, auth.currentUser.uid);
      dispatch(loadToExams(dd));
      setLoading(false);
  }

  const rev = ()=>{
    setRevision(true);
  }
//efecto para cargar las preguntas a exams
  useEffect(()=>{
    cargarExamen();
  },[])
//ventana de confirmacion para cerrar ventana
  useEffect(()=>{
    window.onbeforeunload = function(e) {
      e.preventDefault();
      return '¿Esta seguro que desea salir?';
    };
  },[])

  useEffect(()=>{
    window.back = ()=>{
      alert('No se puede regresar');
    };
  },[])

  const finalizarRevision = ()=>{
    let confirm = window.confirm(revision?"¿Esta seguro de finalizar la revisión?":"¿Esta seguro de finalizar el Examen?");
    if(confirm){
      //dispatch(getQuestion(auth.currentUser.uid));
      setVisible(true);
      rev();
      if(revision){
        setRedirect(true);
      }
    }
    
  }

  useEffect(()=>{
    if(examData){
      // const minut = evaluarExamen(examData)
      // console.log(minutos.current.innerText);
      // console.log(segundos.current);
      //minutos.current.innerText = `${minut.correctas + minut.incorrectas}`;
      minutos.current.innerText = '5';
      segundos.current.innerText = '0';
    }
  },[minutos,segundos,examData])

  useEffect(() => {
    console.log(idCuenta);
    console.log(user.cuenta);
    setCuenta(user.cuenta);
    user.cuenta.map(cuenta => {
      if(cuenta.id === idCuenta){
        setNombre(cuenta.nombre);
        setTipo(cuenta.tipo);
      }
      else
      {
        console.log('no existe'+cuenta.idCuenta+'- '+idCuenta);
      }
    })
    // console.log(user.cuenta)
  } ,[user])
  //hacer un cuenta regresiva
  useEffect(() => {
    let interval = setInterval(() => {
      if(minutos?.current?.innerText === '0' && segundos.current.innerText === '0'){
        clearInterval(interval);
        setVisible(true);
        rev();
      }
      else{
        
        if(segundos?.current?.innerText === '0'){
          segundos.current.innerText = '59';
          if(parseInt(minutos.current.innerText) - 1>10){
            minutos.current.innerText = '0'+parseInt(minutos.current.innerText) - 1;
          }else{
            minutos.current.innerText = parseInt(minutos.current.innerText) - 1;
          }
        }
        else{
          if(segundos?.current?.innerText){
            segundos.current.innerText = parseInt(segundos.current.innerText) - 1;
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  },[minutos,segundos]);

  useEffect(() => {
    if(revision === true){
      dispatch(saveNotas(evaluarExamen(examData),cuenta));
      setNotas(evaluarExamen(examData));
    }
  },[dispatch,revision]);

  useEffect(()=>{
    if(examData){
      for (let index = 0; index < examData.length; index++) {
        varContent[index] = 'none';
      }
      varContent[0] = 'flex';
    }
  },[examData]);

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'none';
  }

  let contador = -1;

  function changeContent(id ){
    var auxVector =[];
    for (let i = 0; i < varContent.length; i++) {
      auxVector[i] = 'none';
    }
    auxVector[id] = 'flex';

    setVarContent(auxVector);
  }


  useEffect(()=>{
    if(!loading){
      console.log("reduxExam: ",reduxExam.exams);
      console.log("idcuenta: ",idCuenta);
      if (reduxExam.exams) {
        setExamData(reduxExam.exams);
        setLoading(false);
      //   if(reduxExam.exam[0].idCuenta===idCuenta){
      //     setExamData(reduxExam.exam);
      //   } else{
      //     const copia = reduxExam.exams.filter(e=>e.idCuenta === `${idCuenta}`);
      //     console.log("cantoda de de la copia: ",copia.length);
      //     if(copia.length>0){
      //       setActualizarIntentos(true);
      //     }
      //     dispatch(loadExam(copia));
      //   }
      // } else {
      //   const copia = reduxExam.exams.filter(e=>e.idCuenta === `${idCuenta}`);
      //   console.log("cantoda de de la copia: ",copia.length);
      //   if(copia.length>0){
      //     setActualizarIntentos(true);
      //   }
      //   dispatch(loadExam(copia));
      //   console.log(copia,"copia");
      }
    }
    

  },[reduxExam, idCuenta, loading]);

  useEffect(()=>{
    window.addEventListener("beforeunload", (e)=>{
      e.preventDefault();
      e.returnValue = "hola";
    });

    return ()=>{
      window.removeEventListener("beforeunload", (e)=>{
        e.preventDefault();
        e.returnValue = "hola";
      });
    }
  });

  useEffect(()=>{
    if(actualizarIntentos){
      if(intentoActualizado){
        return;
      } else{
        setIntentoActualizado(true);
        dispatch(updateIntentos(idCuenta));
        console.log("actualizando intentos una sola vez");
      }
      
    }
  },[actualizarIntentos]);

    return(
      <>
      {redirect?<Redirect to="/suggestions" />:null}
        {
          loading?<h1>cargando</h1> :(
            <>
            <Modal visible={visible} title={'Tiempo Agotado'} onClose={()=>{setVisible(false);}}>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center text-2xl">Se te termino el tiempo </p>
            <p className="text-center text-2xl">notas</p>
            {notas?(
              <>
                <p className="text-center text-2xl">Correctas:{notas.correctas}</p>
                <p className="text-center text-2xl">Incorrectas:{notas.incorrectas}</p>
                <p className="text-center text-2xl">Nota Final</p>
                <p className={`text-center text-4xl ${notas.calificacion<=49?"text-red-500":notas.calificacion<=70?"text-yellow-500":"text-green-500"}`}>
                  {notas.calificacion.toFixed(2)}/100
                  </p>
              </>):
            null}
            <button className=" mt-4 bg-primary rounded m-1 mx-2 p-2 border-2 text-white  -md border-withe hover:bg-fondo hover:border-fondo active:bg-blue-600 focus:bg-primary focus:border-primary focus:text-fondo" onClick={()=>{setVisible(false);}}> 
              Ir a revisión
            </button>
          </div>
        </Modal>
        <div className='flex flex-col justify-center items-between bg-black w-full py-5 text-white absolute'>
          <div className='flex flex-row justify-around items-center'>
            <div></div>
            <div className='text-white text-2xl'>
              <h3>{tipo === 'PPrac'?'Prueba Práctica': 'Prueba Final' }</h3></div>
              {
                revision&&
                <Link to='/print'
                  className='bg-primary p-2 rounded'
                >
                  Imprimir Prueba
                </Link>
              }
            <button className='bg-primary p-2 rounded' onClick={()=>finalizarRevision()}>{revision?'Finalizar revisión': 'Finalizar Examen'}</button>
          </div>
        </div>
        <div className='flex flex-row w-1/2 h-1/2 w-full bg-gray-200'>
          <div className='bg-fondo w-1/4 flex flex-col justify-start items-center py-12'>
            <h1 className='text-primary w-2/3 text-center text-xl mt-12'>{nombre}</h1>
  
            <h3
              className='text-white w-2/3 text-center text-xl mt-8'
            >Tiempo restante:</h3>
            <div className='flex flex-row justify-center justify-center mt-2'>
              <div className='flex flex-col justify-start items-start' >
                <p ref={minutos} className='text-white text-center text-6xl'>60 :</p>
                <p className='text-primary text-start'>minutos</p>
              </div>
              <p className='text-white self-start text-6xl'>:</p>
              <div className='flex flex-col justify-center items-center' >
                <p ref={segundos} className='text-white text-center text-6xl'>12</p>
                <p className='text-primary text-center'>segundos</p>
              </div>
            </div>
            <div className='mb-8 mt-8 items-self-start text-center flex flex-col w-full'>
              {//inicio de pestania
              examData?
                examData.map((item,index)=>{
                  return(
                    <Pestania 
                      key={index}
                      nombre={item.materia}  
                      id={index} 
                      onPress={()=>{changeContent(index)}}
                      active={varContent[index]==='flex'?true:false}
                    />
                  )
                }):null
                
              }
            </div>
          </div>
          {/**inicio de  materias   activo flex desactivado none*/}
              {
                examData?
                examData.map((item,index)=>{
                  console.log(varContent[contador]);
                  contador++;
  
                  return(
                    <Materia 
                      key={index}
                      display={`${varContent[contador]}`} 
                      data={item}
                      revision={revision}
                      siguenteMateria = {()=>{changeContent(index+1)}}
                      anteriorMateria = {()=>{changeContent(index-1)}}
                      cantidadMateria = {examData.length}
                      index = {index}
                    />
                    
                  );
                }):null
              }
          {/**fin de  materias  */}
        </div>
            </>
          )
        }
        
      </>
    );
};