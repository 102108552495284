import React, { useState } from 'react'
import { ModalUpdateExam } from '../update-modal/ModalUpdateExam';
import { ModalSelectExam } from '../select-modal/ModalSelectExam';

export const ExamCard = ({ id, fechaInicio, fechaFin, nombre, examen, refresExams }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSelectOpen, setIsModalSelectOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openModalSelect = () => setIsModalSelectOpen(true);
    const closeModalSelect = () => setIsModalSelectOpen(false);

    return (
        <>
            {/* Modal para actualizar examen */}
            <ModalUpdateExam
                isOpen={isModalOpen}
                closeModal={closeModal}
                examen={examen}
                idExamen={id}
                refresExams={refresExams}
            />

            {/* Modal para seleccionar examen */}
            <ModalSelectExam
                isOpen={isModalSelectOpen}
                closeModal={closeModalSelect}
                examen={examen}
            />

            <div className="bg-basic border-2 border-b-green-500 flex flex-col items-stretch rounded-lg">
                <div className="bg-accent py-2 w-full rounded-t-lg border-2 border-b-green-500">
                    <h3 className="text-center text-white font-bold  self-center px-8 py-2 underline decoration-solid">{nombre}</h3>
                </div>
                <div className="flex flex-row justify-between p-2  sm:p-2 md:p-4">
                    <div className="w-full">
                        <p className="text-start text-black text-center font-semibold">Id: <span className="text-gray-600 font-normal">{id}</span></p>
                        <p className="text-start text-black text-center font-semibold">Del: <span className="text-gray-600 font-normal">{fechaInicio}</span> - Al: <span className="text-gray-600 font-normal">{fechaFin}</span></p>
                    </div>
                </div>

                {/* Botones para abrir modales */}
                <div className='flex w-full mt-4 justify-center'>
                    <button className='bg-primary text-white p-2 rounded-full px-4 ml-4 cursor-pointer' onClick={openModal}>
                        Editar
                    </button>
                    <button className='bg-primary text-white p-2 rounded-full px-4 ml-4 cursor-pointer' onClick={openModalSelect}>
                        Seleccionar
                    </button>
                </div>
            </div>
        </>
    )
}

