import React, { useState, useEffect } from 'react';
import Banner from '../components/banner';
import Simulacrum from '../components/simulacrum';
import ContecParallax from '../components/parallax';
import Comments from '../components/comments';
import NextQuestion from '../components/nextQuestion';
import Footer from '../components/footer';
import Company from '../components/company';
import { useDispatch, useSelector } from 'react-redux';
import { getDataPage } from '../../redux/actions/dataPage';
import { _fetchUsers } from '../../redux/actions/user';
import { getAllMaterias } from '../counts/utils';


export default function Home() {

  const data = useSelector(state => state.dataPage);

  const [examen, setExamen] = useState('Examen Proximo...');
  const [titulo, setTitulo] = useState('Escuela de Jueces');
  const [idEvento, setIdEvento] = useState('');
  const [tipo, setTipo] = useState();
  const [hora, setHora] = useState('3/30/2022 9:30 AM')
  const [datos, setDatos] = useState([]);
  const [clients, setClients] = useState([]);
  const [preguntas, setPreguntas] = useState(0)


  const dispatch = useDispatch();

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'block';
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const { users } = await _fetchUsers();
      setClients(users);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    dispatch(getDataPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.dataPage.length > 0) {
      setExamen("Próximo Examen:");
      setDatos(data.dataPage[0]);
      setTitulo(data.dataPage[0].tituloBanner);
      setIdEvento(data.dataPage[0].idEvento);
      setTipo(data.dataPage[0].tipo);
      setHora(data.dataPage[0].fechaExamen);

    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataMaterias = await getAllMaterias();
        const totalPreguntas = dataMaterias.reduce((total, materia) => {
          return total + (materia.cantidadPreguntas || 0);
        }, 0);
        setPreguntas(totalPreguntas)
      } catch (error) {
        console.error('Error fetching materias: ', error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <Banner examen={examen} titulo={titulo} hora={hora} data={datos.examenCpmpleto} idEvento={idEvento} tipo={tipo} />
      <Simulacrum datos={datos} clients={clients.length} totalPreguntas={preguntas} />
      <ContecParallax />
      <Comments tema={'claro'} />
      <NextQuestion />
      <Company />
      <Footer mensaje={true} />
    </>
  );
};