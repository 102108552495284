import {db} from '../../config/firebase'
import { collection, getDocs } from "firebase/firestore";
//import { doc, getDoc } from "firebase/firestore";
import types from '../types/dataPage';
// import dbRef from '../../config/dbRef';

// const getDataPageRequest = payload => ({
//     type: types.GET_DATA_REQUEST,
//     payload:payload,
// });

const getDataPageSuccess = payload => ({
    type:types.GET_DATA_SUCCESS,
    payload:payload,
});

const getDataPageFailure = payload => ({
    type:types.GET_DATA_FAILURE,
    payload:payload,
});

export const getDataPage = (payload) => {
    return async (dispatch) => {
        try{
        
            getDocs(collection(db,'dataPage'))
            .then((response)=>{
               let data = {};
                response.forEach((doc)=>{
                    data = doc.data();
                });

                return data;
            }).then( data =>
                {dispatch(getDataPageSuccess(data))
                console.log(data)}
            )
            .catch(error =>
                dispatch(getDataPageFailure(error))
            )
            
        } catch(error){
            console.log("[Actions][user][getUser]:", error);
        }
    };
}