// collection Cursos
//idcurso:cpTO2Hbr2kB6n6q1g1eq

//AL INGRESAR CORREO VERIFICAR SI EXISTE EN _USERS  
// Y VERIFICAR CORREO EN EL EVENTO

//datos
// nombre ,correo, 
import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import Header from "../components/headerPage";
import { FaFacebook, FaInstagram  } from "react-icons/fa";
import {IoIosGlobe} from "react-icons/io";
// import 

import  Comments  from "../components/comments";
import Footer from "../components/footer";
import Title from "../components/title";
import image from "../../assets/images/team.png";
import redes from "../../assets/images/redes.png";
import banner from "../../assets/images/banner.png";
import banner2 from "./primer.png";


//crear componente funcinal
export default function Team() {
    let { idEvent } = useParams();
    const [eventId, setEventId] = useState("");

    useEffect(() => {
        //ruta:localhost:3000/events/cpTO2Hbr2kB6n6q1g1eq
        //obtener el idEvent con useParamos 
        setEventId(idEvent);
    }, [idEvent]);
    return (
        <>
        <Header titulo={"El divorcio en el siglo XXI"} nombre={"10 de junio a horas 19:00 "} mensaje={"El divorcio en el siglo XXI"} image={image} />
        <div className="bg-white w-full py-20 px-32 sm:py-5 sm:px-5  md:py-20 md:px-12">
            <div className="flex flex-row justify-around sm:flex-col md:flex-row">
                <div className="w-1/2 pr-32 sm:w-full md:w-1/2 md:pr-8 sm:pr-3 pl-3">
                    <h2 className="my-5 font-bold text-3xl pr-10 text-center text-primary">
                    EL DIVORCIO EN EL SIGLO XXI
                    </h2>
                    <p className="text-justify">
                    Este es un evento presencial para la ciudad de Sucre y virtual para el resto de Bolivia, dirigido a:
                    </p>
                    <ul className=" ml-12">
                        <li type="square" className="text-justify">
                          Abogados en el ejercicio libre de la profesión.
                        </li>
                        <li type="square" className="text-justify">
                          Especialistas en derecho de familia.   
                        </li>
                        <li type="square" className="text-justify">
                          Funcionarios de salas de Familia de los Tribunales de Justicia de los diferentes departamentos.
                        </li>
                        <li type="square" className="text-justify">
                          Población en general interesada en el tema.
                        </li>
                    </ul>
                    <p className="text-justify"><br/>
                    Se realizará en instalaciones de CAINCO calle España #67 (ciudad de Sucre)<br/><br/>
                    <b className="text-primary">El pago de su inscripción cubre:</b>
                    </p>
                    <ul className=" ml-12">
                        <li type="square" className="text-justify">
                          Acceso al evento.
                        </li>
                        <li type="square" className="text-justify">
                          Refrigerio (modalidad presencial).
                        </li>
                        <li type="square" className="text-justify">
                          Envió de certificados por Courier (modalidad virtual).
                        </li>
                        <li type="square" className="text-justify">
                          Material.
                        </li>
                    </ul>
                    <p className="text-justify">
                    <br/>
                   El Certificado del evento estará firmado por el expositor, representante legal Apriori, Fundación Actívate, Federación de Empresarios Privados de Chuquisaca, Universidad San Francisco Xavier de Chuquisaca, Gobierno Autónomo Municipal de Sucre.
                    </p>
                    <p className="text-justify">
                    <br/>
                    <b className="text-primary">Nota.-</b>
                    Al finalizar el evento, el participante tendrá un lapso de 24 horas para realizar una prueba de conocimientos sobre el tema desarrollado.
                    </p>
                    
                    {/* <p className="text-justify">
                    Se realizará en instalaciones de CAINCO calle España #67 (ciudad de Sucre) 
                    </p>
                    <p className="text-justify">
                    El pago de su inscripción cubre:
                    </p>
                    <p className="text-justify">
                    • Acceso al evento.
                    </p>
                    <p className="text-justify">
                    • Refrigerio (modalidad presencial).
                    </p>
                    <p className="text-justify">
                    •  Envió de certificados por Courier (modalidad virtual).
                    </p>
                    <p className="text-justify">
                    • Material.
                    </p>
                    <p className="text-justify">
                    • Certificado (Firmado por el expositor, representante legal Apriori, Fundación Actívate, Federación de Empresarios Privados de Chuquisaca, Universidad San Francisco Xavier de Chuquisaca, Gobierno Autónomo Municipal de Sucre).
                    </p> */}

                    {/* <Link >as</Link> */}
                    <br/>
                    <Link to={"/register-to-event/"+eventId} className="bg-primary text-white py-2 px-4 rounded-full mt-5">Inscribirse</Link>
                </div>
                <div className="bg-cover bg-center w-1/4 h-98 bg-no-repeat sm:w-full md:w-1/2 mt-0 md:mt-0 sm:mt-8 sm:bg-contain md:bg-contain sm:h-80 md:h-98" style={{backgroundImage:`url(${banner2})`}}/>
            </div>
            {/* <div  className="flex flex-row justify-between mt-14" >
                <div>
                    <h1 className="text-2xl"><span  className="text-4xl text-primary mb-8">01.</span> mision</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
                <div>
                    <h1 className="text-2xl"><span className="text-4xl text-primary mb-8">02.</span> vision</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
                <div>
                    <h1 className="text-2xl"><span className="text-4xl text-primary mb-8">03.</span> enfoque</h1>
                    <p className="mt-3">la mision de apriori es la mision de apriori cumpliendo la mision que todos tenemos" osea aqui la mision"</p>
                </div>
            </div> */}
        </div>{/*
        <div className="flex flex-row justify-around w-full p-8" style={{backgroundImage:`url(${redes})`,backgroundPosition:'bottom'}}>
            <div className="flex flex-col items-center">
                <FaFacebook className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">248</h1>
                <p className="text-white">Facebook</p>
            </div>
            <div className="flex flex-col items-center">
                <FaInstagram className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">33</h1>
                <p className="text-white">Instagram</p>
            </div>
            <div className="flex flex-col items-center text-primary">
                <IoIosGlobe className="text-3xl text-primary"/>
                <h1 className="text-5xl mt-2 text-white">157</h1>
                <p className="text-white">Visitas Web</p>
            </div>
        </div>*/}
        <Title titulo="EL DIVORCIO EN EL SIGLO XXI" mensaje=""/>
        
        <Footer/>
        
        </>
    );
}
