import React ,{ Fragment, useState, useEffect, useRef }from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {/* formatPhoneNumber, formatPhoneNumberIntl,*/ isValidPhoneNumber } from 'react-phone-number-input'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {db} from '../../config/firebase';
import dbRef from '../../config/dbRef';
import { collection, query, where, getDocs  } from "firebase/firestore";
//import {auth} from '../../config/firebase';

import { useDispatch, useSelector } from 'react-redux';
import { registerEmailPassSignUp, startClearError, startClearSuccess } from '../../redux/actions/auth';
//import { getUser } from '../../redux/actions/user';
//import { startGoogleAuth, startLogOut } from '../../redux/actions/auth';
import Modal from '../components/Modal';
import { set } from 'react-ga';

export default function Signup() {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch();

  const reduxAuth = useSelector(state => state.auth);
  const cart = useSelector(state => state.cart);

  const [phone , setPhone] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [password, setPassword] = useState();
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPassword2, setErrorPassword2] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [ciudad , setCiudad] = useState();

  const [primerPanel, setPrimerPanel] = useState(true);
  const [segundoPanel, setSegundoPanel] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [code, setCode] = useState(true);
  let codeError = useRef(null);
  //const [extencion,setExtencion] = useState();
  //const [errorExtencion,setErrorExtencion] = useState(false);

  const closeErrorModal = () => {
    setErrorModal(false);
    dispatch(startClearError());
  }

  const closeSuccessModal = () => {
    setSuccessModal(false);
    dispatch(startClearSuccess());
    setIsAuthenticated(true);
  }

  const submit = () =>{
    //buscar al usuario en la base de datos con el codigo de referencia
    
    dispatch(registerEmailPassSignUp(userData));
  }

  const chancePanel = () =>{

    setPrimerPanel(!primerPanel);
    setSegundoPanel(!segundoPanel);
  }
//datos del usuario celular ciudad y pais 
  const onSubmit = (data, e) => {
    if(phone){
      if(isValidPhoneNumber(phone)){
        data.phone = phone;
        setErrorPhone(false);
      } else{
        setErrorPhone(true);
        return
      }
    }else {
      setErrorPhone(true);
      return
    }
    if(code===false){
      return
    }

    let day=startDate.getDate();
    let month=startDate.getMonth()+1;
    const year=startDate.getFullYear();
    if(day<10){
      day=`0${day}`;
    }
    if(month<10){
      month=`0${month}`;
    }

    data.birthday = `${day}/${month}/${year}`;
    //validacion de la extencion de carnet
    /*
    if(extencion){
      if(extencion!=='0'){
        data.dniE = extencion;
        setErrorExtencion(false);
      } else{
        setErrorExtencion(true);
        return
      }
    }else {
      setErrorExtencion(true);
      return
    }*/
    // dispatch(startSignUp(data));
    if(data.password.length<8){
      setErrorPassword('la contraseña debe tener al menos 8 caracteres');
      return
    }
    if(data.password !== data.password2){
      setErrorPassword2('Las contraseñas no coinciden');
      return
    }
    setErrorPassword('');
    setErrorPassword2('');
    console.log(data);    
    setUserData(data);
    setModal(true);
  };
  const verifyCode =async (e) => {
    
    if(e.length===4){
      codeError.current.innerText   = 'comprobando código....';
      const querySnapshot = await getDocs(
        query(collection(db, dbRef.user),where("code", "==", e))
      );
      if(querySnapshot.size>0){
        codeError.current.classList.remove('text-red-500');
        codeError.current.classList.add('text-green-500');
        codeError.current.innerText   = `Código correcto, Codigo de : ${querySnapshot.docs[0].data().name}`;
        setCode(true);
      }else{
        codeError.current.classList.remove('text-green-500');
        codeError.current.classList.add('text-red-500');
        codeError.current.innerText   = 'Código incorrecto, verifique el código o borre el campo';
        setCode(false);

      }
     
    }else{
      codeError.current.innerText   = 'El código debe tener 4 caracteres';
      setCode(false);

    }

    if(e.length===0){
      codeError.current.classList.remove('text-green-500');
      codeError.current.classList.remove('text-red-500');
      codeError.current.classList.add('text-gray-500');
      codeError.current.innerText   = '* Este campo no es obligatorio';
      setCode(true);
    }
    
  }

  const setPhones = (e)=> {
    setPhone(e);
  }
  const validPass = (e,p)=>{
    //console.log(e.target.value,p);
    if(p==='p1'){
      if(e.target.value.length>=8){
        console.log('entro: ',e.target.value.length);
        setErrorPassword('');
        setPassword(e.target.value);
      }
      else{
        setErrorPassword('La contraseña debe tener al menos 8 caracteres');
      }
    }
    else{
      if(e.target.value===password){
        setErrorPassword2('');
      }
      else{
        setErrorPassword2('Las contraseñas no coinciden');
      }
    }

  }

  useEffect(()=>{
    if(reduxAuth.errorRegister){
      console.log('error al crear la cuenta: ')
      console.log(reduxAuth.errorRegister);
      setModal(false);
      setErrorModal(true);
    }
    setButtonDisabled(reduxAuth.loadingRegister);

    if(reduxAuth.success){
      setModal(false);
      setSuccessModal(true);
    }

    if(reduxAuth){
      console.log('estado de redux');
      console.log(reduxAuth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reduxAuth]);
  
  return (
    <Fragment>
      {isAuthenticated?
        cart.cart.id?
        <Redirect to="/convocatoria" />
        :
        <Redirect to="/profile" />
        :null}
      <div className="w-full flex flex-col justify-center items-center h-5/6 mt-16">
      <form onSubmit={handleSubmit(onSubmit)} className="w-1/3 sm:w-full md:w-1/3 mx-0 sm:mx-4 md:mx-0 border-white rounded mt-8">
          <div className='bg-white rounded shadow-2xl bg-gray-100'>
          <h1 className=" text-red-500 text-center pt-5 text-2xl">Crear cuenta{cart.cart.id?'':''}</h1> 
          <div className=' flex flex-col items-center p-5 w-full rounded ease-in duration-300' style={{display:`${primerPanel?'flex':'none'}`,transition:'1s'}}>
            <input
                name="name"
                placeholder="Nombre"
                className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
                {...register("name",{required:true})}
            />
            <p className="text-xs text-red-500">{errors.name?.type === 'required' && "*Debe introducir nombre"}</p>
            <input
                name="lastName"
                placeholder="Apellido"
                className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
                {...register("lastName",{required:true})}
            />  
            <p className="text-xs text-red-500">{errors.lastName?.type === 'required' && "*Debe introducir su nombre completo"}</p>

            
            {/* <input
              name="country"
              placeholder="Pais"
              className="bg-green-50 m-1 "
              {...register("country",{required:true})}
            /> 
            <input
                name="city"
                placeholder="Ciudad"
                className="bg-green-50 m-1 "
                {...register("city",{required:true})}
            />
            fecha de nacimiento:*/}
            <select 
              {...register("city",{required:true})} 
              name='city'
              className="p-3 my-1 bg-gray-200 w-full mt-3 rounded-lg"
              >
                <option value="0">Ciudad de residencia</option>
                <option value="chuquisaca">Chuquisaca</option>
                <option value="laPaz">La Paz</option>
                <option value="cochabamba">Cochabamba</option>
                <option value="oruro">Oruro</option>
                <option value="potosi">Potosi</option>
                <option value="tarija">Tarija</option>
                <option value="pando">Pando</option>
                <option value="beni">Beni</option>
                <option value="santaCruz">Santa Cruz</option>
            </select>
            {/*
            <select
              value={ciudad}
              className="p-3 my-1 bg-gray-200 w-full mt-3 rounded-lg"
              onChange={e => setCiudad(e.target.value)}
              name='ciudad' >
                <option value="0">Ciudad de residencia</option>
                <option value="chuquisaca">Chuquisaca</option>
                <option value="laPaz">La Paz</option>
                <option value="cochabamba">Cochabamba</option>
                <option value="oruro">Oruro</option>
                <option value="potosi">Potosi</option>
                <option value="tarija">Tarija</option>
                <option value="sucre">Sucre</option>
                <option value="beni">Beni</option>
                <option value="santaCruz">Santa Cruz</option>
            </select>
          */}
            <p className='w-full mt-3'>Fecha de nacimiento:</p>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="p-3 my-1 bg-gray-200 w-full"
            />
            <div className="w-1/3 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"
              onClick={()=>chancePanel()}>
                  Siguiente

              </div>
              <p className="text-sm text-gray-1000">Tus datos estan protegidos por nuestras</p><a className="text-sm text-blue-500" href="https://www.google.com/">Politicas de privacidad</a>
          </div>
          <div className=' flex flex-col justify-center p-5 w-full' style={{display:`${segundoPanel?'flex':'none'}`}} >

            {/*<div className='flex flex-row justify-center '>

            {/* <div className='flex flex-row justify-center '>

              <input
                name="DNI"
                placeholder="Carnet de identidad"
                className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
                {...register("dni",{required:true})}
              />
              <select 
                onChange={a=>setCiudad(a)} 
                name='DNIE' 
                className="p-3 my-1 bg-gray-200 w-1/4 mt-3 rounded-lg" >
                  <option value="0">Exp.</option>
                  <option value="CH">CH</option>
                  <option value="LP">LP</option>
                  <option value="CB">CB</option>
                  <option value="OR">OR</option>
                  <option value="PT">PT</option>
                  <option value="TJ">TJ</option>
                  <option value="SC">SC</option>
                  <option value="BE">BE</option>
                  <option value="PD">PD</option>
              </select>
            </div>
          */}
          <PhoneInput
          className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
            placeholder="Numero de celular"
            onChange={setPhones}
            defaultCountry="BO"
            value={phone}
            error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
          />
            <p className="text-xs text-red-500">{
              phone === undefined ||phone === ''?'':phone && isValidPhoneNumber(phone) ? '' : '*Numero invalido'}
            </p>
            
          <input
              name="email"
              placeholder="Correo Electronico"
              className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500"
              type="email"
              {...register("email",{required:true})}
          /><p className="text-xs text-red-500"> {errors.email?.type === 'required' && "*Debe introducir correo Electronico"}</p>
          <input
              name="password"
              type="password"
              placeholder="Contraseña"
              onChangeCapture={(e)=>validPass(e,'p1')}
              className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500"
              {...register("password", {required:true})}
          />
          {errorPassword && <p className="bg-red-600">{errorPassword}</p>}
          <input
              name="password2"
              type="password"
              placeholder="Repetir Contraseña"
              className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
              onChangeCapture={(e)=>validPass(e,'p2')}
              {...register("password2", {required:true})}
          />
           {errorPassword2 && <p className="bg-red-600">{errorPassword2}</p>}
           <p className="text-xs text-red-500">{errors.password?.type === 'required' && "Contraseña necesaria"}</p>
           <input
              name="code"
              placeholder="Codigo de afiliado"
              maxLength={4}
              onChangeCapture={(e)=>verifyCode(e.target.value)}
              className="bg-gray-100 border-t-3 m-1 border-b-2 p-3 pb-0 focus:border-0 focus:border-t-3 focus:border-red-500 w-full"
              {...register("code")}
          />
          <p className="text-xs text-gray-500" ref={codeError}>*Este campo no es necesarioa</p>


           <div className='flex flex-row justify-center '>
            <div className="min-w-1/2 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-primary bg-transparent hover:bg-primary hover:text-white mt-3"
                onClick={()=>chancePanel()}>
                    Atras
            </div>
            <button className="min-w-1/2 ml-3 py-2 px-4 border-2 border-primary font-semibold rounded-lg shadow-md text-white bg-primary hover:bg-transparent hover:text-primary mt-3"
              onClick={handleSubmit(onSubmit)}
            >
                Registrar
            </button>
           </div>
           <div
            className="flex flex-col text-center justify-center mt-3"
           >
           <p className="text-sm text-gray-1000 w-full°">Tus datos estan protegidos por nuestras</p><a className="text-sm text-blue-500" href="https://www.google.com/">Politicas de privacidad</a>
           </div>
          </div>
          
          
          
          
          
          {/* <p> {errorPhone? "Debe introducir un numero valido":''}</p>
          {//<p> {//errors.dni?.type === 'required' && "Debe introducir un numero valido"}</p>
          //<p> {//errorExtencion? "Debe introducir una extencion valida":''}</p>
          }

          <p>{errors.password?.type === 'required' && "Contraseña necesaria"}</p>
          <p>{errors.password2?.type === 'required' && "Contraseña necesaria"}</p>
          <p>{errors.country?.type === 'required' && "Pais requerido"}</p>
          <p>{errors.city?.type === 'required' && "Ciudad requerido"}</p> */}
          </div>
      </form>
      <Modal 
      visible={modal}
      title="Verifica tus datos"
      onClose={()=>setModal(false)}
      >
        <div className="mt-2">
          <p className="text-base text-red-500 font-bold">
            Nombre: <span className="text-gray-700 font-normal"> {userData.name}</span>
          </p>
          <p className="text-base text-red-500 font-bold">

            Apellido: <span className="text-gray-700 font-normal"> {userData.lastName}</span>
          </p>
          <p className="text-base text-red-500 font-bold">
            Ciudad: <span className="text-gray-700 fontnormalr"> {userData.ciudad}</span> 
          </p>
          <p className="text-base text-red-500 font-bold">
            Fecha de nacimiento: <span className="text-gray-700 font-normal"> {userData.birthday}</span>
          </p>
          <p className="text-base text-red-500 font-bold">
            Numero de celular: <span className="text-gray-700 font-normal"> {userData.phone}</span>
          </p>
          <p className="text-base text-red-500 font-bold">
            Correo Electronico: <span className="text-gray-700 font-normal"> {userData.email}</span> 
          </p>
          {
            userData.code && 
            <p className="text-base text-red-500 font-bold">
              Codigo de afiliado: <span className="text-gray-700 font-normal"> {userData.code}</span>
            </p>
          }
        </div>
        <div className="mt-4 flex justify-center">
        <button
          type="button"
          className="inline-flex justify-center px-4 mx-2 py-2 text-sm font-medium text-red-100 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={()=>setModal(false)}
          
        >
          Cancelar
        </button>
        <button
          type="button"
          className="inline-flex justify-center mx-2 px-4 py-2 text-sm font-medium text-green-1000 bg-green-400 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={()=>submit()}
          disabled={buttonDisabled}
        >
          {!buttonDisabled && <span className="ml-2">Continuar</span>}
          {buttonDisabled && <span className="ml-2">Cargando...</span>}
        </button>

        </div>
      </Modal>
      <Modal
      visible={errorModal}
      title="Ocurrio un error"
      onClose={()=>closeErrorModal()}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-1000">
            Error al registrar, intentelo nuevamente
          </p>
        </div>
        <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-100 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={()=>closeErrorModal()}
        >
          Aceptar
        </button>
        </div>
      </Modal>
      <Modal
      visible={successModal}
      title="Registro exitoso"
      onClose={()=>closeSuccessModal()}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-1000">
            Datos registrados correctamente
          </p>
        </div>
        <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-green-1000 bg-green-400 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={()=>closeSuccessModal()}
        >
          Aceptar
        </button>
        </div>
      </Modal>        
      
      </div>
  </Fragment>
    );
}
