import React ,{useState,useEffect,useRef} from 'react';
import { RiSendPlane2Fill} from "react-icons/ri";
import Pregunta from './pregunta';
import {BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

const Marker=({texto, select})=>{
  
  const [state,setState] = useState(false);
  const [text,setText] = useState('');
  useEffect(()=>{
    setState(select);
    setText(texto);
  },[select,texto])
  return(
    <div className= {`bg-fondo m-1 mx-2 px-4 p-2 border-2 text-red-600  -md border-withe  hover:border-primary hover:text-primary ${state?
      'bg-primary border-primary text-fondo':
      ''
    } `}>
      <p
        className={`text-center font-bold  ${state? 'text-fondo':'text-primary'}`}
      >{text}</p>
      
    </div>
  );
}

export default function Materia  (props) {
  const {revision, siguenteMateria, anteriorMateria, cantidadMateria, index} = props;

  //const data = props.data.preguntas;
  const [revision_,setRevision_] = useState(revision);
  const [data,setData] = useState(props.data.preguntas);
  useEffect(()=>{
    setRevision_(revision);
  },[revision])

  useEffect(()=>{
    setData(props.data.preguntas);
  },[props.data])
  return(
    <>
        <div className='w-3/4 flex flex-col justify-start items-center overflow-scroll h-screen pt-20 bg-gray-200 px-6 pb-24' style={{overflowX:'hidden',
        display:`${props.display}`,
      }}>
         
          <div className='flex flex-row justify-center items-center absolute items-self-end justify-self-end mt-full bg-gray-400 p-1 rounded drop-shadow-lg' style={{marginTop:'40%'}}>
          {
           data.map((item,index)=>{
             return(
                <Marker texto={index+1} select={item.marcado?true:false}/>
             )
            })
         }
          </div>
          {
            data.map((item,index)=>{
              return(
                <Pregunta 
                  marcado={item.marcado?item.marcado:null} 
                  idPregunta={item.id}
                  materia={item.materia}
                  key={index}
                  index={index+1}
                  pregunta={item.pregunta}
                  respuestas={item.opciones}
                  correct={item.opcionCorrecta}
                  revision={revision_}
                  explicacionCorrecta={item.explicacionCorrecta}
                />
                  
              )
            })
          }
          <div className='flex flex-row justify-center' >
            {
              index!==0?
                <button className='flex flex-row items-center ali bg-primary text-white p-2 rounded-lg mt-5 mx-4' onClick={()=>{anteriorMateria()}}>
                  <BsFillCaretLeftFill className='text-white'/>
                  Anterior Materia
                </button>
              :null}
            {
              index!==cantidadMateria-1?
                <button className='flex flex-row items-center bg-primary text-white p-2 rounded-lg mt-5 mx-4' onClick={()=>{siguenteMateria()}}>
                  Siguiente Materia
                  <BsFillCaretRightFill className='text-white'/>
                </button>
                :
                null

            }
            
          </div>
          
        </div>
    </>
    );
};