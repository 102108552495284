import React, { useState } from 'react'
import { ModalUpdateGalery } from '../update-galery-modal/ModalUpdateGalery';
import { deleteObject, ref } from 'firebase/storage';
import { db, storage } from '../../../../config/firebase';
import { deleteDoc, doc } from 'firebase/firestore';

export const Galery = ({ images, refresImages }) => {

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);

    const openDeleteModal = (image) => {
        setSelectedImage(image);
        setDeleteModalOpen(true);
    };

    const openEditModal = (image) => {
        setSelectedImage(image);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setSelectedImage(null);
    };

    const handleSave = (updatedImage) => {
        //onUpdateImage(updatedImage); // Actualizar la imagen en la galería principal
        closeEditModal();
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedImage(null);
    };

    const handleDelete = async() => {
        try {
            // Referencia de la imagen en Firebase Storage
            const imageRef = ref(storage, selectedImage.data.url);
            
            // Eliminar la imagen de Firebase Storage
            await deleteObject(imageRef);
            
            // Eliminar el documento de Firestore
            const docRef = doc(db, 'galeria', selectedImage.id);
            await deleteDoc(docRef);
    
            alert("Imagen eliminada correctamente.");
            closeDeleteModal();
            refresImages();
        } catch (error) {
            console.error("Error al eliminar la imagen:", error);
            alert("Error al eliminar la imagen.");
        }
        closeDeleteModal();
    };


    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                {images.map((img, index) => (
                    <div key={index} className="flex flex-col items-center bg-white rounded-lg shadow-md overflow-hidden">
                        <img src={img.data.url} alt={img.data.alt} className="w-full h-48 object-cover" />
                        <p className="text-lg font-semibold text-center py-2">{img.data.titulo}</p>
                        <div className="flex w-full mt-4 justify-center">
                            <button
                                className="bg-primary text-white p-2 rounded-full px-4 ml-4 cursor-pointer"
                                onClick={() => openEditModal(img)}
                            >
                                Editar
                            </button>
                            <button
                                className="bg-primary text-white p-2 rounded-full px-4 ml-4 cursor-pointer"
                                onClick={() => openDeleteModal(img)}
                            >
                                Eliminar
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {isDeleteModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg w-11/12 max-w-sm text-center">
                        <h2 className="text-xl font-bold mb-4">¿Está seguro que quiere eliminar esta imagen?</h2>
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={handleDelete}
                                className="bg-red-500 text-white py-2 px-4 rounded mr-2 hover:bg-red-600"
                            >
                                Sí
                            </button>
                            <button
                                onClick={closeDeleteModal}
                                className="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-500"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <ModalUpdateGalery
                image={selectedImage}
                isOpen={isEditModalOpen}
                closeModal={closeEditModal}
                onSave={handleSave}
                refresImages={refresImages}
            />
        </>
    )
}
