import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Dots } from "../components/onload";
import SearchComponent from "./components/search/SearchComponent";
import { ExamCard } from "./components/cards/ExamCard";
import dbRef from "../../config/dbRef";
import { getDataPage } from '../../redux/actions/dataPage';
import { ModalAddExam } from "./components/add-modal/ModalAddExam";
import { Galery } from "./components/galery/Galery";
import { ModalAddGalery } from "./components/add-galery-modal/ModalAddGalery";
import { PrecingCard } from "./components/plans/precing-card/PrecingCard";


const validUsername = "admin";
const validPassword = "12345678"; 

export default function Admin() {
  const reduxUser = useSelector(state => state.user);

  // Modal add Exam
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModalExam = () => { setIsModalOpen(true) }
  const closeModalExam = () => { setIsModalOpen(false) }

  // Modal add Galery
  const [isModalGaleryOpen, setIsModalGaleryOpen] = useState(false);
  const openModalGalery = () => { setIsModalGaleryOpen(true) }
  const closeModalGalery = () => { setIsModalGaleryOpen(false) }

  const [windowActive, setWindowActive] = useState("exams");
  // const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [exams, setExams] = useState([]);
  const [images, setImages] = useState([]);
  const [planData, setPlanData] = useState([]);

  // Menejo de autenticacion del usuario
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleLogin = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
      setLoginError('');
    } else {
      setLoginError('Credenciales incorrectas');
    }
  };

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'block';
  }

  const fetchPlans = async () => {
    getDocs(collection(db, dbRef.planes))
      .then((response) => {
        const allData = [];
        response.forEach((doc) => {
          allData.push({ id: doc.id, data: doc.data() });
        });
        setPlanData(allData);
      })
      .catch((error) => console.error("Error fetching documents:", error));
  }

  useEffect(() => {
    fetchPlans();
  }, [])

  const fetchImages = async () => {
    getDocs(collection(db, dbRef.galeria))
      .then((response) => {
        const allData = [];
        response.forEach((doc) => {
          allData.push({ id: doc.id, data: doc.data() });
        });
        setImages(allData);
      })
      .catch((error) => console.error("Error fetching documents:", error));
};

  useEffect(() => {
    fetchImages();
  }, [])

  useEffect(() => {
    if (reduxUser.user) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [reduxUser])

  const fetchExams = async () => {
    getDocs(collection(db, dbRef.exam))
      .then((response) => {
        const allData = [];
        response.forEach((doc) => {
          allData.push({ id: doc.id, data: doc.data() });
        });
        setExams(allData);
      })
      .catch((error) => console.error("Error fetching documents:", error));
  }
  useEffect(() => {
    fetchExams();
  }, []);

  // busqueda
  const [search, setSearch] = useState('')

  const searcher = (e) => {
    setSearch(e.target.value);
  }

  const result = (search.length === 0) ? exams : exams.filter((data) => data.data.nombre.toLowerCase().includes(search.toLocaleLowerCase()));

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-200">
        <h2 className="text-2xl mb-4">Iniciar sesión</h2>
        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg w-80">
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mb-2 p-2 border border-gray-300 rounded w-full"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mb-2 p-2 border border-gray-300 rounded w-full"
          />
          <button
            onClick={handleLogin}
            className="px-4 py-2 bg-red-500 text-white rounded w-full"
          >
            Ingresar
          </button>
          {loginError && <p className="text-red-500 mt-2">{loginError}</p>}
        </div>
      </div>
    );
  }

  // Si el usuario está autenticado, mostrar el contenido principal
  return (
    <>
      <div className="w-full text-white flex flex-col justify-center items-center border-b-16 border-accent">
        <h1 className="text-3xl my-5 sm:text-xl md:text-3xl mx-0 sm:mx-4 md:mx-0 text-center">Bienvenido</h1>
      </div>
      <div className="flex flex-row items-start justify-center w-full h-full px-28 sm:px-2 md:px-16">
        <div className="w-9/12 h-80 flex flex-col justify-start items-start -mt-1 sm:mt-1 md:-mt-1 w-full">
          <div className="bg-accent flex flex-row w-full text-white justify-start px-8 rounded-b-lg sm:hidden md:flex">
            <p
              className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'exams' ? ' bg-white text-primary' : 'text-white'}`}
              onClick={() => setWindowActive('exams')}
            >
              Examenes
            </p>
            <p
              className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'galery' ? ' bg-white text-primary' : 'text-white'}`}
              onClick={() => setWindowActive('galery')}
            >
              Galeria
            </p>
            <p
              className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'plans' ? ' bg-white text-primary' : 'text-white'}`}
              onClick={() => setWindowActive('plans')}
            >
              Planes
            </p>
          </div>

          <div className="flex flex-col w-full justify-center items-center py-4">
            <SearchComponent search={search} searcher={searcher} placeholder="Buscar por nombre" aligment='center' />
          </div>

          {/* Examenes Section */}
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'exams' ? 'flex flex-row' : 'hidden'}`}>
            {
              loading ? (
                <div className="w-full flex flex-col justify-center">
                  <p className="text-2xl text-center text-primary">
                    <Dots color="gray" />
                  </p>
                  <h1 className="w-full text-center">Cargando...</h1>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full">
                  {result.map((examen, index) => (
                    <ExamCard
                      key={index}
                      id={examen.id}
                      fechaFin={examen.data.fecha}
                      fechaInicio={examen.data.fecha}
                      nombre={examen.data.nombre}
                      examen={examen}
                      refresExams={fetchExams}
                    />
                  ))}
                </div>
              )
            }
            {
              result.length === 0 && !loading && (
                <div className="w-full flex flex-col justify-center self-start">
                  <p className="text-xl text-center text-secundary">En este momento usted no tiene examenes</p>
                </div>
              )
            }
            <div className="w-full flex flex-col justify-end self-start">
              <button
                onClick={openModalExam}
                className="m-4 text-center text-white base sm:base lg:base cursor-pointer hover:fondo py-2 rounded-full px-4 bg-primary flex flex-row items-center hover:bg-blue-600 self-center"
              >
                Agregar Examen
              </button>
              <ModalAddExam isOpen={isModalOpen} closeModal={closeModalExam} refresExams={fetchExams} />
            </div>
          </div>

          {/* Galeria Section */}
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'galery' ? 'flex flex-row' : 'hidden'}`}>
            <Galery images={images} refresImages={fetchImages} />
            <div className="w-full flex flex-col justify-end self-start">
              <button
                onClick={openModalGalery}
                className="m-4 text-center text-white base sm:base lg:base cursor-pointer hover:fondo py-2 rounded-full px-4 bg-primary flex flex-row items-center hover:bg-blue-600 self-center"
              >
                Agregar Fotografia
              </button>
              <ModalAddGalery isOpen={isModalGaleryOpen} closeModal={closeModalGalery} refresImages={fetchImages} />
            </div>
          </div>

          {/* Planes Section */}
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'plans' ? 'flex flex-row' : 'hidden'}`}>
            <div className="grid grid-cols-3 gap-4 w-full sm:grid-cols-1 md:grid-cols-3 py-10 px-10 pt-10 bg-gray-700">
              {
                planData.map((plan, index) => (
                  <PrecingCard key={index} plan={plan} refresPlans={fetchPlans} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}